import React, { useState } from 'react';
import { Typography, TextField, Button, Box, Radio, RadioGroup, FormControlLabel, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fetchUserQuestionsOnboarding } from '../Api/UserApi';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    fontFamily: 'Inter',
    marginBottom: '1rem',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    color: '#666666',
    marginBottom: '0.2rem',
  },
  question: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '0.5rem',
  },
  input: {
    marginBottom: '1rem',
  },
  nextButton: {
    backgroundColor: '#6BBDBD',
    color: 'white',
    fontSize: "14px",
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '20px',
    padding: '0.5rem 2rem',
    alignSelf: 'flex-end',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#6BBDBD', // Same as default to remove hover effect
    },
    '&:disabled': {
      backgroundColor: '#CCCCCC',
    },
  },
  inputFocused: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#6BBDBD !important',
    },
  },
  radioRoot: {
    '&$radioChecked': {
      color: '#6BBDBD',
    },
  },
  radioChecked: {},
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1000,
  },
  loadingText: {
    marginTop: theme.spacing(2),
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: '600',
  },
}));

const questions = [
  "¿Qué oposición estás preparando?",
  "¿Cuándo es tu examen?",
  "¿Cuántas horas de estudio dedicas al día?",
];

const Questionnaire = ({ accessToken, user }) => {
  const classes = useStyles();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(Array(questions.length).fill(''));
  const [allAnswered, setAllAnswered] = useState(false);
  const [knowExamDate, setKnowExamDate] = useState(true);
  const navigate = useNavigate()
  const isLastQuestion = currentQuestion === questions.length - 1;
  const [isLoading, setIsLoading] = useState(false);

  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleAnswerChange = (e, index = currentQuestion) => {
    const newAnswers = [...answers];
    newAnswers[index] = e.target.value;
    setAnswers(newAnswers);
    setAllAnswered(newAnswers.every(answer => answer !== null && answer !== ''));
  };

  const handleDateChange = (date) => {
    const newAnswers = [...answers];
    newAnswers[1] = date; // The date picker is for the second question (index 1)
    setAnswers(newAnswers);
    setAllAnswered(newAnswers.every(answer => answer !== null && answer !== ''));
  };

  const handleButtonClick = async () => {
    if (isLastQuestion && allAnswered) {
      setIsLoading(true);
      const payload = {
        "oposicion": answers[0],
        "date_exam": answers[1],
        "study_hours": answers[2],
      }
      try {
        await fetchUserQuestionsOnboarding(accessToken, "POST", payload, user);
        // Wait for 5 seconds after the fetch is complete
      } catch (error) {
        console.error("Error saving answers:", error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        await new Promise(resolve => setTimeout(resolve, 3000));

        setIsLoading(false);
        window.location.href = "https://app.typedai.com"; // Use navigate instead of window.location.href
        // window.location.href = "typedapp.local:3000"; // Use navigate instead of window.location.href

      }
      // Navigate after the loading state is set to false and the delay has passed
    } else {
      handleNextQuestion();
    }
  };

  const handleRadioChange = (event) => {
    const isUnknownDate = event.target.value === 'no';
    setKnowExamDate(!isUnknownDate);
    const newAnswers = [...answers];
    newAnswers[1] = isUnknownDate ? 'Aún no lo sé' : null;
    setAnswers(newAnswers);
    setAllAnswered(newAnswers.every(answer => answer !== null && answer !== ''));
  };

  const renderInput = () => {
    if (currentQuestion === 1) {
      return (
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Fecha del examen"
              value={knowExamDate ? answers[currentQuestion] : null}
              onChange={(date) => {
                const newAnswers = [...answers];
                newAnswers[currentQuestion] = date;
                setAnswers(newAnswers);
                setKnowExamDate(true);
                setAllAnswered(newAnswers.every(answer => answer !== null && answer !== ''));
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={classes.input}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MuiPickersUtilsProvider>
          <RadioGroup row value={knowExamDate ? '' : 'no'} onChange={handleRadioChange}>
            <FormControlLabel value="no" control={<Radio />} label="Aún no lo sé" />
          </RadioGroup>
        </>
      );
    } else if (currentQuestion === 2) {
      return (
        <RadioGroup
          aria-label="study-hours"
          name="study-hours"
          value={answers[currentQuestion]}
          onChange={(e) => handleAnswerChange(e, currentQuestion)}
        >
          <FormControlLabel
            value="< 2 horas"
            control={<Radio classes={{ root: classes.radioRoot, checked: classes.radioChecked }} />}
            label="< 2 horas"
          />
          <FormControlLabel
            value="Entre 2 y 4 horas"
            control={<Radio classes={{ root: classes.radioRoot, checked: classes.radioChecked }} />}
            label="Entre 2 y 4 horas"
          />
          <FormControlLabel
            value="> 4 horas"
            control={<Radio classes={{ root: classes.radioRoot, checked: classes.radioChecked }} />}
            label="> 4 horas"
          />
        </RadioGroup>
      );
    } else {
      return (
        <TextField
          variant="outlined"
          fullWidth
          value={answers[currentQuestion]}
          onChange={handleAnswerChange}
          className={classes.input}
          inputProps={{ maxLength: 100 }}

          InputProps={{
            classes: {
              focused: classes.inputFocused,
            },
            style: {
              height: '40px',
            },
          }}
        />
      );
    }
  };

  // Add this new function to check if the current question is answered
  const isCurrentQuestionAnswered = () => {
    return answers[currentQuestion] !== null && answers[currentQuestion] !== '';
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
      {isLoading && (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
          <Typography className={classes.loadingText}>
            Estamos guardando las respuestas...
          </Typography>
        </Box>
      )}
      <Box marginBottom={"20px"}>
        <Box>
          <Typography variant="h5" className={classes.subtitle}>
            Mientras creamos tu material de estudio
          </Typography>
          <Typography variant="subtitle1" className={classes.title}>
            Ayúdanos a conocerte un poco más
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          width="100%"
        >
          <Typography className={classes.question}>
            {currentQuestion + 1}. {questions[currentQuestion]}
          </Typography>
          {renderInput()}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt="auto">
        <Typography variant="body2" color="textSecondary">
          {currentQuestion + 1} de {questions.length}
        </Typography>
        <Button
          variant="contained"
          className={classes.nextButton}
          onClick={handleButtonClick}
          disabled={!isCurrentQuestionAnswered()}
        >
          {isLastQuestion ? "Ir a Typed" : "Siguiente"}
        </Button>
      </Box>
    </Box>
  );
};

export default Questionnaire;
