import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { keyframes } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
`;

const premiumPlan = {
  prices: {
    original: '7,20€',
    discounted: '6,12€',
  },
  period: '/mes',
  discount: '15%',
  features: [
    { text: 'Documentos ilimitados', included: true },
    { text: 'Tarjetas didácticas', included: true },
    { text: 'Preguntas desbloqueadas', included: true },
    { text: '¡Y mucho más!', included: true },
  ],
  buttonText: '¡USAR CÓDIGO!',
};

const WelcomePopup = ({ open, onClose, userData }) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [copyMessage, setCopyMessage] = useState('');
  const handleCopyCode = () => {
    if (userData?.promotion_code) {
      navigator.clipboard.writeText(userData.promotion_code).then(() => {
        setCopyMessage('Código copiado');
        setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
      });
    }
  };

  useEffect(() => {
    const getDeadline = () => {
      if (userData && userData.start_date) {
        const startDate = new Date(userData.start_date);
        const deadline = new Date(startDate);
        deadline.setDate(startDate.getDate() + 3);
        return deadline;
      }
      return null;
    };

    const calculateTimeLeft = () => {
      const now = new Date();
      const deadline = getDeadline();
      if (deadline) {
        const difference = deadline - now;
        if (difference > 0) {
          setTimeLeft({
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          });
        } else {
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [userData]);

  const handleUsarCodigo = () => {
    onClose();
    navigate('/plans');
  };

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      scroll="paper"
      PaperProps={{
        sx: {
          borderRadius: '16px',
          p: 1,
          maxWidth: '500px',
          maxHeight: '95vh'
        }
      }}
    >
      <DialogTitle sx={{
        textAlign: 'left',
        pt: 3,
        pb: 1
      }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: '"Fira Sans", sans-serif',
            fontWeight: 'bold',
            fontSize: '1.25rem',
            '& span': {
              display: 'inline-block',
              animation: `${bounce} 2s infinite ease-in-out`,
            }
          }}
        >
          <span>🎁</span> Descuento de bienvenida
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontFamily: '"Fira Sans", sans-serif',
            color: '#666666',
            mt: 2,
            fontSize: '0.95rem',
            lineHeight: 1.5,
            userSelect: 'text', // Allow text selection

          }}
        >
          Usa <strong>{userData?.promotion_code}</strong> y obtén un <strong>15%</strong> de descuento en los <strong>planes Premium</strong>.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            mt: 2,
            mb: 1,
            '& > div': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(107, 189, 189, 0.08)',
              borderRadius: '8px',
              padding: '8px 12px',
              minWidth: '60px'
            }
          }}
        >
          <Box>
            <Typography sx={{
              fontWeight: 'bold',
              color: '#6BBDBD',
              fontSize: '1.25rem'
            }}>
              {timeLeft.days}
            </Typography>
            <Typography sx={{
              fontSize: '0.75rem',
              color: '#666666'
            }}>
              días
            </Typography>
          </Box>
          <Box>
            <Typography sx={{
              fontWeight: 'bold',
              color: '#6BBDBD',
              fontSize: '1.25rem'
            }}>
              {String(timeLeft.hours).padStart(2, '0')}
            </Typography>
            <Typography sx={{
              fontSize: '0.75rem',
              color: '#666666'
            }}>
              horas
            </Typography>
          </Box>
          <Box>
            <Typography sx={{
              fontWeight: 'bold',
              color: '#6BBDBD',
              fontSize: '1.25rem'
            }}>
              {String(timeLeft.minutes).padStart(2, '0')}
            </Typography>
            <Typography sx={{
              fontSize: '0.75rem',
              color: '#666666'
            }}>
              min
            </Typography>
          </Box>
          <Box>
            <Typography sx={{
              fontWeight: 'bold',
              color: '#6BBDBD',
              fontSize: '1.25rem'
            }}>
              {String(timeLeft.seconds).padStart(2, '0')}
            </Typography>
            <Typography sx={{
              fontSize: '0.75rem',
              color: '#666666'
            }}>
              seg
            </Typography>
          </Box>
        </Box>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            background: '#ffffff',
            borderRadius: '16px',
            p: 2,
            color: '#333333',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
            border: '2px solid #E2F1F1',
            mb: 3
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: '#333333',
              fontSize: '18px',
              mb: 3,
              fontFamily: '"Fira Sans", sans-serif',
              fontWeight: 600,
            }}
          >
            Premium anual
          </Typography>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '300px',
            margin: '0 auto',
            mb: 3,
            gap: 2
          }}>
            <Box sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%'
            }}>
              <Typography sx={{
                fontSize: '0.875rem',
                color: '#666666',
                mb: 1,
                fontFamily: '"Fira Sans", sans-serif',
              }}>
                Precio normal
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}>
                <Typography sx={{
                  fontSize: '2rem',
                  color: '#666666',
                  opacity: 0.7,
                  fontFamily: '"Fira Sans", sans-serif',
                  lineHeight: 1,
                }}>
                  {premiumPlan.prices.original}
                </Typography>
                <Typography component="span" sx={{
                  fontSize: '1rem',
                  ml: 0.5,
                  color: '#666666',
                  opacity: 0.7,
                  mb: 0.4, // Fine-tune vertical alignment
                }}>
                  {premiumPlan.period}
                </Typography>
              </Box>
            </Box>

            <Box sx={{
              color: '#6BBDBD',
              display: 'flex',
              alignItems: 'center',
              fontSize: '1.5rem',
              mb: 1 // Align with prices
            }}>
              →
            </Box>

            <Box sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%'
            }}>
              <Typography sx={{
                fontSize: '0.875rem',
                color: '#6BBDBD',
                mb: 1,
                fontFamily: '"Fira Sans", sans-serif',
                fontWeight: 500
              }}>
                Con descuento
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%'
              }}>
                <Typography sx={{
                  fontSize: '2.5rem',
                  color: '#333333',
                  fontWeight: 'bold',
                  fontFamily: '"Fira Sans", sans-serif',
                  lineHeight: 1,
                }}>
                  {premiumPlan.prices.discounted}
                </Typography>
                <Typography component="span" sx={{
                  fontSize: '1rem',
                  ml: 0.5,
                  mb: 0.5, // Fine-tune vertical alignment
                }}>
                  {premiumPlan.period}
                </Typography>
              </Box>
              <Typography
                sx={{
                  backgroundColor: '#6BBDBD',
                  color: 'white',
                  px: 1,
                  py: 0.5,
                  borderRadius: '4px',
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  display: 'inline-block',
                  mt: 1,
                  minWidth: 'fit-content'
                }}
              >
                -{premiumPlan.discount}
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 2,
            mt: 2,
            px: 2
          }}>
            {premiumPlan.features.map((feature, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  py: 1,
                  px: 2,
                  backgroundColor: 'rgba(107, 189, 189, 0.08)',
                  borderRadius: '12px',
                  transition: 'transform 0.2s ease',
                  '&:hover': {
                    transform: 'translateX(5px)',
                  }
                }}
              >
                <CheckIcon
                  sx={{
                    color: '#6BBDBD',
                    minWidth: 36,
                    mr: 1
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: '"Fira Sans", sans-serif',
                    fontSize: '0.875rem',
                    color: '#2C3E50',
                    fontWeight: 500,
                  }}
                >
                  {feature.text}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Button
            variant="contained"
            size="large"
            onClick={handleCopyCode}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              '&:hover': {
                backgroundColor: '#5AACAC',
                transform: 'scale(1.05)',
                boxShadow: '0 5px 15px rgba(107, 189, 189, 0.2)',
              },
              fontFamily: '"Fira Sans", sans-serif',
              fontWeight: 600,
              px: 4,
              py: 1,
              borderRadius: '25px',
              transition: 'all 0.2s ease',
            }}
          >
            Copiar código
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomePopup;
