import React, { useState, useEffect } from 'react';
import { Container, Title, Subtitle } from './useStyleNewExam';
import { Box, Button, Paper, Backdrop, Typography } from '@mui/material';
import Premium from '../PopUpsAlerts/Premium/Premium';
import { makeStyles } from '@material-ui/core/styles';
import { useCredits } from '../Context/CreditsContext';
import NeedCredits from '../NoCreditsModal/NeedCredits';
import animationData from '../Images/singing-contract.json';
import Lottie from 'lottie-react';

import { useSubscription } from "../Context/SubscriptionContext";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth/Authhandle';
import * as handle from "../Main/handle";
import { fetchFolderExams } from '../Api/ExamsApi';
import '../Main/style.css';

import SelectDocument from './SelectDocument';
import CustomizeExamModal from './CustomizeExamModal';
import InfoIcon from '@mui/icons-material/Info';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const useStyles = makeStyles(() => ({
  paperLeft: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    flexGrow: 1,
    height: "100%",
    overflowY: "auto",
    backgroundColor: '#F8FFFF !important',
  },
}));

const NewExam = () => {
  const { accessToken, user } = useAuth();
  const { credits } = useCredits();
  const [showNeedCredits, setShowNeedCredits] = useState(false);
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentObject, setSelectedDocumentObject] = useState(null);
  const { userData } = useSubscription();
  const isPremium = userData?.plan_name !== "Free";
  const [typeQuestions, setTypeQuestions] = useState({
    memorized: true,
    learning: true,
    incorrect: true,
    notStarted: true
  })
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [questionCount, setQuestionCount] = useState(15);
  const [listDocuments, setListDocuments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExam, setIsLoadingExam] = useState(false);

  const [examCustomization, setExamCustomization] = useState({
    questionCount: 15,
    questionTypes: {
      memorized: false,
      learning: false,
      incorrect: false,
      notStarted: false,
      mix: true
    }
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const handleCustomizationChange = (newCustomization) => {
    setExamCustomization(newCustomization);
  };

  useEffect(() => {
    if (accessToken && user) {
      async function fetchData() {
        setIsLoading(true);
        if (accessToken && user) {
          await handle.fetchAllDocuments(accessToken, user, setListDocuments);
          setListDocuments(prevDocuments => {
            if (prevDocuments && prevDocuments.length > 0) {
              const filteredDocs = prevDocuments.filter(doc => !doc.is_generating && !doc.is_blocked && !doc.is_error_generating && doc.questions_generated);
              if (filteredDocs.length > 0 && !selectedDocument) {
                setSelectedDocument(filteredDocs[0].guid);
                setSelectedDocumentObject(filteredDocs[0]);
              }
              return filteredDocs;
            }
            return prevDocuments;
          });
          const params = new URLSearchParams(location.search);
          const documentParam = params.get('document');
          if (documentParam) {
            setSelectedDocument(documentParam);
          }
        }
        setIsLoading(false);
      }
      fetchData();
    }
  }, [accessToken, user, location]);

  useEffect(() => {
    if (selectedDocument && listDocuments?.length > 0) {
      const foundDocument = listDocuments.find(doc => doc.guid === selectedDocument);
      setSelectedDocumentObject(foundDocument);
    } else {
      setSelectedDocumentObject(null);
    }
  }, [selectedDocument, listDocuments]);

  const handleDocumentSelect = (document) => {
    if (!isPremium) {
      setSelectedDocuments([document]);
      return;
    }

    setSelectedDocuments(prevDocs => {
      if (prevDocs.some(doc => doc.guid === document.guid)) {
        const newDocs = prevDocs.filter(doc => doc.guid !== document.guid);
        return newDocs.length === 0 ? [document] : newDocs;
      }
      if (prevDocs.length >= 5) return prevDocs;
      return [...prevDocs, document];
    });
  };

  const handleCustomizationSave = async (settings) => {
    if (credits === -100 || credits >= 40) {
      setIsLoadingExam(true);

      const formDataExam = new FormData();

      // Extract GUIDs from selectedDocuments
      const documentGuids = selectedDocuments.map(doc => doc.guid);

      formDataExam.append('type[type_exam]', "bullet_points");
      formDataExam.append('number_questions', settings.questionCount);
      formDataExam.append('init_page', "0");
      formDataExam.append('final_page', "20");
      formDataExam.append('type[Document]', JSON.stringify(documentGuids));
      formDataExam.append('Topics', ['Todos']);
      formDataExam.append('question_types', JSON.stringify(settings.questionTypes));

      try {
        const result = await fetchFolderExams(accessToken, "POST", selectedDocuments, user, formDataExam);
        setIsLoadingExam(false);
        navigate(`/exam/${result.path}`, {
          state: { examCustomization: settings }
        });
      } catch (error) {
        console.error('Error creating exam:', error);
        setIsLoadingExam(false);
        // You might want to show an error message to the user here
      }
    } else {
      setShowNeedCredits(true);
    }
  };

  const handleNextStep = () => {
    if (currentStep === 1 && selectedDocuments?.length > 0) {
      setCurrentStep(2);
    }
  };

  const handleBackStep = () => {
    setCurrentStep(1);
  };

  return (
    <Container style={{
      display: "flex",
      flexDirection: "column",
      maxWidth: "1000px",
      width: "100%",
      position: 'relative',
      zIndex: 1,
      margin: "0 auto",
      paddingBottom: "0px"
    }}>
      <Paper elevation={0} className={classes.paperLeft}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px' }}>
          <Title>Pon a prueba tus conocimientos</Title>
          <Subtitle sx={{ marginTop: '8px', marginBottom: '24px' }}>
            Haz tests sobre tus documentos y mejora la rentención de información
          </Subtitle>
        </Box>

        {currentStep === 1 ? (
          <>
            <Box sx={{ marginBottom: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Subtitle>Selecciona el documento a estudiar</Subtitle>
              <Button
                variant="contained"
                onClick={handleNextStep}
                disabled={!selectedDocuments || selectedDocuments.length === 0}
                sx={{
                  backgroundColor: selectedDocuments?.length > 0 ? '#6BBDBD' : '#E0E0E0',
                  color: selectedDocuments?.length > 0 ? 'white' : '#9E9E9E',
                  textTransform: 'none',
                  fontWeight: '600',
                  fontFamily: "'Inter', sans-serif",
                  borderRadius: '24px',
                  padding: '6px 22px',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  '&:hover': {
                    backgroundColor: selectedDocuments?.length > 0 ? '#5AACAC' : '#E0E0E0',
                  },
                  '&:disabled': {
                    cursor: 'not-allowed',
                  },
                }}
              >
                Siguiente
              </Button>
            </Box>

            {!isPremium && (
              <Box
                sx={{
                  backgroundColor: '#FFF4E5',
                  borderRadius: '8px',
                  p: 2,
                  marginTop: '4px',
                  marginBottom: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <WorkspacePremiumIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#FFB800'
                  }}
                />
                <Typography sx={{ color: '#663C00', fontSize: '14px' }}>
                  Para seleccionar más de un documento debes ser usuario premium
                </Typography>
              </Box>
            )}

            <SelectDocument
              listDocuments={listDocuments}
              isLoading={isLoading}
              selectedDocument={selectedDocument}
              selectedDocuments={selectedDocuments}
              onDocumentSelect={handleDocumentSelect}
              setSelectedDocuments={setSelectedDocuments}
              isPremium={isPremium}
            />
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'space-between' }}>
              <Button
                onClick={handleBackStep}
                sx={{
                  color: '#666',
                  textTransform: 'none',
                  fontWeight: '600',
                }}
              >
                ← Volver
              </Button>
              <Button
                variant="contained"
                onClick={() => handleCustomizationSave(examCustomization)}
                sx={{
                  backgroundColor: '#6BBDBD',
                  color: 'white',
                  textTransform: 'none',
                  fontWeight: '600',
                  fontFamily: "'Inter', sans-serif",
                  borderRadius: '24px',
                  padding: '6px 22px',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  '&:hover': {
                    backgroundColor: '#5AACAC',
                  },
                }}
              >
                Empezar Test
              </Button>
            </Box>

            {(userData?.plan_name === "Free") && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  backgroundColor: '#F8F9FA',
                  border: '1px solid #E7E7E7',
                  borderRadius: '12px',
                  padding: '16px',
                  marginBottom: '24px',
                }}
              >
                <InfoIcon sx={{ color: '#6BBDBD', fontSize: 24 }} />
                <Box>
                  <Typography
                    sx={{
                      color: '#2D2D2D',
                      fontSize: '16px',
                      fontWeight: 500,
                      fontFamily: 'Inter',
                      lineHeight: 1.5,
                    }}
                  >
                    Como usuario gratuito tienes acceso limitado a las preguntas de este documento.
                  </Typography>
                  <Typography
                    sx={{
                      color: '#666666',
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0,
                    }}
                  >
                    Para acceder a todas las preguntas
                    <Button
                      onClick={() => navigate('/plans')}
                      sx={{
                        color: '#6BBDBD',
                        textTransform: 'none',
                        padding: '0 px',
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        minWidth: 'auto',
                        fontWeight: 600,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      debes suscribirte
                    </Button>
                  </Typography>
                </Box>
              </Box>
            )}

            <CustomizeExamModal
              onClose={handleBackStep}
              onSave={handleCustomizationSave}
              documentStats={selectedDocumentObject}
              documentName={selectedDocumentObject?.title}
              selectedDocuments={selectedDocuments}
              examCustomization={examCustomization}
              onCustomizationChange={handleCustomizationChange}
            />
          </Box>
        )}

        {showPremium && (
          <Premium
            onClose={() => setShowPremium(false)}
            titleText={premiumTitleText}
          />
        )}
        {showNeedCredits && (
          <NeedCredits
            initialOpen={showNeedCredits}
            onClose={() => setShowNeedCredits(false)}
          />
        )}
      </Paper>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingExam}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Lottie
            animationData={animationData}
            style={{ width: 300, height: 300 }}
          />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Estamos preparando tu examen...
          </Typography>
        </Box>
      </Backdrop>
    </Container>
  );
};

export default NewExam;
