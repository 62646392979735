import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import NewPreguntasDocument from "./NewPreguntasDocument";
import WaitingQuestions from "./WaitingQuestions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStylesPreguntasDocument from './Styles/useStylePreguntasDocument';
import preguntasbloquedas from './preguntasbloquedas.svg';
import MySchemasList from './SchemasList'
import {

    Accordion, AccordionSummary, AccordionDetails, Skeleton
} from '@mui/material';
import { useParams } from "react-router-dom";
import Spinner from "../NewComponents/Spinner";
import Transcript from "./Transcript";
import PDFViewerComponent from "./Viewers/PdfViewer";
import CSVViewerFile from "./Viewers/CSVViewer";
import { fetchExamId, fetchExamsbyDocumentId, fetchFolderExams } from "../Api/ExamsApi";
import ExamList from "../NewComponents/ListExams";
import Summary from "./Summary";
import { fetchQuestions } from "../Api/ChaptersApi";
import GenerantPreguntes from "./GenerantPreguntes";
import DesbloquejarDocuments from "./DesbloquejarDocuments";
import ErrorPreguntesDoc from "./ErrorPreguntesDoc";
import { getCsrfToken } from "../Api/TokenApi";
import FlashcardDoc from '../Flashcards/FlashcardDoc';
import LockIcon from '@mui/icons-material/Lock';
import Tooltip from '@mui/material/Tooltip';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => ({
    csvViewerContainer: {
        maxWidth: "800px", /* Maximum width */
        marginLeft: "auto", /* Auto margin for center alignment */
        marginRight: "auto", /* Auto margin for center alignment */
        /* Add any other styles you need */
    },
    containerSelectors: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f5f7fa',
        borderRadius: '10px',
        padding: '3px',
        gap: "3px",
        width: "fit-content",
        marginBottom: '15px',
        marginTop: '40px',
        paddingRight: "5px",
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    button: {
        borderRadius: "10px",
        fontSize: "16px",
        textTransform: "none",
        // '&:first-of-type': {
        //     borderTopLeftRadius: '5px',
        //     borderBottomLeftRadius: '5px',
        // },
        // '&:last-of-type': {
        //     borderTopRightRadius: '5px',
        //     borderBottomRightRadius: '5px',
        // },
    },
    selectedButton: {
        backgroundColor: 'white',
        color: '#000',
        border: 'none',
    },
    unselectedButton: {
        backgroundColor: 'transparent',
        color: '#000',
        border: 'none',
    },
    buttonUpload: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",

        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",
            // padding: "8px 14px", // Added padding for better appearance

        },
        padding: "4px 11px !important"
    },
    grid: {
        flex: 1,
    },
}));
const SkeletonLoader = () => {
    const classes = useStylesPreguntasDocument();

    return (
        <Container className={classes.scrollableContainer}>
            {[1, 2, 3].map((_, index) => (
                <Accordion key={index} className={classes.accordionPreguntas}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                        className={classes.accordionSummaryPreguntas}
                    >
                        <Skeleton variant="text" width="80%" height={30} />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="40%" />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
}
function Document({ stepValue, messageStep, setQuestions, questions, loading, error,
    docInfo, setSummary, accessToken, user, setMessage, setShowAlert, summary, handleCreateExam,
    listExams, setListExams, isGeneratingSummary, setIsGeneratingSummary, shouldCreateSummary,
    setNeedGenerateQuestions, needGenerateQuestions, errorGeneratingQuestions, chapters_generating, newGeneratingSummary, setNewGeneratingSummary, regeneratingQuestions, setRegeneratingQuestions,
    setShowUpgrade, userData, isGeneratingFlashcards, setIsGeneratingFlashcards }) {
    const { document_id, selectedTranscript } = useParams();
    const { folder_id, selectedFolder } = useParams();
    const [isCreatingSchemas, setIsCreatingSchemas] = useState(docInfo?.is_creating_schema || false);
    // const [listExams, setListExams] = useState([]);
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation(); // Use useLocation to get the current location
    const [numberOfFlashcards, setNumberOfFlashcards] = useState(0);
    const [selected, setSelected] = useState(questions.is_blocked ? null : (shouldCreateSummary ? 'Summary' : 'Progreso'));
    const [totalFlashcards, setTotalFlashcards] = useState(docInfo?.total_flashcards || 0);

    const handleButtonClick = (value) => {
        setSelected(value);
    };
    const renderContentBasedOnType = () => {
        if (!docInfo) {
            return (<div> <Spinner left="40%" /></div>); // Or any other placeholder
        }
        const type = docInfo.type_dc;
        if (type === ".pdf") {
            return <PDFViewerComponent docInfo={docInfo} token={accessToken} />;
        } else if (type === ".png" || type === ".jpg") {
            return <div>Image</div>;
        } else if (type === ".csv" || type === ".xlsx") {
            return <CSVViewerFile docInfo={docInfo} />;
        } else if (type === ".mp4" || type === ".mp3") {
            return <Transcript docInfo={docInfo} accessToken={accessToken} user={user} />;
        } else {
            return <div>Unknown File Type</div>;
        }
    };
    useEffect(() => {
        if (questions.is_blocked) {
            setSelected("");
        }
    }, [questions.is_blocked]);

    const handleGenerateQuestions = async () => {
        try {
            setNeedGenerateQuestions(true);
            const response = await fetch(`${BACKEND_URL}/api_v1/generation_questions/${docInfo.guid}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'X-CSRFToken': getCsrfToken()
                },
                body: JSON.stringify({
                    "needGenerateQuestions": true
                })
            });

            if (response.ok) {
                const data = await response.json();
                setMessage("Generando preguntas...");
                setShowAlert(true);
                // You might want to update the questions state here or trigger a re-fetch
                // For example: setQuestions({ ...questions, is_generating: true });
            } else {
                throw new Error('Failed to start question generation');
            }
        } catch (error) {
            console.error("Error generating questions:", error);
            setMessage(t("error_generating_questions"));
            setShowAlert(true);
            setNeedGenerateQuestions(false);
        }
    };
    const handleItemClick = (guid, isActive) => {
        if (!isActive) {
            // You can add any action here if needed when the item is not active/loading
            setMessage(t("exam_creating"))
            setShowAlert(true)
            return; // Early return to prevent action
        }
        // Redirect if the item is active
        // navigate(`exam/${guid}`);
        const basePath = location.pathname.split('/')[1]; // This gets the first segment after the root
        const newPath = `/${basePath}/exam/${guid}`;

        // Redirect if the item is active
        navigate(newPath);
    };
    const handleRemoveExam = async (exam_id) => {
        // Immediately filter out the exam optimistically

        try {
            setListExams(currentExams => {
                // Convert the object to an array of entries, filter out the exam, and convert back to an object
                const filteredEntries = Object.entries(currentExams).filter(([guid, examDetails]) => guid !== exam_id);
                return Object.fromEntries(filteredEntries);
            });      // Perform the deletion request
            await fetchExamId(accessToken, "DELETE", exam_id, user);
            // No need to do anything here on success, as we've already updated the UI

        } catch (error) {
            console.error("this error", error);
            // Handle error - e.g., show an alert or undo the optimistic update
            setMessage("Error removing exam. Please try again.");
            setShowAlert(true);
            // Optionally, fetch the exams again or revert the change in the state
        }
    };
    const [schemas, setSchemas] = useState([]);
    const [schemasError, setSchemasError] = useState(null);
    const [schemasLoading, setSchemasLoading] = useState(true);

    // Add schemas fetch effect
    useEffect(() => {
        const fetchSchemas = async () => {
            setSchemasLoading(true);
            try {
                const response = await fetch(`${BACKEND_URL}/api_v1/documents/${docInfo.guid}/schemas/?user_id=${user.sub}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch schemas');
                }
                const data = await response.json();
                // Sort schemas by date_creation in descending order (newest first)
                const sortedSchemas = data.sort((a, b) =>
                    new Date(b.date_creation) - new Date(a.date_creation)
                );
                setSchemas(sortedSchemas);
            } catch (error) {
                setSchemasError(error.message);
                console.error('Error fetching schemas:', error);
            } finally {
                setSchemasLoading(false);
            }
        };

        if (docInfo?.guid && user?.sub) {
            fetchSchemas();
        }
    }, [docInfo?.guid, user?.sub]);




    const renderContent = () => {
        switch (selected) {
            case 'Document':
                return (
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            {renderContentBasedOnType()}
                        </Grid>
                    </Grid>
                );
            case 'Summary':
                return (
                    <>
                        {accessToken && <Summary userData={userData} setSummary={setSummary} docInfo={docInfo} accessToken={accessToken} user={user} summary={summary} isGeneratingSummary={isGeneratingSummary} newGeneratingSummary={newGeneratingSummary} setNewGeneratingSummary={setNewGeneratingSummary} setIsGeneratingSummary={setIsGeneratingSummary} />}
                    </>
                );
            case 'Schemas':
                return accessToken && <MySchemasList
                    documentId={docInfo.guid}
                    accessToken={accessToken}
                    user={user}
                    isCreatingSchemas={isCreatingSchemas}
                    setIsCreatingSchemas={setIsCreatingSchemas}
                    schemas={schemas}
                    setSchemas={setSchemas}
                    isLoading={schemasLoading}
                    error={schemasError}
                    summary={summary}
                    setShowUpgrade={setShowUpgrade}
                />
            case 'Progreso':

                return (
                    <Grid container className={classes.grid}>

                        <Grid item xs={12}>
                            {Object.keys(questions).length > 0 ? (
                                errorGeneratingQuestions || questions.is_generating_error ? (
                                    <ErrorPreguntesDoc />
                                ) : !questions.topics ? (
                                    <SkeletonLoader />
                                ) : questions.topics.length === 0 && questions.question_generated ? (
                                    <SkeletonLoader />
                                ) : !questions.question_generated ? (
                                    needGenerateQuestions || questions.is_generating ? (
                                        <NewPreguntasDocument
                                            saving_questions={true}
                                            chapters_generating={chapters_generating}
                                            max_pages={docInfo?.info_doc?.pages || 0}
                                            loading={loading}
                                            chapters={questions.topics || []}
                                            setQuestions={setQuestions}
                                            user={user}
                                            accessToken={accessToken}
                                            docInfo={docInfo}
                                            regeneratingQuestions={regeneratingQuestions}
                                            setRegeneratingQuestions={setRegeneratingQuestions}
                                        />
                                    ) : (
                                        <WaitingQuestions handleGenerateQuestions={handleGenerateQuestions} />
                                    )
                                ) : (
                                    <NewPreguntasDocument
                                        saving_questions={false}
                                        chapters_generating={false}
                                        loading={loading}
                                        chapters={questions.topics || []}
                                        setQuestions={setQuestions}
                                        user={user}
                                        max_pages={docInfo?.info_doc?.pages || 0}
                                        accessToken={accessToken}
                                        docInfo={docInfo}
                                        regeneratingQuestions={regeneratingQuestions}
                                        setRegeneratingQuestions={setRegeneratingQuestions}
                                    />
                                )
                            ) : (
                                <SkeletonLoader />
                            )}

                        </Grid>
                    </Grid>
                );
            case 'Exams':
                return (
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            {/* {listExams && (

                                <ExamList listExams={listExams} handleItemClick={handleItemClick} handleRemoveExam={handleRemoveExam} />
                            )} */}

                            {
                                listExams && Object.keys(listExams).length > 0 ? (

                                    <ExamList listExams={listExams} handleItemClick={handleItemClick} handleRemoveExam={handleRemoveExam} />


                                ) : (<>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: "50px",
                                        height: '100%',
                                    }}>
                                        <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/emptyExams.svg" alt="Preview Exam" />
                                        <Typography style={{ fontSize: "12px", marginBottom: "10px" }}>{t("exams_dont_exist")}</Typography>
                                        <Button className={classes.buttonUpload} onClick={handleCreateExam}>{t("create_exam")}</Button>

                                    </div>
                                </>
                                )
                            }

                        </Grid>
                    </Grid>
                );
            case 'Flashcards':

                return <FlashcardDoc
                    documentId={document_id}
                    isGeneratingFlashcards={isGeneratingFlashcards}
                    setIsGeneratingFlashcards={setIsGeneratingFlashcards}
                    setNumberOfFlashcards={setNumberOfFlashcards}
                    numberOfFlashcards={numberOfFlashcards}
                    setTotalFlashcards={setTotalFlashcards}
                    docInfo={docInfo}
                />;
            default:
                return null;
        }
    };

    // async function fetchExams() {
    //     if (accessToken && user) {

    //         try {

    //             const listExamsFetched = await fetchExamsbyDocumentId(accessToken, "GET", docInfo.folder, docInfo.guid, user,);
    //             setListExams(listExamsFetched)
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }

    // }
    // useEffect(() => {
    //     fetchExams()
    // }, [docInfo]);

    return (
        <>
            <Grid
                container
                style={{ display: "flex", flexDirection: "column", height: "90vh", backgroundColor: "white", width: "100%", flexWrap: "unset", }}
            >
                {questions.is_blocked ? (<Typography>Documento Bloqueado</Typography>) : (
                    <Box sx={{
                        borderBottom: '1px solid #E0E0E0',
                        marginBottom: '10px',
                        marginTop: '40px'
                    }}>
                        <Tabs
                            value={selected}
                            onChange={(event, newValue) => handleButtonClick(newValue)}
                            indicatorColor="primary"
                            textColor="primary"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#6BBDBD',
                                    height: '2px'
                                },
                                '& .MuiTab-root': {
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    fontFamily: 'Inter',
                                    color: '#666666',
                                    minWidth: 'unset',
                                    padding: '6px 16px',
                                    marginRight: '24px',
                                    '&.Mui-selected': {
                                        color: '#6BBDBD',
                                        fontWeight: 600
                                    }
                                },
                                '& .MuiTabs-flexContainer': {
                                    justifyContent: 'flex-start'
                                },
                                width: '100%',
                                paddingLeft: '0px',
                            }}
                        >
                            <Tab
                                label={`Preguntas (${questions.topics ? questions.topics.reduce((total, topic) => total + topic.questions.length, 0) : 0})`}
                                value="Progreso"
                                disableRipple
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    fontFamily: 'Inter',
                                    color: '#666666',
                                    minWidth: 'unset',
                                    padding: '6px 16px',
                                    marginRight: '24px',
                                    '&.Mui-selected': {
                                        color: '#6BBDBD',
                                        fontWeight: 600
                                    }
                                }}
                            />

                            <Tab
                                label={`Flashcards (${totalFlashcards})`}
                                value="Flashcards"
                                disableRipple
                                // icon={userData?.plan_name?.startsWith("Basic") ? <LockIcon /> : null}
                                iconPosition="end"
                            // disabled={userData?.plan_name?.startsWith("Basic")}
                            />

                            <Tab
                                label="Resumen"
                                value="Summary"
                                disableRipple
                            />
                            <Tab
                                label={`Esquemas (${schemas.length})`}
                                value="Schemas"
                                disableRipple
                            />
                            <Tab
                                label={`Documento`}
                                value="Document"
                                disableRipple
                            />
                        </Tabs>
                    </Box>
                )}
                {/* {userData?.plan_name === "External" && (
                    <div style={{
                        marginTop: "20px",
                        padding: "20px",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "20px",
                        boxShadow: "none",
                        marginBottom: "15px"

                    }}>
                        <Typography style={{
                            color: "#000B0D",
                            fontSize: "16px",
                            fontFamily: "Fira Sans",
                            fontWeight: 600,
                            marginBottom: "15px"
                        }}>
                            Recursos Adicionales
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#6BBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <Typography style={{ color: '#6BBDBD', fontSize: '14px', fontFamily: 'Fira Sans' }}>
                                    Guía de estudio
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#6BBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <Typography style={{ color: '#6BBDBD', fontSize: '14px', fontFamily: 'Fira Sans' }}>
                                    Plantillas de resumen
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#6BBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <Typography style={{ color: '#6BBDBD', fontSize: '14px', fontFamily: 'Fira Sans' }}>
                                    Tutoriales
                                </Typography>
                            </div>
                        </div>
                    </div>
                )} */}


                {renderContent()}
            </Grid >
        </>
    );
}

export default Document;
