import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Container, Box } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuillEditor from "../NewComponents/TextEditor";
import useChapterStyles from "./Styles/useChapterStyles";
import { getCsrfToken } from '../Api/TokenApi';
import { fetchGenerateSummary } from "../Api/FilesApi";
import { WebSocketContext } from "../WebSocketProvider";
import { useCredits } from "../Context/CreditsContext";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import PopupDiagram from '../MentalMap/PopupDiagram';
import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Tooltip from '@mui/material/Tooltip';
import PopUpSeeDiagrams from '../MentalMap/PopUpSeeDiagrams';
import DownloadIcon from '@mui/icons-material/Download';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import DOMPurify from 'dompurify';
import "./Styles/style.css";

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

if (process.env.NODE_ENV === 'development') {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
} else {
    pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;
}

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const PAGE_CREDIT_PRICE = 0.375

const NoSeparatorAccordion = styled(Accordion)(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
    boxShadow: 'none',
    borderBottom: 'none',
}));

const Header1Group = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    '& .header1-title': {
        fontFamily: "'Fira Sans', sans-serif",
        fontWeight: 500,
        fontSize: '18px',
        color: 'black',
    },
    '& .nested-accordion': {
        marginLeft: theme.spacing(1),
    },
}));

const useStyles = makeStyles((theme) => ({
    accordionSummary: {
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIcon': {
            marginRight: theme.spacing(1),
        },
        paddingLeft: 0, // Add this line to remove left padding
    },
}));

const CustomContainer = styled(Container)(({ theme }) => ({
    height: '82vh',
    overflow: 'auto',
    marginTop: '12px',
    padding: '0 !important',
    overflowX: 'hidden',
    paddingBottom: '100px !important',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '0 16px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    '& p': {
        marginBottom: '12px',
        lineHeight: '1.6',
    },
    '& ul, & ol': {
        paddingLeft: '24px',
        marginBottom: '12px',
    },
    '& li': {
        marginBottom: '8px',
    },
    '& ul ul, & ol ol, & ul ol, & ol ul': {
        marginTop: '8px',
        marginBottom: '8px',
    },

}));

function Summary({ userData, docInfo, setSummary, accessToken, user, summary, isGeneratingSummary, setIsGeneratingSummary, newGeneratingSummary, setNewGeneratingSummary }) {
    const { document_id, selectedTranscript } = useParams();
    const { folder_id, selectedFolder } = useParams();
    const { t } = useTranslation();
    const classes = useChapterStyles();
    const { messageCallback } = useContext(WebSocketContext);


    const [editorValue, setEditorValue] = useState('');
    const { updateCredits, credits } = useCredits();

    const [creditsNeeded, setCreditsNeeded] = useState(0);

    const [currentLoadingPage, setCurrentLoadingPage] = useState('0');
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [expandedSections, setExpandedSections] = useState({});
    const customClasses = useStyles();

    const [openDiagramPopup, setOpenDiagramPopup] = useState(false);
    const [currentSectionContent, setCurrentSectionContent] = useState('');
    const [currentSectionTitle, setCurrentSectionTitle] = useState('');
    const [openSeeDiagramsPopup, setOpenSeeDiagramsPopup] = useState(false);
    const [selectedSectionDiagrams, setSelectedSectionDiagrams] = useState([]);
    const [selectedSectionTitle, setSelectedSectionTitle] = useState('');


    useEffect(() => {
        if (messageCallback) {

            switch (messageCallback.type) {

                case "PageSummary":
                    if (messageCallback.document_id === document_id) {
                        setCurrentLoadingPage(messageCallback.page);
                        setIsGeneratingSummary(true)
                    }
                    return
                // case "SummaryUnblocked":
                //     setIsUnblurred(true)
                //     setIsGeneratingSummary(false)

                //     setNewGeneratingSummary(false)
                //     return
            }

        }
    }, [messageCallback]);

    useEffect(() => {
        if (summary) {
            setEditorValue(summary);
        }
    }, [summary, document_id, folder_id]);
    useEffect(() => {
        if (docInfo) {
            let _creditsPage = Math.max(1, Math.round(docInfo.info_doc.pages * PAGE_CREDIT_PRICE));

            setCreditsNeeded(_creditsPage)
        }
    }, [document_id, docInfo])
    useEffect(() => {
        const adjustEditorHeight = () => {
            const editorElement = document.getElementById('editor-container');
            if (editorElement) {
                const viewportHeight = window.innerHeight;
                const editorOffsetTop = editorElement.getBoundingClientRect().top;
                const distanceToBottom = 20;
                const editorHeight = viewportHeight - editorOffsetTop - distanceToBottom;

                editorElement.style.height = `${editorHeight}px`;
            }
        };

        adjustEditorHeight();
        window.addEventListener('resize', adjustEditorHeight);

        return () => window.removeEventListener('resize', adjustEditorHeight);
    }, []);

    const handleDownloadSummary = async () => {
        if (!summary || !docInfo) {
            return;
        }

        // Function to convert image URL to base64
        const getBase64FromUrl = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        // Only get logo if user is External
        let logoBase64;
        if (userData?.plan_name === 'External') {
            logoBase64 = await getBase64FromUrl('https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/UGT.png');
        }

        let formattedContent;
        if (typeof summary === 'object' && summary !== null) {
            if (Array.isArray(summary)) {
                formattedContent = summary.map(section => `
                    <div>
                        <h2 style="color: #026277; margin: 0; padding: 0;">${section.header2}</h2>
                        <div style="margin: 0; padding: 0;">${section.content}</div>
                    </div>
                `).join('');
            } else {
                formattedContent = `
                    <div>
                        <h2 style="color: #026277; margin: 0; padding: 0;">${summary.header2 || ''}</h2>
                        <div style="margin: 0; padding: 0;">${summary.content || ''}</div>
                    </div>
                `;
            }
        } else if (typeof summary === 'string') {
            try {
                const parsedSummary = JSON.parse(summary);
                if (Array.isArray(parsedSummary)) {
                    formattedContent = parsedSummary.map(section => `
                        <div>
                            <h2 style="color: #026277; margin: 0; padding: 0;">${section.header2}</h2>
                            <div style="margin: 0; padding: 0;">${section.content}</div>
                        </div>
                    `).join('');
                } else {
                    formattedContent = summary;
                }
            } catch (e) {
                console.log("Error parsing summary:", e);
                formattedContent = summary;
            }
        } else {
            formattedContent = summary;
        }

        const htmlContent = `
            <div>
                <h1 style="color: #026277; margin: 0; padding: 0;">${docInfo.title}</h1>
                ${formattedContent}
            </div>
        `;

        const content = htmlToPdfmake(htmlContent);

        const watermarkText = userData?.plan_name === 'External' ? 'UGT' : 'Typedai';
        const watermarkColor = userData?.plan_name === 'External' ? '#E52330' : '#6BBDBD';

        const documentDefinition = {
            content: content,
            watermark: { text: watermarkText, color: watermarkColor, opacity: 0.3, bold: true, fontSize: 70 },
            pageMargins: [40, 60, 40, 60],
            header: userData?.plan_name === 'External' ? {
                columns: [
                    { width: '*', text: '' },
                    {
                        width: 100,
                        image: logoBase64,
                        fit: [100, 100],
                        margin: [-30, 30, 0, 0]
                    }
                ]
            } : null,
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: 'Resumen hecho con typedai.com',
                            alignment: 'left',
                            color: '#6BBDBD',
                        },
                        {
                            text: currentPage + ' de ' + pageCount,
                            alignment: 'right',
                        }
                    ],
                    margin: [40, 0]
                };
            }
        };

        pdfMake.createPdf(documentDefinition).download(`${docInfo.title}.pdf`);
    };
    const handleGenerateSummary = async (exists) => {
        if (credits === -100 || credits >= creditsNeeded) {
            if (!summary || summary === null) {
                setIsGeneratingSummary(true)
            } else {
                setNewGeneratingSummary(false)
            }

            // console.log('Credits before update:', credits);

            fetchGenerateSummary(accessToken, "POST", document_id, null, user)
                .then(() => {
                    if (docInfo) {
                        docInfo.summary_active = true;
                    }

                    // updateCredits();
                    // console.log('Credits after update:', credits);
                    // setIsUnblurred(true)
                })
                .catch((error) => {
                    console.error("Error generating summary:", error);
                });
        } else {
            setShowUpgrade(true)
        }
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedSections({
            ...expandedSections,
            [panel]: isExpanded,
        });
    };

    const handleGenerateDiagram = (text) => {
        console.log("Generating diagram");
    };

    const handleOpenSeeDiagrams = (index) => {
        const dummyDiagrams = Array(index + 1).fill().map((_, i) => ({
            name: `Esquema ${i + 1}`,
            text: `graph TD\nA[${summary[index].title}] --> B[Subtema ${i + 1}]`
        }));
        setSelectedSectionDiagrams(dummyDiagrams);
        setSelectedSectionTitle(summary[index].title);
        setOpenSeeDiagramsPopup(true);
    };

    useEffect(() => {
        if (Array.isArray(summary)) {
            const initialExpandedState = {};
            summary.forEach((_, index) => {
                initialExpandedState[`header1-${index}`] = true;
                initialExpandedState[`panel${index}-${index}`] = false;
            });
            setExpandedSections(initialExpandedState);
        }
    }, [summary]);

    return (
        <>
            {showUpgrade && <NeedCredits initialOpen={true} onClose={() => setShowUpgrade(false)} />}
            <PopupDiagram
                open={openDiagramPopup}
                onClose={() => setOpenDiagramPopup(false)}
                onGenerate={handleGenerateDiagram}
                sectionTitle={currentSectionTitle}
            />
            <PopUpSeeDiagrams
                open={openSeeDiagramsPopup}
                onClose={() => setOpenSeeDiagramsPopup(false)}
                diagrams={selectedSectionDiagrams}
                title={selectedSectionTitle}
            />
            {summary !== null && docInfo?.summary_active && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        style={{
                            backgroundColor: '#6BBDBD',
                            color: 'white',
                            fontSize: '14px',
                            fontFamily: "'Fira Sans', sans-serif",
                            borderRadius: '20px',
                            textTransform: 'none',
                            boxShadow: 'none',
                        }}
                        onClick={handleDownloadSummary}
                    >
                        Descargar resumen
                    </Button>
                </Box>
            )}
            <CustomContainer className="custom-scrollbar">

                {newGeneratingSummary && !summary ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '50vh',
                        textAlign: 'center'
                    }}>
                        <Typography>Generando resumen...</Typography>
                        <Typography style={{ fontSize: "12px", color: "gray", paddingBottom: "15px" }}>
                            {currentLoadingPage === "--" ? "Dividiendo el resumen por capitulos" : `Páginas resumidas: ${currentLoadingPage}`}
                        </Typography>
                        <CircularProgress style={{ color: "#026277" }} />
                    </Box>
                ) :
                    summary !== null ? (
                        (docInfo?.summary_active) && (!isGeneratingSummary && !newGeneratingSummary) ? (
                            // Normal unblurred view
                            <div>
                                {Array.isArray(summary) ? (
                                    <div>
                                        {Object.entries(
                                            summary.reduce((acc, section) => {
                                                if (!acc[section.header1]) {
                                                    acc[section.header1] = [];
                                                }
                                                acc[section.header1].push(section);
                                                return acc;
                                            }, {})
                                        ).map(([header1, sections], groupIndex) => (
                                            <Header1Group key={groupIndex}>
                                                <NoSeparatorAccordion
                                                    expanded={expandedSections[`header1-${groupIndex}`] || false}
                                                    onChange={handleAccordionChange(`header1-${groupIndex}`)}
                                                    className={classes.accordionPreguntas}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`header1-${groupIndex}-content`}
                                                        id={`header1-${groupIndex}-header`}
                                                        className={`${classes.accordionSummaryPreguntas} ${customClasses.accordionSummary}`}
                                                    >
                                                        <Typography className="header1-title">
                                                            {header1}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="nested-accordion">
                                                            {sections.map((section, index) => (
                                                                <NoSeparatorAccordion
                                                                    key={index}
                                                                    expanded={expandedSections[`panel${groupIndex}-${index}`] || false}
                                                                    onChange={handleAccordionChange(`panel${groupIndex}-${index}`)}
                                                                    className={classes.accordionPreguntas}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls={`panel${groupIndex}-${index}bh-content`}
                                                                        id={`panel${groupIndex}-${index}bh-header`}
                                                                        className={`${classes.accordionSummaryPreguntas} ${customClasses.accordionSummary}`}
                                                                    >
                                                                        <Typography className={classes.headerPreguntas}>
                                                                            {section.header2}
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>

                                                                        <ContentWrapper
                                                                            className="notranslate"
                                                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatContent(section.content)) }}
                                                                        />
                                                                    </AccordionDetails>
                                                                </NoSeparatorAccordion>
                                                            ))}
                                                        </div>
                                                    </AccordionDetails>
                                                </NoSeparatorAccordion>
                                            </Header1Group>
                                        ))}
                                    </div>
                                ) : (
                                    // Unstructured HTML content
                                    <ContentWrapper
                                        className="notranslate"
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(summary) }}
                                    />
                                )}
                            </div>
                        ) : (
                            // Blurred view with unlock button
                            <div style={{ position: 'relative', height: '50vh', overflow: 'hidden' }} >
                                <div style={{ filter: 'blur(4px)' }}>
                                    {Array.isArray(summary) && (
                                        <div>
                                            {Object.entries(
                                                summary.reduce((acc, section) => {
                                                    if (!acc[section.header1]) {
                                                        acc[section.header1] = [];
                                                    }
                                                    acc[section.header1].push(section);
                                                    return acc;
                                                }, {})
                                            ).map(([header1, sections], groupIndex) => (
                                                <Header1Group key={groupIndex}>
                                                    <NoSeparatorAccordion
                                                        expanded={expandedSections[`header1-${groupIndex}`] || false}
                                                        onChange={handleAccordionChange(`header1-${groupIndex}`)}
                                                        className={classes.accordionPreguntas}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`header1-${groupIndex}-content`}
                                                            id={`header1-${groupIndex}-header`}
                                                            className={`${classes.accordionSummaryPreguntas} ${customClasses.accordionSummary}`}
                                                        >
                                                            <Typography className="header1-title">
                                                                {header1}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ContentWrapper
                                                                className="notranslate"
                                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatContent(sections.content)) }}
                                                            />
                                                        </AccordionDetails>
                                                    </NoSeparatorAccordion>
                                                </Header1Group>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div style={{
                                    position: 'absolute',
                                    top: '35%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                    textAlign: 'center'
                                }}>
                                    <Button
                                        onClick={() => handleGenerateSummary(true)}
                                        className={classes.generateSummary}
                                    >
                                        Desbloquear resumen
                                    </Button>
                                </div>
                            </div>
                        )
                    ) : (
                        // Initial state - show generate summary button
                        <div style={{ textAlign: 'center', marginTop: "50px" }}>
                            <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/generatingSummary.svg" alt="Generating summary" />
                            {isGeneratingSummary ? (
                                <>
                                    <Typography style={{ fontSize: "12px", color: "gray" }}>{t("summary_ready")}</Typography>
                                    <Typography style={{ fontSize: "12px", color: "gray", paddingBottom: "15px" }}>
                                        Páginas resumidas: {currentLoadingPage}
                                    </Typography>
                                    <CircularProgress style={{ color: "#026277" }} />
                                </>
                            ) : (
                                <>
                                    <Button className={classes.generateSummary} onClick={() => handleGenerateSummary(false)}>
                                        {t("generate_summary")}
                                    </Button>
                                    {docInfo &&
                                        <Typography style={{ fontSize: "12px", color: "gray", marginTop: "10px" }}>
                                            {t("cost_action_credits", { number_credits: creditsNeeded })}
                                        </Typography>
                                    }
                                </>
                            )}
                        </div>
                    )}
            </CustomContainer>
        </>
    );
}

const formatContent = (content) => {
    // Return empty string if content is undefined/null
    if (!content) return '';

    // Convert dash-prefixed lines into list items
    return content.replace(/^-\s*(.*?)$/gm, '<li>$1</li>')
        .replace(/((?:<li>.*?<\/li>\n?)+)/g, '<ul>$1</ul>');
};

export default Summary;
