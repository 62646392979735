import React, { useState, useEffect, useContext } from 'react';
import {
  Card, CardContent, Typography, Box, Grid, Container,
  Button, Skeleton, Tooltip, Chip, Divider, CircularProgress
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSubscription } from "../Context/SubscriptionContext";
import Premium from '../PopUpsAlerts/Premium/Premium';
import useStylesPreguntasDocument from '../Documents/Styles/useStylePreguntasDocument';
import LockIcon from '@mui/icons-material/Lock';
import PopupFlashcard from './PopupFlashcards';
import CircleIcon from '@mui/icons-material/Circle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import styled from '@emotion/styled';
import { fetchFlashcard } from '../Api/FlashcardsApi';
import { useAuth } from "../Auth/Authhandle";
import { WebSocketContext } from '../WebSocketProvider';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { fetchRegenerateFlashcards } from '../Api/FilesApi';
import PremiumPopup from '../Stripe/PricingComponents/PremiumPopup';
import { Link } from 'react-router-dom';

import { deleteFlashcard } from '../Api/FlashcardsApi';
import WelcomePopup from '../AllModalsActions/WelcomePopup';
const SolutionText = styled('span')({
  color: '#66BB6A',
  fontWeight: 500,
});

const formatTextWithSolutions = (text) => {
  if (!text) return '';

  const parts = text.split(/(\[.*?\])/g);
  return parts.map((part, index) => {
    if (part.startsWith('[') && part.endsWith(']')) {
      return (
        <SolutionText key={index}>
          {part.slice(1, -1)}
        </SolutionText>
      );
    }
    return part;
  });
};

const FlashcardDoc = ({ documentId, isGeneratingFlashcards, setIsGeneratingFlashcards, setNumberOfFlashcards, numberOfFlashcards, setTotalFlashcards, docInfo }) => {
  const classes = useStylesPreguntasDocument();
  const { userData } = useSubscription();
  const [open, setOpen] = useState(false);
  const [selectedFlashcard, setSelectedFlashcard] = useState(null);
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  const [showFlashcard, setShowFlashcard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState('Sin empezar');
  const [visibleFlashcards, setVisibleFlashcards] = useState(5);
  const [flashcards, setFlashcards] = useState([]);
  const { accessToken, user } = useAuth();
  const { messageCallback } = useContext(WebSocketContext);
  const [openRegenerateFlashcardsModal, setOpenRegenerateFlashcardsModal] = useState(false);
  const [showPremiumPopup, setShowPremiumPopup] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [flashcardToDelete, setFlashcardToDelete] = useState(null);

  const [isShowingFlashcards, setIsShowingFlashcards] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const loadFlashcards = async () => {

      try {
        setLoading(true);
        const data = await fetchFlashcard(accessToken, documentId, user);
        setFlashcards(data);
        setError(null);
      } catch (err) {
        setError('Error loading flashcards: ' + err.message);
        console.error('Error loading flashcards:', err);
      } finally {
        setLoading(false);
      }
    };

    if (accessToken && user) {
      loadFlashcards();
    }
  }, [documentId, accessToken, user]);
  useEffect(() => {
    if (userData?.plan_name?.startsWith("Basic")) {
      setShowPremium(true);
    }
  }, [userData]);

  useEffect(() => {
    if (messageCallback) {
      if (messageCallback.type === 'flashcards_saved') {
        setIsGeneratingFlashcards(false);
        setIsShowingFlashcards(true);
        const data = messageCallback;
        if (data.document_id === documentId) {

          setFlashcards(prevFlashcards => {
            const newFlashcards = data.flashcards.map(flashcard => ({
              id: flashcard.guid,
              type: flashcard.type,
              text: flashcard.type === 'cloze' ? flashcard.text : undefined,
              question: flashcard.type === 'question' ? flashcard.text : undefined,
              answer: flashcard.answer,
              active: true,
              attempts: 0
            }));

            const existingIds = new Set(prevFlashcards.map(f => f.id));
            const uniqueNewFlashcards = newFlashcards.filter(f => !existingIds.has(f.id));
            setNumberOfFlashcards(numberOfFlashcards + uniqueNewFlashcards.length);
            setTotalFlashcards(numberOfFlashcards + uniqueNewFlashcards.length);

            return [...prevFlashcards, ...uniqueNewFlashcards];
          });
        }
      }
      if (messageCallback.type === 'flashcards_updated_done') {
        setIsShowingFlashcards(false);
      }
    }
  }, [messageCallback, documentId]);

  const handleOpen = (flashcard) => {
    setOpen(true);
    setSelectedFlashcard(flashcard);
    setShowFlashcard(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowFlashcard(false);
  };

  const handleShowPremium = (titleText) => {
    setShowPremium(true);
    // setPremiumTitleText(titleText);
  };
  const handleOpenRegenerateFlashcardsModal = () => {
    setOpenRegenerateFlashcardsModal(true);
  };
  const handleCloseRegenerateFlashcardsModal = () => {
    setOpenRegenerateFlashcardsModal(false);
  };


  const getFilterCounts = () => {
    const counts = {
      'Sin empezar': 0,
      'Fácil': 0,
      'Normal': 0,
      'Difícil': 0,
      'Muy difícil': 0
    };

    flashcards.forEach(card => {
      if (card.attempts === 0) {
        counts['Sin empezar']++;
      } else if (card.attempts < 1.5) {
        counts['Fácil']++;
      } else if (card.attempts < 2.5) {
        counts['Normal']++;
      } else if (card.attempts < 3.5) {
        counts['Difícil']++;
      } else if (card.attempts >= 3.5) {
        counts['Muy difícil']++;
      }
    });

    return counts;
  };

  const filters = [
    { value: 'Sin empezar', label: `Sin empezar (${getFilterCounts()['Sin empezar']})`, color: '#A0A0A0' },
    { value: 'Fácil', label: `Fácil (${getFilterCounts()['Fácil']})`, color: '#6BBDBD' },
    { value: 'Normal', label: `Normal (${getFilterCounts()['Normal']})`, color: '#6BBDBD' },
    { value: 'Difícil', label: `Difícil (${getFilterCounts()['Difícil']})`, color: '#FCAB32' },
    { value: 'Muy difícil', label: `Muy difícil (${getFilterCounts()['Muy difícil']})`, color: '#AA161D' }
  ];

  const SkeletonLoader = () => (
    <Container className={classes.scrollableContainer}>
      {[1, 2, 3].map((_, index) => (
        <Box key={index} mb={2}>
          <Skeleton variant="rectangular" height={80} />
        </Box>
      ))}
    </Container>
  );



  const handleShowMore = () => {
    setVisibleFlashcards(prev => prev + 5);
  };

  const getFilteredFlashcards = () => {
    return flashcards.filter(card => {
      switch (selectedDifficulty) {
        case 'Sin empezar':
          return card.attempts === 0;
        case 'Fácil':
          return card.attempts < 1.5 && card.attempts > 0;
        case 'Normal':
          return card.attempts < 2.5 && card.attempts > 1.5;
        case 'Difícil':
          return card.attempts < 3.5 && card.attempts > 2.5;
        case 'Muy difícil':
          return card.attempts >= 3.5;
        default:
          return true;
      }
    });
  };

  const handleDeleteClick = (e, flashcard) => {
    e.stopPropagation(); // Prevent card from opening
    setFlashcardToDelete(flashcard);
    setDeleteDialogOpen(true);

  };
  const handleRegenerateFlashcards = () => {
    setIsGeneratingFlashcards(true);
    fetchRegenerateFlashcards(accessToken, documentId, user)
    // Here you would add the API call to regenerate questions
    // For now, just closing the modal
    handleCloseRegenerateFlashcardsModal();
    setIsGeneratingFlashcards(false);
    docInfo.regenerate_flashcards_done = true;
  };

  const handleDeleteConfirm = async () => {
    if (flashcardToDelete) {
      setIsDeleting(true);
      try {
        await deleteFlashcard(accessToken, flashcardToDelete.guid, user);
        setFlashcards(prevFlashcards =>
          prevFlashcards.filter(card => card.guid !== flashcardToDelete.guid)
        );
        setNumberOfFlashcards(prev => Math.max(0, prev - 1));
        setTotalFlashcards(prev => Math.max(0, prev - 1));

      } catch (error) {
        console.error('Error deleting flashcard:', error);
        // Optionally add error handling here
      } finally {
        setIsDeleting(false);
        setDeleteDialogOpen(false);
        setFlashcardToDelete(null);
      }
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setFlashcardToDelete(null);
  };

  if (loading) return <SkeletonLoader />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (flashcards.length === 0) {
    const handleGenerateFlashcards = async () => {
      if (userData.plan_name.startsWith("Basic")) {
        setShowPremium(true);
        return;
      }
      setIsGeneratingFlashcards(true);
      try {
        await fetchFlashcard(accessToken, documentId, user, 'POST');
      } catch (err) {
        setError('Error generating flashcards: ' + err.message);
        console.error('Error generating flashcards:', err);
      } finally {
        setIsGeneratingFlashcards(false);
      }
    };
    console.log("docInfo", docInfo)

    return (


      <Container className={classes.scrollableContainer}>

        {(userData?.plan_name?.startsWith("Basic")) && (
          <Typography
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '16px',
              color: '#666',
              marginBottom: '16px'
            }}
          >
            Sólo puedes usar flashcards con el
            <Link
              to="/plans"
              style={{
                textDecoration: 'underline',
                color: '#6BBDBD',
                fontWeight: 'bold',
                marginLeft: '4px',
                cursor: 'pointer'
              }}
            >
              plan Premium
            </Link>
          </Typography>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="200px"
        >

          {isGeneratingFlashcards ? (
            <>
              <CircularProgress size={24} sx={{ mb: 2, color: '#6BBDBD' }} />
              <Typography
                sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '16px',
                  color: '#666',
                  textAlign: 'center',
                  marginBottom: '16px'
                }}
              >
                Generando flashcards...
              </Typography>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '16px',
                  color: '#666',
                  textAlign: 'center',
                  marginBottom: '16px'
                }}
              >
                Haz click para generar tus flashcards
              </Typography>
              <Button
                onClick={() => handleGenerateFlashcards()}
                disabled={isGeneratingFlashcards}
                sx={{
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: '600',
                  fontFamily: "'Fira Sans', sans-serif",
                  color: 'white',
                  backgroundColor: '#6BBDBD',
                  padding: '8px 16px',
                  borderRadius: '24px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#5AACAC'
                  }
                }}
              >
                Generar flashcards
              </Button>
            </>
          )}
        </Box>
      </Container>
    );
  }
  return (
    <>
      {showPremium && (
        <PremiumPopup
          open={true}
          onClose={() => setShowPremium(false)}
        />
      )}

      {showFlashcard && selectedFlashcard && (
        <PopupFlashcard
          onClose={handleClose}
          front={selectedFlashcard.front}
          back={selectedFlashcard.back}
        />
      )}


      <Container className={classes.scrollableContainer}>
        {(userData?.plan_name?.startsWith("Basic")) && (
          <Typography
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '16px',
              color: '#666',
              marginBottom: '16px'
            }}
          >
            Sólo puedes usar flashcards con el
            <Link
              to="/plans"
              style={{
                textDecoration: 'underline',
                color: '#6BBDBD',
                fontWeight: 'bold',
                marginLeft: '4px',
                cursor: 'pointer'
              }}
            >
              plan Premium
            </Link>
          </Typography>
        )}
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          paddingRight: '25px',
          paddingLeft: '25px',
          marginBottom: '16px'
        }}>
          {!docInfo.regenerate_flashcards_done && (
            <Button
              onClick={handleOpenRegenerateFlashcardsModal}
              startIcon={<RefreshIcon />}
              sx={{
                backgroundColor: '#6BBDBD',
                color: 'white',
                borderRadius: '20px',
                padding: '6px 16px',
                textTransform: 'none',
                fontFamily: 'Fira Sans',
                fontWeight: 600,
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: '#5aa9a9',
                },
              }}
            >
              Regenerar Flashcards
            </Button>
          )}
        </Box>
        {userData.plan_name === "Free" && (
          <Typography
            sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '14px',
              color: '#AA161D',
              textAlign: 'left',
              marginBottom: '16px',
              paddingLeft: '16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <LockIcon sx={{ fontSize: 16 }} />
            <Link to="/pricing" style={{ textDecoration: 'underline', color: '#AA161D', cursor: 'pointer' }}>
              Tienes flashcards bloqueadas. Suscríbete para desbloquearlas.
            </Link>
          </Typography>
        )}

        {isShowingFlashcards && (
          <>
            <Typography
              sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '16px',
                color: '#666',
                textAlign: 'center',
                marginBottom: '16px'

              }}
            >
              Se estan generando flashcards...
            </Typography>
          </>
        )}
        <Box sx={{
          display: 'flex',
          gap: 1,
          mb: 2,
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
          {filters.map((filter) => (
            <Chip
              key={filter.value}
              label={filter.label}
              onClick={() => setSelectedDifficulty(filter.value)}
              sx={{
                backgroundColor: selectedDifficulty === filter.value ? filter.color : '#f5f5f5',
                color: selectedDifficulty === filter.value ? 'white' : 'black',
                '&:hover': {
                  backgroundColor: selectedDifficulty === filter.value ? filter.color : '#e0e0e0',
                },
                fontFamily: 'Inter, sans-serif',
                fontWeight: 600,
                borderRadius: '20px',
                padding: '8px 16px',
              }}
            />
          ))}
        </Box>

        <Box style={{
          marginLeft: '16px',
          marginRight: '32px',
          width: 'calc(100% - 48px)'
        }}>
          {getFilteredFlashcards().length === 0 ? (
            <Typography
              sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '16px',
                color: '#666',
                textAlign: 'center',
                marginTop: '32px'
              }}
            >
              No tienes flashcards {selectedDifficulty.toLowerCase()}
            </Typography>
          ) : (
            <>
              {getFilteredFlashcards().slice(0, visibleFlashcards).map((flashcard) => (
                <Card
                  variant="outlined"
                  className={classes.cardPreguntas}
                  key={flashcard.id}
                  style={{
                    boxShadow: 'none',
                    border: '1px solid #E2F1F1',
                    marginBottom: '16px',
                    position: 'relative',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '32px',
                    minHeight: '120px',
                    display: 'flex',

                    alignItems: 'center'
                  }}
                // onClick={() => handleOpen(flashcard)}
                >
                  <CardContent style={{
                    filter: !flashcard.active && userData.plan_name === "Free" ? 'blur(3px)' : 'none',
                    transition: 'filter 0.3s ease-in-out',
                    padding: '0',
                    width: '100%',
                    '&:last-child': {
                      paddingBottom: 0
                    }
                  }}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      height: '100%'
                    }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: 500,
                          fontSize: '14px',
                          color: '#2D2D2D',
                          lineHeight: '1.6',
                          margin: 0
                        }}>
                          {formatTextWithSolutions(flashcard.type === 'cloze' ? flashcard.text : flashcard.question)}
                        </Typography>
                        {flashcard.type !== 'cloze' && (
                          <>
                            <Divider
                              sx={{
                                my: 2.5,
                                borderColor: '#E2F1F1',
                              }}
                            />
                            <Typography sx={{
                              fontFamily: "'Fira Sans', sans-serif",
                              fontSize: '14px',
                              color: '#666',
                              lineHeight: '1.6',
                              mt: 2
                            }}>
                              {formatTextWithSolutions(flashcard.answer)}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <DeleteIcon
                        onClick={(e) => handleDeleteClick(e, flashcard)}
                        sx={{
                          color: '#666',
                          cursor: 'pointer',
                          '&:hover': {
                            color: '#AA161D'
                          }
                        }}
                      />
                    </Box>
                  </CardContent>
                  {userData.plan_name === "Free" && !flashcard.active && (
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      zIndex="1"
                    >
                      <Button
                        onClick={() => handleShowPremium('flashcards')}
                        style={{
                          textTransform: 'none',
                          fontSize: '14px',
                          fontWeight: '600',
                          fontFamily: "'Inter', sans-serif",
                          color: 'white',
                          backgroundColor: '#AA161D',
                          padding: '4px 16px',
                          borderRadius: '24px',
                          boxShadow: 'none',
                          width: 'auto'
                        }}
                      >
                        <LockIcon sx={{ color: 'white', width: 20, height: 20, marginRight: '8px' }} />
                        Desbloquear
                      </Button>
                    </Box>
                  )}
                </Card>
              ))}
            </>
          )}

          {visibleFlashcards < getFilteredFlashcards().length && (
            <Button
              onClick={handleShowMore}
              style={{
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: '600',
                fontFamily: "'Inter', sans-serif",
                color: 'white',
                backgroundColor: '#6BBDBD',
                padding: '8px 16px',
                borderRadius: '24px',
                boxShadow: 'none',
                marginTop: '16px',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              Mostrar más
            </Button>
          )}
        </Box>
      </Container>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "24px",
            maxWidth: "400px",
            width: "100%"
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Confirmar eliminación
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro de que quieres eliminar esta flashcard?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleDeleteCancel}
            disabled={isDeleting}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
            sx={{
              backgroundColor: '#AA161D',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#8A1219'
              },
              minWidth: '100px'
            }}
          >
            {isDeleting ? (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            ) : (
              'Eliminar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRegenerateFlashcardsModal}
        onClose={handleCloseRegenerateFlashcardsModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Regenerar preguntas
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#666666',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro que quieres regenerar las preguntas? Las preguntas actuales se perderán.
          </Typography>
        </DialogTitle>

        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleCloseRegenerateFlashcardsModal}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleRegenerateFlashcards}
            disabled={isGeneratingFlashcards}
            sx={{
              backgroundColor: '#FFBF60',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#FFBF60'
              }
            }}
          >
            {isGeneratingFlashcards ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Regenerar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FlashcardDoc;