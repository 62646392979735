// PlanSelection.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { getCsrfToken } from '../Api/TokenApi'
import { useAuth } from "../Auth/Authhandle";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box, Slider } from '@mui/material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { makeStyles } from "@material-ui/core/styles";
import { fetchUserSubscription } from '../Api/UserApi';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import usePlanStyles from "./PlanStyles"
import { styled } from '@mui/system';
import { useSubscription } from '../Context/SubscriptionContext';

const REACT_APP_STRIPE_LOADER = process.env.REACT_APP_STRIPE_LOADER;

const stripePromise = loadStripe(REACT_APP_STRIPE_LOADER);
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CustomSlider = styled(Slider)({
    color: '#6BBDBD',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#6BBDBD',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#E2F1F1',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#E2F1F1',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
});

const PlanSelection = () => {
    const [plans, setPlans] = useState([]);
    const [freePlan, setFreePlan] = useState({})
    const { accessToken, user } = useAuth(); // Use the hook at the top level
    const classes = usePlanStyles();
    const { t } = useTranslation();
    const [credits, setCredits] = useState(300);
    const [price, setPrice] = useState(3);
    const { userData } = useSubscription();
    const [currentPlan, setCurrentPlan] = useState("")
    const handleSliderChange = (event, newValue) => {
        setCredits(newValue);
        setPrice((newValue * 0.01).toFixed(2));
    };
    useEffect(() => {
        if (userData && userData.plan_id) {
            setCurrentPlan(userData.plan_id);
        }
    }, [userData]);
    // useEffect(() => {
    //     // Here you would fetch the plans from your backend
    //     // For this example, we'll just hardcode them
    //     // const mockPlans = [
    //     //     { id: "price_1OwNdFGRUIwiA10DK87WO4LW", name: "Gratuito", price: "0", items: ['3 carpetas', '3 archivos por carpeta', 'Genera 20 preguntas con su solución', '5 resúmenes', '50 preguntas al chat', 'Con publicidad', 'Resúmenes y exámenes con marca de agua'] },
    //     //     { id: "price_1P5Yy8GRUIwiA10DlOpMFBt8", name: "Básico", price: "9,99", items: ['5 carpetas', '10 archivos por carpeta', 'Genera 50 preguntas con su solución', '20 resúmenes', '100 preguntas al chat', 'Sin publicidad', 'Resúmenes y exámenes con marca de agua'] },
    //     //     { id: "price_1P5Yv0GRUIwiA10D1gOWQn1O", name: "Pro", price: "19,99", items: ['Carpetas ilimitadas', 'Sube tantos apuntes como quieras', 'Genera 200 preguntas con su solución', 'Resúmenes ilimitados', 'Preguntas al chat ilimitadas', 'Sin publicidad', 'Resúmenes y exámenes sin marca de agua'] },
    //     // ];
    //     const plansData = t('plans', { returnObjects: true });

    //     const paymentPlans = plansData.slice(1, 3);

    //     setFreePlan(plansData[0])
    //     setPlans(paymentPlans);
    // }, []);

    useEffect(() => {
        let plansData = t('plans', { returnObjects: true });
        plansData = plansData.map((plan, index) => {
            const planIDs = [
                process.env.REACT_APP_FREE_PLAN_ID,
                process.env.REACT_APP_QUARTERLY_PLAN_ID,
                process.env.REACT_APP_MONTHLY_PLAN_ID
            ];
            return { ...plan, id: planIDs[index] };
        });

        // const paymentPlans = plansData.slice(1); // Exclude the free plan for payment options
        setFreePlan(plansData[0]); // Set the free plan
        // console.log("plans", plansData)
        setPlans(plansData); // Set paid plans
        setCurrentPlan(userData.plan_id)
    }, []);


    useEffect(() => {
        const fetchData = async () => {

            if (accessToken && user) {

                const subResult = await fetchUserSubscription(accessToken, user);

            }

        }
        fetchData()
    });

    const handleBuyCredits = async (quantity) => {
        try {
            const csrfToken = getCsrfToken(); // Get CSRF token for non-GET requests

            // Call your backend to create a checkout session
            const response = await fetch(`${BACKEND_URL}/create-checkout-session/?user_id=${user['sub']}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Bearer ${accessToken}`, // Include the token in the Authorization header
                },
                body: JSON.stringify({ "quantity": quantity, "priceId": "price_1PaWliGRUIwiA10D1h389hDU" }), // Adjusted from plan.priceId to plan.id
            });

            const result = await response.json();

            // When the backend returns the session ID, redirect to Stripe checkout
            if (response.ok && (result.subscriptionUpdated)) {
                // Redirect to a success page
                window.location.replace("/")
            } else if (response.ok && result.subscriptionCreated) {
                window.location.replace("/")
            }
            else if (response.ok && result.sessionId) {
                // When the backend returns a session ID for a new checkout, redirect to Stripe checkout
                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({
                    sessionId: result.sessionId,
                });
                if (error) {
                    console.error('Stripe Checkout error:', error.message);
                }
            } else {
                // Handle any errors or unexpected responses
                console.error('Unexpected response:', result);
            }
        } catch (error) {
            console.error('Failed to start the checkout process:', error);
        }
    };

    const handlePlanSelected = async (plan) => {
        try {
            const csrfToken = getCsrfToken(); // Get CSRF token for non-GET requests
            // Call your backend to create a checkout session
            const response = await fetch(`${BACKEND_URL}/create-checkout-session/?user_id=${user['sub']}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Bearer ${accessToken}`, // Include the token in the Authorization header
                },
                body: JSON.stringify({ "priceId": plan.id }), // Adjusted from plan.priceId to plan.id
            });

            const result = await response.json();

            // When the backend returns the session ID, redirect to Stripe checkout
            if (response.ok && (result.subscriptionUpdated)) {
                // Redirect to a success page
                window.location.replace("/")
            } else if (response.ok && result.subscriptionCreated) {
                window.location.replace("/")
            }
            else if (response.ok && result.sessionId) {
                // When the backend returns a session ID for a new checkout, redirect to Stripe checkout
                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({
                    sessionId: result.sessionId,
                });
                if (error) {
                    console.error('Stripe Checkout error:', error.message);
                }
            } else {
                // Handle any errors or unexpected responses
                console.error('Unexpected response:', result);
            }
        } catch (error) {
            console.error('Failed to start the checkout process:', error);
        }
    };

    return (
        <div style={{ overflowY: "scroll", height: "100%" }}>
            <Grid container className={classes.Maincontainer}>
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <Typography style={{ fontSize: 35, fontWeight: 700, marginRight: "0px" }}>{t("we_adapt")}</Typography>
                    {/* <Typography style={{ fontSize: 25, fontWeight: 500, color: "gray" }}>Elige el plan más adecuado</Typography> */}
                </div>
                <Grid className={classes.container}>
                    {plans.map((plan, index) => (
                        <Grid item key={plan.id}>
                            <Card className={index === 1 ? classes.root2 : classes.root}>
                                <CardContent style={{ padding: "30px" }}>
                                    <Typography className={index === 1 ? classes.priceStyle2 : classes.priceStyle}>
                                        {index !== 0 ? (
                                            <> € {plan.price}<span className={classes.smallFont}> /{t("monthly")}</span></>
                                        ) : (
                                            <>  {plan.price}
                                            </>
                                        )}
                                    </Typography>
                                    <Typography className={index === 1 ? classes.planName2 : classes.planName}>

                                        <>
                                            {plan.name}
                                        </>

                                    </Typography>
                                    <Typography className={index === 1 ? classes.planDescription2 : classes.planDescription}>
                                        {plan.description}
                                    </Typography>

                                    <List>
                                        {plan.items && plan.items.map((item, indexlist) => (
                                            <ListItem key={indexlist} className={index === 1 ? classes.listItem2 : classes.listItem}>
                                                <ListItemIcon className={index === 1 ? classes.listItemIcon2 : classes.listItemIcon}>
                                                    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                                                        <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                                                    </svg>


                                                    {/* <CheckCircleOutlineIcon className={index === 1 ? classes.checkIcon2 : classes.checkIcon} /> */}
                                                </ListItemIcon>
                                                <ListItemText primary={item} className={index === 1 ? classes.element2 : classes.element} style={{ fontSize: "12px !important" }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                                <CardActions className={index === 1 ? classes.buttonContainer : classes.buttonContainer}>
                                    <Button
                                        size="large"

                                        className={index === 2 ? classes.button3 : index === 0 ? classes.button : classes.button2}
                                        onClick={() => handlePlanSelected(plan)}
                                        disabled={plan.id === currentPlan} // Optionally disable the button

                                    >
                                        {plan.id === currentPlan ? t("your_current_plan") : t("choose_plan")}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}

                </Grid>
            </Grid>
            {/* <Grid>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    marginRight="0px"
                    height="130px"

                >
                    <Typography style={{ fontSize: "14px" }}>
                        {t("more_credits")}
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Typography>{credits}</Typography>
                        <CustomSlider
                            value={credits}
                            min={300}
                            max={2000}
                            step={100}
                            onChange={handleSliderChange}
                            style={{ margin: '0 20px', width: '300px', fontSize: "14px" }}
                        />

                        <Typography>€{price}</Typography>
                    </Box>
                    <Button variant="contained" color="primary" onClick={() => { handleBuyCredits(credits) }} className={classes.button} style={{ width: "120px" }}>
                        {t("buy_credits")}
                    </Button>
                </Box>
            </Grid> */}
        </div>
    );

};



export default PlanSelection;
