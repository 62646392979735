import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Checkbox,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QuizIcon from '@mui/icons-material/Quiz';
import { styled } from '@mui/material/styles';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};

const CustomCheckbox = styled(Checkbox)({
  color: '#CCCCCC',
  '&.Mui-checked': {
    color: '#6BBDBD',
  },
  '&:hover': {
    backgroundColor: 'rgba(107, 189, 189, 0.04)',
  },
});

const StudyDocumentsModal = ({ open, onClose, documents, onStartStudying }) => {
  const [selectedDoc, setSelectedDoc] = useState(null);

  const handleToggleDocument = (docId) => {
    setSelectedDoc(selectedDoc === docId ? null : docId);
  };

  const calculateEstimatedTime = (flashcardCount) => {
    const minutes = Math.ceil((flashcardCount * 30) / 60);
    return `${minutes} min`;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="study-documents-modal"
      onKeyDown={(e) => e.key === 'Escape' && onClose()}
    >
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box>
            <Typography
              id="study-documents-modal"
              variant="h6"
              sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontWeight: 600
              }}
            >
              Flashcards pendientes
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Fira Sans', sans-serif",
                color: 'text.secondary',
                fontSize: '0.875rem',
                mt: 0.5
              }}
            >
              Selecciona qué documento quieres memorizar
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        {documents.length === 0 ? (
          <Box sx={{ 
            textAlign: 'center', 
            py: 4,
            color: 'text.secondary'
          }}>
            <Typography sx={{ fontFamily: "'Fira Sans', sans-serif" }}>
              No hay documentos con flashcards pendientes
            </Typography>
          </Box>
        ) : (
          <List className="custom-scrollbar" sx={{ 
            height: 300,
            overflowY: 'scroll',
            mb: 2,
            pr: 2,
            '& .MuiListItem-root': {
              borderRadius: 1,
              mb: 1,
              bgcolor: 'background.paper',
              border: '1px solid',
              borderColor: 'divider',
            }
          }}>
            {documents.map((doc) => (
              <ListItem
                key={doc.id}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleToggleDocument(doc.id)}
              >
                <ListItemIcon>
                  <CustomCheckbox
                    edge="start"
                    checked={selectedDoc === doc.id}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          borderRadius: 1,
                          bgcolor: '#6BBDBD',
                          flexShrink: 0
                        }}
                      />
                      <Typography sx={{ fontWeight: 500, fontFamily: "'Fira Sans', sans-serif" }}>
                        {doc.title}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 2,
                      mt: 0.5
                    }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        color: 'text.secondary',
                        fontSize: '0.875rem',
                        fontFamily: "'Fira Sans', sans-serif"
                      }}>
                        <QuizIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                        {doc.pendingFlashcards} pendientes
                      </Box>
                      <Tooltip title="Tiempo aproximado">
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          color: 'text.secondary',
                          fontSize: '0.875rem',
                          fontFamily: "'Fira Sans', sans-serif"
                        }}>
                          <AccessTimeIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                          {calculateEstimatedTime(doc.pendingFlashcards)}
                        </Box>
                      </Tooltip>
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button
            onClick={onClose}
            sx={{
              textTransform: 'none',
              color: 'text.secondary',
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={selectedDoc === null}
            onClick={() => {
              onStartStudying([selectedDoc]);
              setSelectedDoc(null);
              onClose();
            }}
            sx={{
              textTransform: 'none',
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '14px',
              borderRadius: '20px',
              boxShadow: 'none',
              bgcolor: '#6BBDBD',
              '&:hover': {
                bgcolor: '#5AACAC',
              },
            }}
          >
            Comenzar estudio
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default StudyDocumentsModal; 