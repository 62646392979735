import React, { useState, useEffect } from 'react';
import { Container, Title, Subtitle } from './useStyleNewExam';
import { Box, Button, Paper, Tabs, Tab, Typography, Grid, ListItemIcon, ListItemText, Tooltip, Checkbox, ToggleButtonGroup, ToggleButton, Skeleton } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth/Authhandle';
import * as handle from "../Main/handle";
import '../Main/style.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SelectDocument from './SelectDocument';
import styled from '@emotion/styled';
import Select from 'react-select';
import Dialog from '@mui/material/Dialog';
import { useSubscription } from '../Context/SubscriptionContext';

import CustomizeFlashcards from './CustomizeFlashcards';
import { fetchFlashcardsFolders, createSetFlashcards } from '../Api/FlashcardsApi';
import CustomizeTask from './CustomizeTask';
import { useCredits } from '../Context/CreditsContext';
import NeedCredits from '../NoCreditsModal/NeedCredits';
const CustomCheckbox = styled(Checkbox)({
  color: '#CCCCCC',
  '&.Mui-checked': {
    color: '#6BBDBD',
  },
  '&:hover': {
    backgroundColor: 'rgba(107, 189, 189, 0.04)',
  },
});

const DIFFICULTY_COLORS = {
  'Sin responder': '#E0E0E0',
  'Fácil': '#4CAF50',
  'Normal': '#2196F3',
  'Difícil': '#FFC107',
  'Muy difícil': '#FF5722'
};

const useStyles = makeStyles(() => ({
  paperLeft: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    flexGrow: 1,
    height: "100%",
    overflowY: "auto",
    backgroundColor: '#F8FFFF !important',
  },
  tabs: {
    '& .MuiTab-root': {
      textTransform: 'none',
      fontFamily: 'Fira Sans',
      fontSize: '16px',
      fontWeight: 500,
      color: '#666',
      '&.Mui-selected': {
        color: '#6BBDBD',
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#6BBDBD',
    },
  },
}));

const NewFlashcards = () => {
  const { accessToken, user } = useAuth();
  const { userData } = useSubscription();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentObject, setSelectedDocumentObject] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [listDocuments, setListDocuments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState({ value: 'all', label: 'Todas las carpetas' });
  const [showCustomization, setShowCustomization] = useState(false);
  const [showCustomizeTask, setShowCustomizeTask] = useState(false);
  const [showNeedCredits, setShowNeedCredits] = useState(false);

  const { credits } = useCredits();



  useEffect(() => {
    if (accessToken && user) {
      async function fetchData() {
        setIsLoading(true);
        try {
          const response = await fetchFlashcardsFolders(accessToken, user);

          // Check if response has the expected data structure
          const data = response?.data || response;

          // Extract the flashcards arrays from the response
          const transformedDocs = {
            pending_flashcards: data.pending_flashcards || [],
            all_flashcards: data.all_flashcards || []
          };

          setListDocuments(transformedDocs);

          // Set initial selection if there are flashcards available
          if (transformedDocs.all_flashcards.length > 0 && !selectedDocument) {
            setSelectedDocument(transformedDocs.all_flashcards[0].document_guid);
            setSelectedDocumentObject(transformedDocs.all_flashcards[0]);
          }

        } catch (error) {
          console.error('Error fetching flashcard folders:', error);
        } finally {
          setIsLoading(false);
        }
      }
      fetchData();
    }
  }, [accessToken, user, location]);

  useEffect(() => {
    if (selectedDocument && listDocuments?.length > 0) {
      const foundDocument = listDocuments.find(doc => doc.guid === selectedDocument);
      setSelectedDocumentObject(foundDocument);
    } else {
      setSelectedDocumentObject(null);
    }
  }, [listDocuments]);

  const handleDocumentSelect = (document) => {
    setSelectedDocument(document.document_guid);
    setSelectedDocumentObject(document);
  };

  const handleCustomizationSave = async (customization) => {
    if (credits === -100 || credits >= 40) {

      if (activeTab === 1) {
        try {
          const flashcardData = {
            set_flashcard_id: selectedDocumentObject?.set_flashcard_id,
            // Add any other necessary data for pending flashcards
          };

          const response = await createSetFlashcards(
            accessToken,
            selectedDocument,
            flashcardData,
            user
          );

          navigate('/flashcards', {
            state: {
              documentGuid: selectedDocument,
              studyMode: customization.studyMode,
              customization: {
                documentTitle: selectedDocumentObject?.document_title,
                folderName: selectedDocumentObject?.folder_name,
                folderColor: selectedDocumentObject?.folder_color,
              },
              flashcardsSet: response
            }
          });
        } catch (error) {
          console.error('Error creating flashcards set:', error);
        }
      }
      if (activeTab === 0) {
        try {
          const flashcardData = {
            num_flashcards: customization.cardCount,
            difficulty: customization.cardTypes,
          };

          const response = await createSetFlashcards(
            accessToken,
            selectedDocument,
            flashcardData,
            user
          );
          navigate('/flashcards', {
            state: {
              documentGuid: selectedDocument,
              studyMode: customization.studyMode,
              customization: {
                cardCount: customization.cardCount,
                documentTitle: selectedDocumentObject?.document_title,
                folderName: selectedDocumentObject?.folder_name,
                folderColor: selectedDocumentObject?.folder_color,
                difficulty: customization.difficulty
              },
              flashcardsSet: response
            }
          });
        } catch (error) {
          console.error('Error creating flashcards set:', error);
        }
      }
    } else {
      console.log("Customization saved appear credits")
      setShowNeedCredits(true);
    }
  };

  const calculateEstimatedTime = (flashcardCount) => {
    const minutes = Math.ceil((flashcardCount * 15) / 60);
    return `${minutes} min`;
  };

  const getDummyDistribution = (totalCards) => {
    return {
      'Sin responder': Math.floor(totalCards * 0.4),
      'Fácil': Math.floor(totalCards * 0.2),
      'Normal': Math.floor(totalCards * 0.15),
      'Difícil': Math.floor(totalCards * 0.15),
      'Muy difícil': Math.floor(totalCards * 0.1)
    };
  };

  const getFolderOptions = () => {
    if (!listDocuments?.all_flashcards) return [];
    const folders = new Set(listDocuments.all_flashcards.map(doc => doc.folder_name || 'Sin carpeta'));
    return [
      { value: 'all', label: 'Todas las carpetas' },
      ...Array.from(folders).map(folder => ({
        value: folder,
        label: folder
      }))
    ];
  };

  const renderLoadingSkeleton = () => (
    <Grid container spacing={2}>
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <Grid item xs={12} sm={6} md={4} key={item}>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              p: 1.5,
              height: '100%',
              backgroundColor: 'background.paper',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Skeleton variant="circular" width={32} height={32} sx={{ mr: 2 }} />
              <Box sx={{ width: '100%' }}>
                <Skeleton variant="text" width="80%" height={24} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                  <Skeleton variant="text" width={80} height={20} />
                  <Skeleton variant="text" width={60} height={20} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  const handleSiguiente = () => {
    setShowCustomization(true);
    // Here will go the logic for showing customization
  };

  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);

    setSelectedTask(null);
  };

  const handleButtonClick = () => {
    if (activeTab === 1) {
      // For pending flashcards, show CustomizeTask
      setShowCustomizeTask(true);
    } else {
      // For regular flashcards, show customization dialog
      handleSiguiente();
    }
  };

  const calculateCompletionPercentage = (doc) => {
    const completedCards = (doc.flashcards_easy || 0) + (doc.flashcards_normal || 0);
    const totalCards = completedCards + (doc.flashcards_hard || 0) + (doc.flashcards_veryhard || 0) + (doc.flashcards_not_started || 0);

    if (totalCards === 0) return 0;
    return Math.round((completedCards / totalCards) * 100);
  };

  const calculateTotalFlashcards = (doc) => {
    // Initial slots for new and very hard flashcards
    let total = Math.min(20, doc.flashcards_not_started || 0); // new flashcards (max 20)
    total += Math.min(50, doc.flashcards_very_hard || 0); // very hard flashcards (max 50)

    // Calculate remaining slots (70 - new - veryhard)
    let remainingSlots = 70 - total;

    // Add hard flashcards
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, doc.flashcards_hard || 0);
      remainingSlots -= Math.min(remainingSlots, doc.flashcards_hard || 0);
    }

    // Add normal flashcards
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, doc.flashcards_normal || 0);
      remainingSlots -= Math.min(remainingSlots, doc.flashcards_normal || 0);
    }

    // Add easy flashcards
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, doc.flashcards_easy || 0);
      remainingSlots -= Math.min(remainingSlots, doc.flashcards_easy || 0);
    }

    // Add any remaining not started flashcards if there are still slots
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, Math.max(0, (doc.flashcards_not_started || 0) - 20));
    }

    return total;
  };
  if (userData?.plan_name?.startsWith("Basic")) {
    return (
      <Container style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "1000px",
        width: "100%",
        position: 'relative',
        zIndex: 1,
        margin: "0 auto",
        paddingBottom: "0px"
      }}>
        <Typography variant="h6" color="textSecondary" align="center" sx={{ mt: 4 }}>
          Solo puedes realizar flashcards con el plan premium
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "20px" }}>
          <Button variant="contained" color="primary" sx={{
            bgcolor: '#6BBDBD',
            width: "150px",
            color: 'white',
            textTransform: 'none',
            fontSize: "14px",
            borderRadius: "20px",
            padding: "6px 22px",
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            '&:hover': {
              bgcolor: '#5AACAC',
            },
            '&.Mui-disabled': {
              bgcolor: '#cccccc',
              color: '#666666'
            }
          }}
            onClick={() => navigate('/plans')}>Elegir plan</Button>
        </Box>
      </Container>
    );
  }
  return (
    <Container style={{
      display: "flex",
      flexDirection: "column",
      maxWidth: "1000px",
      width: "100%",
      position: 'relative',
      zIndex: 1,
      margin: "0 auto",
      paddingBottom: "0px"
    }}>
      {
        showNeedCredits && (
          <NeedCredits
            initialOpen={showNeedCredits}
            onClose={() => setShowNeedCredits(false)}
          />
        )
      }
      <Paper elevation={0} className={classes.paperLeft}>
        {!showCustomization && !showCustomizeTask ? (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px' }}>
              <Title>Memoriza con flashcards</Title>
              <Subtitle sx={{ marginTop: '8px', marginBottom: '12px' }}>
                Retén toda la información de tus documentos con tarjetas de memoria
              </Subtitle>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                className={classes.tabs}
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab label="Todas tus flashcards" />
                <Tab label="Flashcards Pendientes" />

              </Tabs>

              {selectedDocument && !showCustomization && (
                <Button
                  variant="contained"
                  onClick={handleButtonClick}
                  disabled={!selectedTask}
                  sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Inter', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 32px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    '&:hover': {
                      backgroundColor: '#5AACAC',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#cccccc',
                      color: '#ffffff',
                    },
                  }}
                >
                  {activeTab === 1 ? 'Siguiente' : 'Siguiente'}
                </Button>
              )}
            </Box>

            {activeTab === 0 && !showCustomization ? (
              <>
                <Box sx={{
                  mb: 3,
                  width: '250px',
                  alignSelf: 'flex-start'
                }}>
                  <Select
                    value={selectedFolder}
                    onChange={(option) => setSelectedFolder(option)}
                    options={getFolderOptions()}
                    placeholder="Filtrar por carpeta"
                    isDisabled={isLoading}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '8px',
                        borderColor: state.isFocused ? '#6BBDBD' : '#e0e0e0',
                        boxShadow: state.isFocused ? '0 0 0 1px #6BBDBD' : 'none',
                        '&:hover': {
                          borderColor: '#6BBDBD'
                        }
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isSelected ? '#6BBDBD' : state.isFocused ? '#e8f4f4' : 'white',
                        color: state.isSelected ? 'white' : '#333',
                        fontFamily: 'Fira Sans',
                        '&:active': {
                          backgroundColor: '#6BBDBD'
                        }
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        fontFamily: 'Fira Sans'
                      }),
                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        fontFamily: 'Fira Sans'
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        zIndex: 2
                      })
                    }}
                  />
                </Box>

                <Box
                  className="custom-scrollbar"
                  sx={{
                    height: 400,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    mb: 2,
                    pr: 2,
                  }}
                >
                  {isLoading ? (
                    renderLoadingSkeleton()
                  ) : (
                    <Grid container spacing={2}>
                      {listDocuments?.all_flashcards
                        ?.filter(doc =>
                          (selectedFolder.value === 'all' || doc.folder_name === selectedFolder.value)
                          && doc.total_flashcards > 0
                        )
                        .map((doc) => (
                          <Grid item xs={12} sm={6} md={4} key={doc.document_guid}>
                            <Box
                              onClick={() => {
                                setSelectedTask(selectedTask === doc.document_guid ? null : doc.document_guid);
                                handleDocumentSelect(doc);
                              }}
                              sx={{
                                cursor: 'pointer',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 1,
                                p: 1.5,
                                height: '100%',
                                backgroundColor: 'background.paper',
                                '&:hover': {
                                  backgroundColor: '#f5f5f5'
                                }
                              }}
                            >
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                pr: 0
                              }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                  <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                    <CustomCheckbox
                                      edge="start"
                                      checked={selectedTask === doc.document_guid}
                                      tabIndex={-1}
                                      disableRipple
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Box
                                          sx={{
                                            width: 16,
                                            height: 16,
                                            borderRadius: 1,
                                            bgcolor: doc.folder_color || '#6BBDBD',
                                            flexShrink: 0
                                          }}
                                        />
                                        <Tooltip title={doc.document_title || 'Untitled Document'}>
                                          <Typography
                                            sx={{
                                              fontWeight: 500,
                                              fontFamily: "'Fira Sans', sans-serif",
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              maxWidth: '300px'
                                            }}
                                          >
                                            {doc.document_title || 'Untitled Document'}
                                          </Typography>
                                        </Tooltip>
                                      </Box>
                                    }
                                    secondary={
                                      <Box>
                                        <Typography sx={{

                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          maxWidth: '300px',
                                          textAlign: 'left'
                                        }} >
                                          {`${doc.total_flashcards} flashcards`}
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                  <Typography sx={{
                                    color: '#6BBDBD',
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    fontFamily: "'Fira Sans', sans-serif",
                                    marginRight: "10px"
                                  }}>
                                    {`${calculateCompletionPercentage(doc)}%`}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box sx={{
                  mb: 3,
                  width: '250px',
                  alignSelf: 'flex-start'
                }}>
                  <Select
                    value={selectedFolder}
                    onChange={(option) => setSelectedFolder(option)}
                    options={getFolderOptions()}
                    placeholder="Filtrar por carpeta"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '8px',
                        borderColor: state.isFocused ? '#6BBDBD' : '#e0e0e0',
                        boxShadow: state.isFocused ? '0 0 0 1px #6BBDBD' : 'none',
                        '&:hover': {
                          borderColor: '#6BBDBD'
                        }
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isSelected ? '#6BBDBD' : state.isFocused ? '#e8f4f4' : 'white',
                        color: state.isSelected ? 'white' : '#333',
                        fontFamily: 'Fira Sans',
                        '&:active': {
                          backgroundColor: '#6BBDBD'
                        }
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        fontFamily: 'Fira Sans'
                      }),
                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        fontFamily: 'Fira Sans'
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        zIndex: 2
                      })
                    }}
                  />
                </Box>

                <Box
                  className="custom-scrollbar"
                  sx={{
                    height: 400,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    mb: 2,
                    pr: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    {listDocuments?.pending_flashcards
                      ?.filter(doc => selectedFolder.value === 'all' || doc.folder_name === selectedFolder.value)
                      .map((doc) => (
                        <Grid item xs={12} sm={6} md={4} key={doc.guid}>
                          <Box
                            onClick={() => {
                              setSelectedTask(selectedTask === doc.document_guid ? null : doc.document_guid);
                              handleDocumentSelect(doc);
                            }}
                            sx={{
                              cursor: 'pointer',
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              p: 1.5,
                              height: '100%',
                              backgroundColor: 'background.paper',
                              '&:hover': {
                                backgroundColor: '#f5f5f5'
                              }
                            }}
                          >
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%',
                              pr: 0
                            }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                  <CustomCheckbox
                                    edge="start"
                                    checked={selectedTask === doc.document_guid}
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                      <Box
                                        sx={{
                                          width: 16,
                                          height: 16,
                                          borderRadius: 1,
                                          bgcolor: doc.folder_color || '#6BBDBD',
                                          flexShrink: 0
                                        }}
                                      />
                                      <Tooltip title={doc.document_title || 'Untitled Document'}>
                                        <Typography
                                          sx={{
                                            fontWeight: 500,
                                            fontFamily: "'Fira Sans', sans-serif",
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '300px'
                                          }}
                                        >
                                          {doc.document_title || 'Untitled Document'}
                                        </Typography>
                                      </Tooltip>
                                    </Box>
                                  }
                                  secondary={
                                    <Box sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 1,
                                      mt: 0.5,
                                      width: '100%'
                                    }}>
                                      <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                      }}>
                                        <Typography sx={{
                                          color: 'text.secondary',
                                          fontFamily: "'Fira Sans', sans-serif",
                                          flexShrink: 0
                                        }}>
                                          {`${calculateTotalFlashcards(doc)} flashcards`}
                                        </Typography>
                                        <Tooltip title="Tiempo aproximado">
                                          <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'text.secondary',
                                            fontSize: '0.875rem',
                                            fontFamily: "'Fira Sans', sans-serif",
                                            flexShrink: 0
                                          }}>
                                            <AccessTimeIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                                            {calculateEstimatedTime(calculateTotalFlashcards(doc) || 0)}
                                          </Box>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  }
                                />
                                <Box sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '100%',
                                  marginLeft: 'auto',
                                  marginRight: '10px'
                                }}>
                                  <Typography sx={{
                                    color: '#6BBDBD',
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    fontFamily: "'Fira Sans', sans-serif",
                                  }}>
                                    {`${calculateCompletionPercentage(doc)}%`}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </>
            )}
          </>
        ) : showCustomizeTask ? (
          <Box sx={{ width: '100%', pt: 3 }}>
            <CustomizeTask
              onClose={() => setShowCustomizeTask(false)}
              onSave={handleCustomizationSave}
              documentStats={selectedDocumentObject}
              documentName={selectedDocumentObject?.title}
            />
          </Box>
        ) : (
          <>
            <Box sx={{ width: '100%', pt: 3 }}>
              <CustomizeFlashcards
                onClose={() => setShowCustomization(false)}
                onSave={handleCustomizationSave}
                documentStats={selectedDocumentObject}
                documentName={selectedDocumentObject?.title}
              />
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default NewFlashcards; 