import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell, LabelList } from 'recharts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import QuizIcon from '@mui/icons-material/Quiz';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import StudyDocumentsModal from './StudyDocumentsModal';
import ExplicacionFlashcard from './ExplicacionFlashcard';
import { useSubscription } from "../Context/SubscriptionContext";
import { streamJustification } from '../Exam/QdrantJustification';
import { useAuth } from '../Auth/Authhandle';
import WelcomePopup from '../AllModalsActions/WelcomePopup';

const SummaryContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: "800px",
  margin: '0 auto',
  padding: theme.spacing(2),
  fontFamily: "'Fira Sans', sans-serif",
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 22px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: '#6BBDBD',
  color: 'white',
  '&:hover': {
    backgroundColor: '#6BBDBD',
  },
}));

const MasteryBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#6BBDBD',
  borderRadius: '12px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  fontFamily: "'Fira Sans', sans-serif",
}));

const ResultCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '8px',
  border: '1px solid #E2F1F1',
  boxShadow: 'none',
}));

const DifficultyChip = styled(Box)(({ difficulty }) => {
  const colors = {
    facil: { bg: '#E8F5E9', text: '#4CAF50' },
    normal: { bg: '#E3F2FD', text: '#2196F3' },
    dificil: { bg: '#FFF3E0', text: '#FF9800' },
    muy_dificil: { bg: '#FFEBEE', text: '#F44336' },
    sin_responder: { bg: '#F5F5F5', text: '#9E9E9E' }
  };
  const color = colors[difficulty] || colors.sin_responder;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '4px 12px',
    borderRadius: '16px',
    backgroundColor: color.bg,
    color: color.text,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: "'Fira Sans', sans-serif",
  };
})

const NumberChip = styled(Box)({
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: "'Fira Sans', sans-serif",
  margin: '2px',
  cursor: 'default',
});

const SolutionText = styled('span')({
  color: '#66BB6A',
  fontWeight: 500,
});

const ExplanationButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '13px',
  fontWeight: 500,
  backgroundColor: '#F8F9FA',
  color: '#666666',
  border: '1px solid #E0E0E0',
  '&:hover': {
    backgroundColor: '#EAECEF',
  },
}));

const FlashcardSummary = ({ onRestart, stats, cards, studyMode, user, accessToken }) => {
  const navigate = useNavigate();
  const { userData } = useSubscription();
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);

  const [isStudyModalOpen, setIsStudyModalOpen] = useState(false);
  const [explanationOpen, setExplanationOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [explanationText, setExplanationText] = useState("");
  const [isLoadingExplanation, setIsLoadingExplanation] = useState(false);


  useEffect(() => {
    if (userData && userData.start_date && userData.plan_name === "Free") {
      const startDate = new Date(userData.start_date);
      const currentDate = new Date();
      const timeDifference = currentDate - startDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      if (daysDifference > 3) {
        setShowWelcomePopup(false);
      } else {
        setShowWelcomePopup(true);
      }
    }
  }, [userData]);

  const handleExplain = async (card) => {
    setExplanationOpen(true);
    setIsLoadingExplanation(true);
    setExplanationText("");
    try {
      await streamJustification(
        {
          answer: card.answer || "",
          question: card.question || "",
          text: card.text || "",
          type: card.type || "",
          userId: user['sub'],
          flashcardId: card.id || "",
          typeJustification: "flashcard",
        },
        (word) => {
          setExplanationText(prev => prev + word);
        },
        user,
        accessToken
      );

    } catch (error) {
      console.error("Error fetching explanation:", error);
      setExplanationText("Lo sentimos, no pudimos generar la explicación en este momento.");
    } finally {
      setIsLoadingExplanation(false);
    }
  };


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const initialChartData = cards.reduce((acc, card) => {
    let level;
    switch (card.appearances) {
      case 1:
        level = 'facil';
        break;
      case 2:
        level = 'normal';
        break;
      case 3:
        level = 'dificil';
        break;
      default:
        level = 'muy_dificil';
    }

    const existingEntry = acc.find(entry => entry.name === (
      level === 'facil' ? 'Fácil' :
        level === 'normal' ? 'Normal' :
          level === 'dificil' ? 'Difícil' : 'Muy Difícil'
    ));

    if (existingEntry) {
      existingEntry.value++;
    } else {
      acc.push({
        name: level === 'facil' ? 'Fácil' :
          level === 'normal' ? 'Normal' :
            level === 'dificil' ? 'Difícil' : 'Muy Difícil',
        value: 1,
        color: level === 'facil' ? '#4CAF50' :
          level === 'normal' ? '#2196F3' :
            level === 'dificil' ? '#FF9800' : '#F44336'
      });
    }
    return acc;
  }, []);

  // Define the fixed order of difficulty levels
  const difficultyOrder = ['Fácil', 'Normal', 'Difícil', 'Muy Difícil'];

  // Create the final chart data with all levels and proper ordering
  const chartData = difficultyOrder.map(name => {
    const existingData = initialChartData.find(item => item.name === name);
    return existingData || {
      name,
      value: 0,
      color: name === 'Fácil' ? '#4CAF50' :
        name === 'Normal' ? '#2196F3' :
          name === 'Difícil' ? '#FF9800' : '#F44336'
    };
  });

  const getDifficultyLabel = (difficulty) => {
    const labels = {
      facil: 'Fácil',
      normal: 'Normal',
      dificil: 'Difícil',
      muy_dificil: 'Muy Difícil',
      sin_responder: 'Sin Responder'
    };
    return labels[difficulty] || difficulty;
  };

  // Add ref for the cards container
  const cardsContainerRef = React.useRef({});

  // Add scroll handler
  const scrollToCard = (index) => {
    cardsContainerRef.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  const formatTextWithSolutions = (text) => {
    const parts = text.split(/(\[.*?\])/g);
    return parts.map((part, index) => {
      if (part.startsWith('[') && part.endsWith(']')) {
        return (
          <SolutionText key={index}>
            {part.slice(1, -1)}
          </SolutionText>
        );
      }
      return part;
    });
  };

  const handleStartStudying = (selectedDocIds) => {
    onRestart(selectedDocIds);
  };

  const getDifficultyForCard = (card) => {
    // Base difficulty purely on number of appearances
    switch (card.appearances) {
      case 1:
        return 'facil';
      case 2:
        return 'normal';
      case 3:
        return 'dificil';
      case 4:
      default:
        return 'muy_dificil';
    }
  };

  return (
    <SummaryContainer>
      {showWelcomePopup && <WelcomePopup
        open={true}
        userData={userData}
        onClose={() => setShowWelcomePopup(false)}
      />}
      <Box sx={{
        mb: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: '24px',
          fontFamily: "'Fira Sans', sans-serif",
        }}>
          Resultado de la sesión
        </Typography>

        <Box sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap'
        }}>
          <ActionButton
            onClick={() => navigate('/new-exam')}
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid #6BBDBD',
              color: '#6BBDBD',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            Realizar test
          </ActionButton>
          {/* <ActionButton
            onClick={() => setIsStudyModalOpen(true)}
            startIcon={<QuizIcon />}
          >
            Flashcards pendientes
          </ActionButton> */}
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <MasteryBox>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
              <Box component="span" sx={{
                mr: 1,
                fontSize: '1.5rem'
              }}>
                🏆
              </Box>
              <Typography sx={{
                fontSize: '20px',
                fontWeight: 500,
                fontFamily: "'Fira Sans', sans-serif",
              }}>
                Grado de dominio
              </Typography>
            </Box>
            <Typography sx={{
              fontSize: '48px',
              fontWeight: 'bold',
              mb: 1,
              fontFamily: "'Fira Sans', sans-serif",
            }}>
              {Math.round(stats.masteryGrade)}%
            </Typography>
            <Typography sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '16px',
              mb: 2,
            }}>
              {stats.masteryGrade >= 80 ? '¡Excelente progreso! ¡Sigue así!' :
                stats.masteryGrade >= 60 ? '¡Buen trabajo! Continúa practicando' :
                  stats.masteryGrade >= 40 ? 'Vas por buen camino, sigue intentándolo' :
                    'Necesitas más práctica, ¡no te rindas!'}
            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              fontFamily: "'Fira Sans', sans-serif",
              mt: 'auto'
            }}>
              <AccessTimeIcon sx={{ fontSize: '1.2rem', mr: 0.5 }} />
              {formatTime(stats.timeSpent)}
            </Box>
          </MasteryBox>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box sx={{ height: 250 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                margin={{
                  top: 30,
                  right: 10,
                  left: 10,
                }}
                barGap={0}
              >
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666',
                    fontSize: 14,
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666',
                    fontSize: 14,
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                  allowDecimals={false}
                  tickCount={5}
                />
                <Bar
                  dataKey="value"
                  radius={[4, 4, 4, 4]}
                  barSize={40}
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                    />
                  ))}
                  <LabelList
                    dataKey="value"
                    position="top"
                    content={({ x, y, width, value }) => (
                      <text
                        x={x + width / 2}
                        y={y - 10}
                        fill="#666"
                        textAnchor="middle"
                        fontSize={16}
                        fontWeight={500}
                        fontFamily="'Fira Sans', sans-serif"
                      >
                        {Math.round(value)}
                      </text>
                    )}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>

      {userData.plan_name == "Free" && (
        <Box sx={{
          textAlign: 'center',
          my: 3,
          p: 2,
          backgroundColor: '#F8F9FA',
          borderRadius: '12px',
          border: '2px dashed #CCCCCC'
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 600,
            mb: 1,
            fontFamily: "'Fira Sans', sans-serif",
            color: '#2D2D2D'
          }}>
            ¡Accede a todas las flashcards!
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#555',
            fontFamily: "'Fira Sans', sans-serif",
            mb: 1
          }}>
            Desbloquea acceso a todas las flashcards de tus documentos
          </Typography>
          <Button
            onClick={() => navigate('/plans')}
            sx={{
              mt: 1,
              fontFamily: "'Fira Sans', sans-serif",
              color: '#6BBDBD',
              textTransform: 'none',
              padding: "6px 22px",
              backgroundColor: 'white',
              border: '1px solid #6BBDBD',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            ¡Desbloquear!
          </Button>
        </Box>
      )}
      <Box sx={{ mt: 4 }}>
        <Typography sx={{
          mb: 3,
          fontSize: '20px',
          fontWeight: 500,
          fontFamily: "'Fira Sans', sans-serif",
        }}>
          Resultados individuales
        </Typography>

        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          mb: 3
        }}>
          {cards.map((card, index) => {
            const getChipColors = (appearances) => {
              const colors = {
                1: { bg: '#E8F5E9', text: '#4CAF50' },  // Facil
                2: { bg: '#E3F2FD', text: '#2196F3' },  // Normal
                3: { bg: '#FFF3E0', text: '#FF9800' },  // Dificil
                4: { bg: '#FFEBEE', text: '#F44336' },  // Muy Dificil
              };
              // If appearances is greater than 4, return muy_dificil colors
              return colors[appearances] || colors[4];
            };

            return (
              <NumberChip
                key={index}
                sx={{
                  backgroundColor: getChipColors(card.appearances || 1).bg,
                  color: getChipColors(card.appearances || 1).text,
                  cursor: 'pointer',
                }}
                onClick={() => scrollToCard(index)}
              >
                {index + 1}
              </NumberChip>
            );
          })}
        </Box>

        {cards.map((card, index) => {
          const cardDifficulty = getDifficultyForCard(card);

          return (
            <ResultCard
              key={index}
              ref={el => cardsContainerRef.current[index] = el}
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                position: 'relative'
              }}>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <DifficultyChip difficulty={cardDifficulty}>
                      {getDifficultyLabel(cardDifficulty)}
                    </DifficultyChip>
                  </Box>
                  <Typography sx={{
                    fontFamily: "'Fira Sans', sans-serif",
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#2D2D2D',
                    mb: 1
                  }}>
                    {card.question || card.text.replace(/\[.*?\]/g, '___')}
                  </Typography>
                  <Typography sx={{
                    fontFamily: "'Fira Sans', sans-serif",
                    fontSize: '14px',
                    color: '#666',
                    mb: 2
                  }}>
                    {card.type === 'question' ?
                      formatTextWithSolutions(card.answer) :
                      formatTextWithSolutions(card.text)
                    }
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ExplanationButton
                      onClick={() => {
                        setSelectedCard(card);
                        setTimeout(() => handleExplain(card), 0);
                      }}
                    >
                      Explicación
                    </ExplanationButton>
                  </Box>
                </Box>
              </Box>
            </ResultCard>
          );
        })}
      </Box>

      <StudyDocumentsModal
        open={isStudyModalOpen}
        onClose={() => setIsStudyModalOpen(false)}
        documents={[
          {
            id: 1,
            title: "TypeScript Basics",
            pendingFlashcards: 8,
            letter: "A",
            color: "#E8F5E9",
            textColor: "#4CAF50",
            lastStudied: new Date()
          },
          {
            id: 2,
            title: "JavaScript Patterns",
            pendingFlashcards: 12,
            letter: "D",
            color: "#FFF3E0",
            textColor: "#FF9800",
            lastStudied: new Date(Date.now() - 24 * 60 * 60 * 1000)
          },
          {
            id: 3,
            title: "React Fundamentals",
            pendingFlashcards: 15,
            letter: "B",
            color: "#E3F2FD",
            textColor: "#2196F3",
            lastStudied: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
          },
          {
            id: 4,
            title: "Node.js Advanced Concepts",
            pendingFlashcards: 10,
            letter: "C",
            color: "#F3E5F5",
            textColor: "#9C27B0",
            lastStudied: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
          },
          {
            id: 5,
            title: "GraphQL Mastery",
            pendingFlashcards: 6,
            letter: "E",
            color: "#FFEBEE",
            textColor: "#F44336",
            lastStudied: new Date(Date.now() - 4 * 24 * 60 * 60 * 1000)
          }
        ]}
        onStartStudying={handleStartStudying}
      />

      <ExplicacionFlashcard
        open={explanationOpen}
        onClose={() => setExplanationOpen(false)}
        question={selectedCard?.question || selectedCard?.text}
        correctAnswer={selectedCard?.answer || selectedCard?.text}
        userAnswer={selectedCard?.userAnswer}
        explanationText={explanationText}
        isLoading={isLoadingExplanation}
      />
    </SummaryContainer>
  );
};

export default FlashcardSummary; 