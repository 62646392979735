import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '12px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: '32px',
        width: '100%',
        maxWidth: '600px',
        margin: '20px',
        outline: 'none',
        maxHeight: '650px',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        fontFamily: 'Inter, sans-serif',
        color: 'black',
        fontSize: '14px',
        whiteSpace: 'pre-wrap',
        overflowY: 'auto',
        flex: 1,
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    button: {
        marginTop: theme.spacing(2),
        backgroundColor: '#6BBDBD',
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Inter, sans-serif',
        boxShadow: 'none',
        fontWeight: '600',
        fontSize: '14px',
        borderRadius: '20px',
        padding: '8px 24px',
        '&:hover': {
            backgroundColor: '#5AA9A9',
        },
    },
    header: {
        flexShrink: 0,
    },
    footer: {
        flexShrink: 0,
    },
    loader: {
        color: '#6BBDBD',
        marginBottom: theme.spacing(2),
    }
}));

function JustificationPopup({ open, onClose, question, correctAnswer, justificationText, isLoading }) {
    const classes = useStyles();
    const [displayText, setDisplayText] = useState('');
    const lastChunkRef = useRef('');

    // Reset when modal closes
    useEffect(() => {
        if (!open) {
            setDisplayText('');
            lastChunkRef.current = '';
        }
    }, [open]);

    // Handle new text chunks
    useEffect(() => {
        if (justificationText && justificationText !== '[DONE]') {
            // Get only the new part of the text
            const newText = justificationText.replace(lastChunkRef.current, '');
            lastChunkRef.current = justificationText;

            // Convert <br> to newline if present
            const formattedText = newText.replace(/<br>/g, '\n');

            setDisplayText(prev => prev + formattedText);
        }
    }, [justificationText]);

    return (
        <Modal open={open} onClose={onClose} className={classes.modal}>
            <Box className={classes.paper}>
                <Typography
                    variant="h6"
                    className={classes.header}
                    style={{
                        fontSize: "20px",
                        fontFamily: "Fira Sans",
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: '24px'
                    }}
                >
                    Justificación
                </Typography>

                <Typography
                    className={classes.content}
                    style={{
                        whiteSpace: 'pre-line',
                        lineHeight: '1.6',
                        marginBottom: '24px'
                    }}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(displayText) || (isLoading ?
                            'Generando justificación de tu respuesta...'
                            : 'No hay justificación disponible.'
                        )
                    }}
                />

                <Box className={classes.footer}>
                    <Button
                        onClick={onClose}
                        variant="contained"
                        className={classes.button}
                        style={{ float: 'right' }}
                    >
                        Cerrar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default JustificationPopup; 