import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const PremiumPopup = ({ open, onClose }) => {
  const navigate = useNavigate();

  const premiumFeatures = [
    'Acciones ilimitadas',
    'Documentos ilimitados',
    'Tests de más de un documento',
    'Generación de preguntas extra',
    '¡Y mucho más!',
  ];

  const handlePlanClick = () => {
    onClose();
    navigate('/plans');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '24px',
          background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
          maxWidth: '500px',
          margin: '16px',
        }
      }}
    >
      <Box sx={{ position: 'absolute', right: 8, top: 8 }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogTitle sx={{
        textAlign: 'center',
        color: '#6BBDBD',
        fontFamily: '"Fira Sans", sans-serif',
        fontWeight: 'bold',
        pt: 5,
        pb: 1,
        fontSize: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1
      }}>
        Funcionalidad Premium
      </DialogTitle>

      <DialogContent>
        <Typography
          variant="h6"
          sx={{
            color: '#2C3E50',
            textAlign: 'center',
            fontFamily: '"Fira Sans", sans-serif',
            fontSize: '18px',
            width: '80%',
            px: 2,
            margin: '0px auto 24px',
            '& span': {
              textDecoration: 'underline'
            },
            cursor: 'pointer'
          }}
          onClick={() => {
            onClose();
            navigate('/plans');
          }}
        >
          Para generar flashcards debes disponer de una <span>cuenta Premium</span>
        </Typography>

        <Typography
          variant="h6"
          sx={{
            color: '#2C3E50',
            textAlign: 'left',
            mb: 2,
            fontFamily: '"Fira Sans", sans-serif',
            fontSize: '16px',
            px: 2
          }}
        >
          Más funcionalidades premium:
        </Typography>

        <List sx={{
          mb: 4,
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          gap: 2,
          px: 2
        }}>
          {premiumFeatures.map((feature, index) => (
            <ListItem key={index} sx={{
              py: 1,
              px: 2,
              backgroundColor: 'rgba(107, 189, 189, 0.08)',
              borderRadius: '12px',
            }}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <CheckIcon sx={{ color: '#6BBDBD' }} />
              </ListItemIcon>
              <ListItemText
                primary={feature}
                sx={{
                  '& .MuiTypography-root': {
                    color: '#2C3E50',
                    fontFamily: '"Fira Sans", sans-serif',
                    fontWeight: 500
                  }
                }}
              />
            </ListItem>
          ))}
        </List>

        <Box sx={{ display: 'flex', justifyContent: 'center', pb: 4 }}>
          <Button
            variant="contained"
            size="large"
            onClick={handlePlanClick}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              '&:hover': {
                backgroundColor: '#5CACAC',
              },
              fontFamily: '"Fira Sans", sans-serif',
              px: 6,
              py: 1.5,
              borderRadius: '25px',
              fontSize: '16px',
              boxShadow: '0 4px 12px rgba(107, 189, 189, 0.2)',
              transition: 'transform 0.2s',
              '&:hover': {
                backgroundColor: '#5CACAC',
                transform: 'translateY(-2px)',
              }
            }}
          >
            Hazte Premium desde €7,2/mes
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PremiumPopup; 