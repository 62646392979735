import i18n from './i18n'; // Assuming i18n.js is in your src directory

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Layout from "./Main/Layout";

import { default as React, useEffect, useRef } from "react";
import Home from "./Home/Home";

import MainStructure from "./Main/MainStructure";
import MySchemas from "./MentalMap/MySchemas";
import Excalidraw2 from "./MentalMap/Excalidraw";

import NewOnboarding from "./NewOnboarding/NewOnboarding";

import { SubscriptionProvider } from "./Context/SubscriptionContext";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatically navigating

import AdminUsers from "./Configuration/Admin";
import Config from "./Configuration/Config";
import ExamListView from "./Exam/ExamListView";
import ExamView from "./Exam/ExamView";
import LogIn from "./Login/Login";
import LoginSuccess from "./Login/LoginSuccess";
import Welcome from "./Welcome/WelcomePage";
import NewExam from "./AllModalsActions/NewExamPage";
import { green } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { GenerateQuestionsProvider } from "./Context/GenerateQuestionsContext";
import "./App.css";
import ActiveSubscriptionProvider from "./Context/ActiveSubscriptionContext";
import EmailVerifyCard from "./Error/EmailVerifyCard";
import SubscriptionInactiveCard from "./Error/SubscriptionInactiveCard";
import ProtectedRoute from "./ProtectedRoute";
import CheckoutFormStripe from './Stripe/CheckoutFormStripe';
import PlanSelection from './Stripe/PlanSelection';
import ReturnStripe from './Stripe/ReturnStripe';
import { WebSocketProvider } from './WebSocketProvider';
import { NavigationProvider } from './NavigationContext';
import DocumentStructure from './Main/DocumentStructure';
import AIChatView from './AIChat/AIChatView';
import MemoriseView from './Memorise/MemoriseView';
import WelcomeOposicion from './Welcome/WelcomeOposicion';
import { CreditsProvider } from './Context/CreditsContext';
import LayoutProgress from './Progress/LayoutProgress';
import PricingView from './Stripe/PricingView';
import { useLocation } from 'react-router-dom';
import { PointsProvider } from './Context/PointsContext';
import MermaidChart from './MentalMap/MermaidChart';
import PomodoroPage from './Pomodoro/PomodoroPage';
import { createGlobalStyle } from 'styled-components';
import Flashcards from './Flashcards/Flashcards';
import QuestionFlashcard from './Flashcards/QuestionFlashcards';
import FlashcardPage from './Flashcards/FlashcardPage';
import NewFlashcards from './AllModalsActions/NewFlashcardsPage';

import Dashboard from './Dashboard/Dashboard';

import posthog from 'posthog-js';
import PricingPage from './Stripe/PricingComponents/PricingPage';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Fira Sans', sans-serif;
  }
`;
const POSTHOG = process.env.REACT_APP_POSTHOG;
// TODO move posthog inside app
posthog.init(POSTHOG, { api_host: 'https://eu.i.posthog.com' })
// Create a new component for scroll restoration
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


function App() {


  const theme = createTheme({
    palette: {
      primary: {
        //main: "#026277",
        main: "#026277",
      },
      secondary: {
        main: green[500],
      },
    },
  });

  const backendHost = BACKEND_URL.replace(/^https?:\/\//, '');
  // const backendHost = "127.0.0.1:8000"

  // const websocketUrl = "ws://127.0.0.1:8000/ws/socket-server/";
  // const websocketUrlChat = "ws://127.0.0.1:8000/ws/chat/";
  const websocketUrlChat = `wss://${backendHost}/ws/chat/`;
  const websocketUrl = `wss://${backendHost}/ws/socket-server/`;

  // useEffect(() => {
  //   // Set a timer to redirect after 3600000 milliseconds (1 hour)
  //   const timer = setTimeout(() => {
  //     window.location.href = 'https://typedai.com'; // Redirect to typedai.com
  //   }, 60000);

  //   // Cleanup function to clear the timer if the component unmounts earlier
  //   return () => clearTimeout(timer);
  // }, []); // Empty dependency array means this effect runs once on mount
  useEffect(() => {
    // Function to remove the item from local storage
    const cleanupLocalStorage = () => {
      localStorage.removeItem('chat_responses');
    };

    // Add the event listener for the beforeunload event
    window.addEventListener('beforeunload', cleanupLocalStorage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', cleanupLocalStorage);
    };
  }, []);
  const idleTimerRef = useRef(null);

  // Function to handle the user activity
  const onActivity = () => {
    // Clear the existing timer when activity is detected
    clearTimeout(idleTimerRef.current);
    // Set/Reset the timer
    idleTimerRef.current = setTimeout(() => {
      window.location.href = 'https://typedai.com'; // Redirect after inactivity
    }, 5400000); // 1 hour of inactivity
  };

  useEffect(() => {
    // Set the initial timer
    idleTimerRef.current = setTimeout(() => {
      window.location.href = 'https://typedai.com';
    }, 5400000);

    // Event listeners for various user activities
    window.addEventListener('mousemove', onActivity);
    window.addEventListener('keydown', onActivity);
    window.addEventListener('scroll', onActivity);
    window.addEventListener('click', onActivity);

    // Cleanup function to remove listeners and clear timer
    return () => {
      clearTimeout(idleTimerRef.current);
      window.removeEventListener('mousemove', onActivity);
      window.removeEventListener('keydown', onActivity);
      window.removeEventListener('scroll', onActivity);
      window.removeEventListener('click', onActivity);
    };
  }, []); // Empty dependency array ensures this runs once on mount



  // Create a new component for scroll restoration


  // Crisp will be displayed

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ActiveSubscriptionProvider>
        <>
          <Router>
            <NavigationProvider> {/* Wrap routes in NavigationProvider */}

              <Routes>
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <SubscriptionProvider>
                        <Dashboard />
                      </SubscriptionProvider>
                    </ProtectedRoute>
                  }
                />

                <Route
                  element={
                    <ProtectedRoute>
                      <SubscriptionProvider>
                        <CreditsProvider>
                          <WebSocketProvider url={websocketUrl} urlchat={websocketUrlChat}>
                            <Layout />
                          </WebSocketProvider>
                        </CreditsProvider>
                      </SubscriptionProvider>
                    </ProtectedRoute>
                  }
                >
                  <Route
                    exact
                    path="/:folder_id/:document_id"
                    element={
                      <ProtectedRoute>
                        <PointsProvider>
                          <GenerateQuestionsProvider>
                            <DocumentStructure active_folder={false} />
                          </GenerateQuestionsProvider>
                        </PointsProvider>
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/:folder_id/:document_id/:chapter_id"
                    element={
                      <ProtectedRoute>
                        <MainStructure active_folder={false} />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    exact
                    path="/:folder_id/exam/:exam_id"
                    element={
                      <ProtectedRoute>
                        <ExamView key={window.location.pathname} />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/schemas" element={<MySchemas />} />
                  <Route path="/excalidraw" element={<Excalidraw2 />} />

                  <Route
                    exact
                    path="/exam/:exam_id"
                    element={
                      <ProtectedRoute>
                        <ExamView />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    exact
                    path="/flashcards"
                    element={
                      <ProtectedRoute>
                        <FlashcardPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/question-flashcards"
                    element={
                      <ProtectedRoute>
                        <QuestionFlashcard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="exams"
                    element={
                      <ProtectedRoute>
                        <ExamListView />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="aichat"
                    element={
                      <ProtectedRoute>
                        <AIChatView />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="memorise/*"
                    element={
                      <ProtectedRoute>

                        <MemoriseView />

                      </ProtectedRoute>
                    }
                  />

                  <Route
                    exact
                    path="/schema/:schema_id"
                    element={
                      <SubscriptionProvider>
                        <ProtectedRoute>
                          <MermaidChart />
                        </ProtectedRoute>
                      </SubscriptionProvider>
                    }
                  />

                  <Route
                    exact
                    path="/admin"
                    element={
                      <ProtectedRoute>
                        <AdminUsers active_folder={false} />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/plans" element={
                    <ProtectedRoute>
                      <PricingPage />
                    </ProtectedRoute>
                  } />
                  (
                  <Route
                    exact
                    path="/myaccount"
                    element={
                      <ProtectedRoute>
                        <Config />
                      </ProtectedRoute>
                    }

                  />
                  )
                  <Route
                    exact
                    path="/"
                    element={
                      <ProtectedRoute>
                        <PointsProvider>
                          <LayoutProgress />
                        </PointsProvider>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/folders"
                    element={
                      <ProtectedRoute>
                        <PointsProvider>
                          <Home />
                        </PointsProvider>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/new-exam"
                    element={
                      <ProtectedRoute>
                        <NewExam />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="/new-flashcards"
                    element={
                      <ProtectedRoute>
                        <NewFlashcards />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    exact
                    path="/pomodoro"
                    element={
                      <ProtectedRoute>
                        <PomodoroPage />
                      </ProtectedRoute>
                    }
                  /> */}


                  (
                  <Route
                    exact
                    path="/:folder_id"
                    element={
                      <ProtectedRoute>
                        <GenerateQuestionsProvider>
                          <MainStructure active_folder={true} />
                        </GenerateQuestionsProvider>
                      </ProtectedRoute>
                    }
                  />
                  )
                </Route>
                (
                <Route
                  exact
                  path="/welcome"
                  element={
                    <SubscriptionProvider>
                      <ProtectedRoute>
                        <NewOnboarding />
                      </ProtectedRoute>
                    </SubscriptionProvider>
                  }
                />




                <Route path="/checkout" element={<SubscriptionProvider>
                  <ProtectedRoute>
                    <CheckoutFormStripe />
                  </ProtectedRoute>
                </SubscriptionProvider>} />
                <Route path="/success/" element={<ReturnStripe />} />

                ){/* <Route exact path="/test" element={<Typeform />} /> */}
                {/* <Route exact path="/intro" element={<VideoIntro />} /> */}
                <Route
                  exact
                  path="/innactive"
                  element={<SubscriptionInactiveCard />}
                />
                <Route exact path="/verify" element={<EmailVerifyCard />} />
                <Route exact path="/login" element={<LogIn />} />
                <Route exact path="/loginSuccess" element={<LoginSuccess />} />
                {/* <Route
              exact
              path="/loginsuccessGoogle"
              element={<RecallSuccessGmail />}
            />
            <Route
              exact
              path="/loginSuccessMicrosoft"
              element={<RecallSuccessOutlook />}
            /> */}



              </Routes>
            </NavigationProvider>
          </Router>
          {/* <Route
              exact
              path="/loginSuccessMicrosoft"
              element={<RecallSuccessOutlook />}
            />
      </Routes> */}
        </>
      </ActiveSubscriptionProvider >
    </ThemeProvider >
  );
}

export default App;
