import React from 'react';
import { Card, CardContent, Typography, IconButton, Divider, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const FlashcardContainer = styled(Card)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  minHeight: '200px',
  maxHeight: '80vh',
  borderRadius: '20px',
  border: '2px solid #6BBDBD',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  zIndex: 1000,
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
}));

const FlashcardContent = styled(CardContent)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px',
  position: 'relative',
});

const StyledDivider = styled(Divider)({
  width: '100%',
  margin: '20px 0',
  color: '#E2F1F1',
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '10px',
  right: '10px',
});

const TextContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0',
});

const AdaptiveTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Inter', sans-serif",
  fontWeight: '600',
  textAlign: 'center',
  width: '100%',
  overflowWrap: 'break-word',
  hyphens: 'auto',
  padding: '0 20px',
}));

const PopupFlashcard = ({ onClose, front, back }) => {
  return (
    <FlashcardContainer>
      <FlashcardContent>
        <CloseButton onClick={(e) => { e.stopPropagation(); onClose(); }}>
          <CloseIcon />
        </CloseButton>
        <TextContainer>
          <AdaptiveTypography variant="body1">
            {front}
          </AdaptiveTypography>
        </TextContainer>
        <StyledDivider />
        <TextContainer>
          <AdaptiveTypography variant="body1">
            {back}
          </AdaptiveTypography>
        </TextContainer>
      </FlashcardContent>
    </FlashcardContainer>
  );
};

export default PopupFlashcard;