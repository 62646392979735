import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AddIcon from '@mui/icons-material/Add';
import TimerIcon from '@mui/icons-material/Timer';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsModal from './SettingsModal';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


// Custom styled components
const TimerDisplay = styled(Typography)(({ theme }) => ({
  fontSize: '170px',
  fontFamily: 'Fira Sans',
  fontWeight: 'bold',
  backgroundColor: 'transparent',
  lineHeight: 1,
  margin: '24px 0',
  color: theme.palette.text.primary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '20px',
}));

function PomodoroPage() {
  const [timeLeft, setTimeLeft] = useState(1500);
  const [isRunning, setIsRunning] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState(
    { 
      id: 1, 
      name: 'Examen La Constitución', 
      completedPomodoros: 0, 
      totalPomodoros: 4, 
      duration: `${0 * 25} de ${4 * 25} minutos`,
      document: 'Nom del document'
    }
  );
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [settings, setSettings] = useState({
    pomodoroDuration: 25,
    shortBreakDuration: 5,
    longBreakDuration: 15,
  });
  const [timerType, setTimerType] = useState('pomodoro');
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [newTask, setNewTask] = useState({
    name: '',
    pomodoros: 1,
    document: ''
  });
  const [selectDocumentOpen, setSelectDocumentOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [pomodoroSequence, setPomodoroSequence] = useState(0);

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      if (timerType === 'pomodoro') {
        handlePomodoroComplete();
      } else {
        setTimerType('pomodoro');
        setTimeLeft(settings.pomodoroDuration * 60);
      }
      setIsRunning(false);
    }
    return () => clearInterval(timer);
  }, [isRunning, timeLeft, timerType, settings]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const toggleTimer = () => {
    setIsRunning(!isRunning);
  };

  const handlePomodoroCount = (increment) => {
    setNewTask(prev => ({
      ...prev,
      pomodoros: increment ? prev.pomodoros + 1 : Math.max(1, prev.pomodoros - 1)
    }));
  };

  const handleSaveTask = () => {
    if (newTask.name && selectedDocument) {
      const task = {
        id: Date.now(),
        name: newTask.name,
        completed: '0/'+newTask.pomodoros,
        duration: `0 de ${newTask.pomodoros * 25} minutos`,
        document: newTask.document,
        documentId: newTask.documentId
      };
      
      setTasks([...tasks, task]);
      setNewTask({ name: '', pomodoros: 1, document: '', documentId: null });
      setSelectedDocument(null);
      setShowTaskForm(false);
    }
  };

  const handleDeleteTask = (taskId) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const handleDocumentSelect = (document) => {
    setSelectedDocument(document);
    setNewTask(prev => ({
      ...prev,
      document: document.name,
      documentId: document.id
    }));
    setSelectDocumentOpen(false);
  };

  const handlePomodoroComplete = () => {
    if (currentTask && currentTask.completedPomodoros < currentTask.totalPomodoros) {
      const newCompletedPomodoros = currentTask.completedPomodoros + 1;
      
      if (newCompletedPomodoros >= currentTask.totalPomodoros) {
        // Clear current task when all pomodoros are completed
        setCurrentTask(null);
        // Update tasks list to mark this task as completed
        setTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === currentTask.id 
              ? { ...task, isCompleted: true } 
              : task
          )
        );
      } else {
        // Update current task progress
        setCurrentTask(prev => ({
          ...prev,
          completedPomodoros: newCompletedPomodoros,
          duration: `${newCompletedPomodoros * 25} de ${prev.totalPomodoros * 25} minutos`
        }));
      }
      
      // Handle break timing
      const newSequence = (pomodoroSequence + 1) % 4;
      setPomodoroSequence(newSequence);
      
      if (newSequence === 0) {
        setTimerType('longBreak');
        setTimeLeft(settings.longBreakDuration * 60);
      } else {
        setTimerType('shortBreak');
        setTimeLeft(settings.shortBreakDuration * 60);
      }
    }
  };

  const handleSkipTimer = () => {
    setIsRunning(false);
    if (timerType === 'pomodoro') {
      handlePomodoroComplete();
    } else {
      setTimerType('pomodoro');
      setTimeLeft(settings.pomodoroDuration * 60);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              gap: 1,
              fontSize: '2.5rem', 
              fontFamily: 'Fira Sans',
              fontWeight: 700,
              mb: 1
            }}
          >Pomodoro
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              fontFamily: 'Fira Sans',
              color: 'text.secondary' 
            }}
          >
            Organiza las horas de estudio para aprovechar al máximo tu día
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={7}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: '100%' }}>
              {/* Current Task Card */}
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3,
                  borderRadius: '20px',
                  border: `1px solid ${currentTask ? '#D9D9D9' : '#6BBDBD'}`,
                  backgroundColor: currentTask ? 'white' : 'rgba(107, 189, 189, 0.2)'
                }}
              >
                {currentTask ? (
                  <>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      mb: 1 
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ 
                          width: 16, 
                          height: 16, 
                          bgcolor: '#6BBDBD', 
                          borderRadius: 1 
                        }} />
                        <Typography sx={{ fontFamily: 'Fira Sans', fontWeight: "bold" }}>
                          {currentTask.name}
                        </Typography>
                      </Box>
                      <Typography 
                        color="#00B564" 
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontFamily: 'Fira Sans' }}
                      >
                        {`${currentTask.completedPomodoros}/${currentTask.totalPomodoros}`} <TaskAltIcon sx={{ fontSize: 16 }} />
                      </Typography>
                    </Box>
                    
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1,
                      color: 'text.secondary'
                    }}>
                      <DescriptionOutlinedIcon sx={{ fontSize: 20 }} />
                      <Typography sx={{ fontFamily: 'Fira Sans' }}>
                        {currentTask.document}
                      </Typography>
                      <Typography sx={{ ml: 'auto', fontFamily: 'Fira Sans' }}>
                        {currentTask.duration}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 1
                  }}>
                    <Typography 
                      sx={{ 
                        color: '#6BBDBD',
                        fontWeight: 600,
                        fontFamily: 'Fira Sans',
                        textAlign: 'center',
                        fontSize: '0.875rem'
                      }}
                    >
                      Selecciona una tarea para empezar a estudiar
                    </Typography>
                  </Box>
                )}
              </Paper>

              {/* Timer Card */}
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 4, 
                  flex: 1, 
                  textAlign: 'center',
                  bgcolor: 'transparent',
                  borderRadius: '20px' 
                }}
              >
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 1,
                  mb: 3
                }}>
                  <Box
                    sx={{
                      padding: '6px 16px',
                      borderRadius: '20px',
                      backgroundColor: timerType === 'pomodoro' ? '#6BBDBD' : 'white',
                      border: timerType === 'pomodoro' ? 'none' : '1px solid #6BBDBD',
                      color: timerType === 'pomodoro' ? 'white' : '#6BBDBD',
                      fontSize: '14px',
                      fontFamily: 'Fira Sans'
                    }}
                  >
                    Pomodoro
                  </Box>
                  <Box
                    sx={{
                      padding: '6px 16px',
                      borderRadius: '20px',
                      backgroundColor: timerType === 'shortBreak' ? '#6BBDBD' : 'white',
                      border: timerType === 'shortBreak' ? 'none' : '1px solid #6BBDBD',
                      color: timerType === 'shortBreak' ? 'white' : '#6BBDBD',
                      fontSize: '14px',
                      fontFamily: 'Fira Sans'
                    }}
                  >
                    Descanso Corto
                  </Box>
                  <Box
                    sx={{
                      padding: '6px 16px',
                      borderRadius: '20px',
                      backgroundColor: timerType === 'longBreak' ? '#6BBDBD' : 'white',
                      border: timerType === 'longBreak' ? 'none' : '1px solid #6BBDBD',
                      color: timerType === 'longBreak' ? 'white' : '#6BBDBD',
                      fontSize: '14px',
                      fontFamily: 'Fira Sans'
                    }}
                  >
                    Descanso Largo
                  </Box>
                </Box>

                <TimerDisplay>{formatTime(timeLeft)}</TimerDisplay>
                
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={toggleTimer}
                    disabled={!currentTask}
                    startIcon={isRunning ? <PauseIcon /> : <PlayArrowIcon />}
                    sx={{ 
                      bgcolor: 'primary.light',
                      textTransform: 'none',
                      fontFamily: 'Fira Sans',
                      '&:hover': { bgcolor: 'primary.main' },
                      '&.Mui-disabled': {
                        bgcolor: '#cccccc',
                        color: '#666666'
                      }
                    }}
                  >
                    {isRunning ? 'Detener' : 'Empezar'}
                  </StyledButton>
                  <IconButton 
                    onClick={handleSkipTimer}
                    disabled={!currentTask}
                    sx={{ 
                      color: 'primary.light',
                      '&.Mui-disabled': {
                        color: '#cccccc'
                      }
                    }}
                  >
                    <SkipNextIcon />
                  </IconButton>
                </Box>

                <Button 
                  variant="text" 
                  color="error" 
                  disabled={!currentTask}
                  onClick={() => {
                    setCurrentTask(null);
                    setIsRunning(false);
                    setTimeLeft(settings.pomodoroDuration * 60);
                    setTimerType('pomodoro');
                  }}
                  sx={{ 
                    mt: 2, 
                    fontFamily: 'Fira Sans', 
                    textTransform: 'none', 
                    fontWeight: 400,
                    '&.Mui-disabled': {
                      color: '#cccccc'
                    }
                  }}
                >
                  Terminar tarea
                </Button>
              </Paper>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={5}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 3, 
                height: '600px',
                borderRadius: '20px',
                border: '1px solid #D9D9D9'
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 2 
              }}>
                <Typography sx={{ fontFamily: 'Fira Sans', fontWeight: 600 }}>
                  ¿Qué vas a estudiar hoy?
                </Typography>
                <IconButton size="small" onClick={() => setSettingsOpen(true)}>
                  <SettingsIcon sx={{ color: '#D9D9D9' }} />
                </IconButton>
              </Box>
              <Divider sx={{ mb: 2 }} />
              
              <Box sx={{ 
                height: 'calc(100% - 120px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555',
                },
              }}>
                {tasks && tasks.length > 0 ? (
                  <List>
                    {tasks.map((task) => (
                      <ListItem 
                        key={task.id}
                        sx={{ 
                          px: 0, 
                          py: 2,
                          borderBottom: '1px solid #D9D9D9'
                        }}
                      >
                        <Box sx={{ width: '100%' }}>
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            mb: 1 
                          }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box sx={{ 
                                width: 16, 
                                height: 16, 
                                bgcolor: '#6BBDBD', 
                                borderRadius: 1 
                              }} />
                              <Typography sx={{ fontFamily: 'Fira Sans', fontWeight: "bold" }}>
                                {task.name}
                              </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Typography 
                                color="#00B564" 
                                sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontFamily: 'Fira Sans' }}
                              >
                                {task.completed} <TaskAltIcon sx={{ fontSize: 16 }} />
                              </Typography>
                              <IconButton 
                                size="small" 
                                onClick={() => handleDeleteTask(task.id)}
                                sx={{ color: 'text.secondary' }}
                              >
                                <DeleteOutlineIcon sx={{ fontSize: 20 }} />
                              </IconButton>
                            </Box>
                          </Box>
                          
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            gap: 1,
                            color: 'text.secondary'
                          }}>
                            <DescriptionOutlinedIcon sx={{ fontSize: 20 }} />
                            <Typography sx={{ fontFamily: 'Fira Sans' }}>
                              {task.document}
                            </Typography>
                            <Typography sx={{ ml: 'auto', fontFamily: 'Fira Sans' }}>
                              {task.duration}
                            </Typography>
                          </Box>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                ) : !showTaskForm ? (
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 4
                  }}>
                    <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="220" height="220" />
                    <Typography 
                      sx={{ 
                        color: 'text.secondary',
                        fontFamily: 'Fira Sans',
                        textAlign: 'center',
                        marginTop: "20px"
                      }}
                    >
                      Añade a continuación tus tareas
                    </Typography>
                  </Box>
                ) : null}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                  {showTaskForm ? (
                    <Paper
                      elevation={0}
                      sx={{
                        p: 0,
                        borderRadius: '10px',
                        width: '100%'
                      }}
                    >                  
                      <TextField
                        fullWidth
                        placeholder="Nombre de la tarea"
                        value={newTask.name}
                        onChange={(e) => setNewTask({...newTask, name: e.target.value})}
                        variant="standard"
                        sx={{ marginBottom: "5px", marginTop: "20px" }}
                      />

                      <Box sx={{ mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Button
                            onClick={() => setSelectDocumentOpen(true)}
                            sx={{
                              color: selectedDocument ? 'text.primary' : '#666',
                              textTransform: 'none',
                              fontFamily: 'Fira Sans'
                            }}
                          >
                            {selectedDocument ? selectedDocument.name : '+ Elige el documento'}
                          </Button>
                          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box
                              sx={{
                                bgcolor: '#f5f5f5',
                                px: 2,
                                py: 1,
                                borderRadius: '4px',
                                width: '60px',
                                textAlign: 'center'
                              }}
                            >
                              {newTask.pomodoros}
                            </Box>
                            <Typography sx={{ fontFamily: 'Fira Sans', color: '#666' }}>
                              pomodoros
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <IconButton 
                                size="small" 
                                onClick={() => handlePomodoroCount(true)}
                              >
                                <KeyboardArrowUpIcon />
                              </IconButton>
                              <IconButton 
                                size="small" 
                                onClick={() => handlePomodoroCount(false)}
                              >
                                <KeyboardArrowDownIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                        <Button
                          onClick={() => setShowTaskForm(false)}
                          sx={{
                            color: '#666',
                            borderRadius: '20px',
                            textTransform: 'none',
                            fontFamily: 'Fira Sans'
                          }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleSaveTask}
                          disabled={!newTask.name || !selectedDocument}
                          sx={{
                            bgcolor: '#6BBDBD',
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: '20px',
                            boxShadow: 'none',
                            fontFamily: 'Fira Sans',
                            '&:hover': {
                              bgcolor: '#6BBDBD'
                            },
                            '&.Mui-disabled': {
                              bgcolor: '#cccccc',
                              color: '#666666'
                            }
                          }}
                        >
                          Añadir
                        </Button>
                      </Box>
                    </Paper>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary" 
                      startIcon={<AddIcon />}
                      onClick={() => setShowTaskForm(true)}
                      sx={{ 
                        marginTop: "10px",
                        width: '50%',
                        textTransform: 'none',
                        fontFamily: 'Fira Sans',
                        boxShadow: 'none',
                        borderRadius: '20px',
                        color: "white",
                        backgroundColor: "#6BBDBD",
                        '&:hover': { backgroundColor: "#6BBDBD" }
                      }}
                    >
                      Añadir Tarea
                    </Button>
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <SettingsModal
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        settings={settings}
        setSettings={setSettings}
      />
    </Container>
  );
}

export default PomodoroPage; 