import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import TableFolders from "../Folders/TableFolders";
import { useTranslation } from 'react-i18next';
import UploadButton from '../NewComponents/SubirApuntesButton';
import cardsStyles from '../cardsStyles';
import CardComponent from '../NewComponents/CardComponent';
import GettingStarted from '../Onboard/GettingStarted';
import RectanglesHome from './RectanglesHome';

const useStyles = makeStyles((theme) => ({
    paperLeft: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        flexGrow: 1,
        height: "100%",  // Ensure it takes the full height of its parent
        overflowY: "auto",  // Enable vertical scrolling
        backgroundColor: "#F8FFFF"
    },
    containerParent: {
        display: "flex",
        flexDirection: "column",
        // Removed: border: "1px solid #6BBDBD",
        // Removed: borderRadius: "20px",
        // Removed: backgroundColor: "white",
        gap: "10px"
    },
    container: {
        display: "flex",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: "20px",
        alignItems: "start",
        justifyContent: "center"


    },
    container2: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: "20px",
        alignItems: "start",

        backgroundColor: "white",
        padding: "20px"
    },
    fullWidth: {
        gridColumn: "span 3",
    },
    card: {
        borderRadius: 20,
        backgroundColor: '#E2E8F0',
        boxShadow: 'none',

    },
    media: {
        height: 100,
        backgroundColor: '#CBD5E0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px 8px 0 0',
    },
    icon: {
        width: '60%',
        height: '60%',
    },
    content: {
        backgroundColor: '#F1F5F9',
        padding: '8px 14px !important',
    },
    title: {
        fontWeight: 600,
        fontSize: '14px',
    },
    description: {
        color: '#4A5568',
        fontSize: '10px',
    },
    boxImg: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "100px",
        '@media (max-height: 800px)': {
            marginTop: "7px",
        },
    },
    bottomContainer: {
        display: 'flex', flexDirection: 'row', justifyContent: "space-between",
        marginTop: "20px"
    },
    bordersContainer: {
        border: "1px solid #E2F1F1",
        borderRadius: "20px",
        padding: "20px 50px 20px 50px",
        marginTop: "20px",
        background: "white"
    }
}));

const FolderList = ({
    isLoading,
    initialFolders,
    handleCreateFolderClick,
    fetchDataAndUpdate,
    accessToken,
    user,
    transcripts,
    handleUploadFile,
    handleUploadExam,
    handleClickOpenNotReady,
    userData,
    allPointsData
}) => {
    const classes = useStyles();
    const classesCards = cardsStyles();
    const { t } = useTranslation();
    const [folders, setFolders] = useState(initialFolders);

    useEffect(() => {
        setFolders(initialFolders);
    }, [initialFolders]);



    const handleFolderUpdate = async () => {
        await fetchDataAndUpdate();

        setFolders(initialFolders);

    };
    return (
        <>


            <Grid item style={{ width: "100%" }}>
                <Paper elevation={3} className={classes.paperLeft}>
                    <div className={classes.containerParent}>
                        <div>
                            {/* <Typography
                            variant="h6"
                            style={{
                                color: "#026277",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                letterSpacing: "-0.225px",
                                paddingLeft: 2
                            }}
                        >
                            {t('your_folders', { Nombre: user.given_name })}👋
                        </Typography> */}
                        </div>
                       {/* {userData && (
                            <>
                                {(!userData.has_uploaded_file || !userData.has_created_summary || !userData.has_created_exam) && (
                                    <GettingStarted
                                        has_uploaded_file={userData.has_uploaded_file}
                                        has_created_summary={userData.has_created_summary}
                                        has_created_exam={userData.has_created_exam}
                                    />
                                )}
                                <RectanglesHome days={userData.streak_days} allPointsData={allPointsData} />
                            </>
                        )}*/}


                    </div>
     
                    <div className={classes.bordersContainer}>
                        <Box style={{display: "flex", flexDirection: "column", gap: "10px"}}>                                       <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        paddingTop="30px">

                        <Typography
                            style={{
                                color: 'black',
                                fontSize: '24px',
                                fontFamily: "Fira Sans",
                                fontWeight: "bold",
                                letterSpacing: '-0.225px',
                            }}
                        >
                            Tus carpetas
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            {userData?.plan_name !== "External" && (
                                <>
                                    <UploadButton onClick={handleUploadFile} />
                                    <Button 
                                        onClick={handleCreateFolderClick}
                                        style={{
                                            textTransform: "none", 
                                            backgroundColor: "#6BBDBD", 
                                            color: "white", 
                                            fontFamily: "'Fira Sans', sans-serif",
                                            borderRadius: "20px", 
                                            padding: "6px 22px", 
                                            fontWeight: "bold", 
                                            fontSize: "14px"
                                        }}
                                    >
                                        Crear carpeta
                                    </Button>
                                </>
                            )}
                        </Box>
                        
                        

                    </Box>
                    <Box><Typography style={{fontSize: "14px", color: "#A0A0A0", fontStyle: "Fira Sans", fontWeight: "normal"}}>Organiza tus apuntes de la mejor manera</Typography></Box></Box>

                        <div className={classes.bottomContainer}>


                        </div>

                        {isLoading ? (
                            <div className={classes.container2}>
                                <div key={1} className={classes.folder}>
                                    <Skeleton variant="rect" width="100%" height={20} style={{
                                        borderRadius: "10px",
                                        height: "30px"
                                    }} />
                                </div>
                            </div>
                        ) : folders.length > 0 ? (
                            <div style={{ marginTop: '5px', overflowX: 'hidden', overflowY: "auto", paddingBottom: "3px" }}>

                                <TableFolders
                                    initialFolders={folders}
                                    initialTranscripts={transcripts}
                                    fetchDataAndUpdate={handleFolderUpdate}
                                    accessToken={accessToken}
                                    user={user}
                                    showList={true}
                                    handleUploadFile={handleUploadFile}
                                    handleCreateFolderClick={handleCreateFolderClick}
                                />
                            </div>
                        ) : (
                            <Box className={classes.boxImg}>
                                <div style={{ textAlign: 'center' }}>
                                    <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/noContent.svg" alt="No Documents" className={classes.imgEmpty} />
                                </div>
                                <Typography style={{ fontSize: "14px" }}>{t("create_first_folder_desc")}</Typography>
                                <Button
                                    onClick={handleCreateFolderClick}
                                    style={{
                                        alignSelf: 'center',
                                        textTransform: 'none',
                                        display: 'flex',
                                        fontFamily: "'Fira Sans', sans-serif",
                                        marginTop: '10px',
                                        borderRadius: "8px",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '4px',
                                        color: "#026277",
                                        backgroundColor: "#E2F1F1",
                                        fontSize: "14px",
                                        paddingRight: "12px"
                                    }}
                                    className="home-step"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" style={{ marginRight: '8px' }} // Add margin here
                                    >
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.5C10.5523 3.5 11 3.94772 11 4.5V9.5H16C16.5523 9.5 17 9.94772 17 10.5C17 11.0523 16.5523 11.5 16 11.5H11V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5V11.5H4C3.44772 11.5 3 11.0523 3 10.5C3 9.94771 3.44772 9.5 4 9.5L9 9.5V4.5C9 3.94772 9.44772 3.5 10 3.5Z" fill="#025365" />
                                    </svg>
                                    {t('nothing_desc')}
                                </Button>
                            </Box>
                        )}
                    </div>
                </Paper>
            </Grid>
        </>
    );
};

export default FolderList;
