import { useAuth0 } from "@auth0/auth0-react";
import Drawer from "@material-ui/core/Drawer";

import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography"
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@mui/material/Box";
import React, { useEffect, useState, useContext } from "react";
import { WebSocketContext } from '../WebSocketProvider';

import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import { fetchUserInfo } from '../Api/UserApi'; // Import the fetchUserInfo function
import { useAuth } from "../Auth/Authhandle";
import { useSubscription } from '../Context/SubscriptionContext'; // Adjust the import path accordingly
import SmallAlert from "../NewComponents/Modal/smallAlert";
import SpinnerUpload from "../NewComponents/Upload/SpinnerUpload";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import useLayoutStyles from "./Styles/useLayoutStyles";
import ModalParent from "../AllModalsActions/ParentModal";
// import sesionesEstudio from './Icons/sesionesEstudio.svg';
import ModalNotReady from "../NewComponents/ModalNotReady";
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import clsx from 'clsx';
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useCredits } from "../Context/CreditsContext";


import { Button } from "@mui/material";
import * as handle from "./handle"
import SchemaIcon from '@mui/icons-material/AccountTree'; // Import a suitable icon

import UGTLogo from './Icons/UGT_Logo.png'; // Add this import at the top with other imports
import UGTMinLogo from './Icons/UGT_Min.PNG'; // Add this import at the top with other imports

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import QuizIcon from '@mui/icons-material/Quiz';  // For "Hacer test"
import PsychologyIcon from '@mui/icons-material/Psychology';  // For "Memorizar"
import LockIcon from '@mui/icons-material/Lock'; // Import LockIcon from MUI
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip from MUI


const Sidebar = ({ children }) => {
  const { t } = useTranslation();
  const classes = useLayoutStyles();
  const { messageCallback } = useContext(WebSocketContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [titleOption, setTitle] = useState("");
  const [descriptionOption, setDescription] = useState("");
  const [buttonOption, setButton] = useState("");
  const [placeholderMessage, setPlaceholderMessage] = useState("");
  const { hasSubscription, setHasSubscription, userData, fetchData } = useSubscription();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [progressBar, setProgressBar] = useState(false);
  const navigate = useNavigate();
  const { credits } = useCredits();

  const [modalOpenUpload, setModalOpenUpload] = useState(false);
  const [titleOptionUpload, setTitleOptionUpload] = useState('');
  const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
  const [buttonOptionUpload, setButtonOptionUpload] = useState('');
  const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
  const [typeUpload, setTypeUpload] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCollapsed, setAnchorElCollapsed] = useState(null);

  //logout
  const { logout } = useAuth0();

  // call useEffect to render userInfo
  const location = useLocation(); // Get the current location

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("")
  const [imageProfile, setImageProfile] = useState("")
  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [widthModal, setWidthModal] = useState("350px")
  const [typeModal, setTypeModal] = useState("");
  const [openNotReady, setOpenNotReady] = useState(false);
  const [currentCredits, setCurrentCredits] = useState(credits || 200);
  useEffect(() => {

    setCurrentCredits(credits)
  }, [credits, accessToken, user])


  useEffect(() => {
    if (messageCallback) {

      switch (messageCallback.type) {
        case "Exam":
          setCurrentCredits(messageCallback.credits)
          break;

        // case "summary":
        //   setCurrentCredits(messageCallback.credits)
        //   break;
        case "Error":
          alert(messageCallback.messageAlert)

          setShowSpinner(false);
          break;
        case "documentsUpdate":
          setProgressBar(false)
          setShowAlert(false)
          fetchData()
          // setShowSpinner(false);
          break;
        default:
          console.log("Unhandled message type:", messageCallback.document);
      }

    }
  }, [messageCallback]);
  const handleClickOpenNotReady = () => {
    setOpenNotReady(true);
  };

  const handleCloseNotReady = () => {
    setOpenNotReady(false);
  };
  useEffect(() => {

    async function fetchData() {
      try {
        if (accessToken && user) {
          const userData = await fetchUserInfo(accessToken, "GET", null, user); // Call the fetchUserInfo function

          const surnameFirstPart = userData.surname.split(' ')[0];

          let fullname = `${userData.name} ${surnameFirstPart}`
          let userEmail = userData.email
          if (userEmail.length > 8) {
            // Taking only the beginning of the email and appending "...."
            userEmail = userEmail.substring(0, 13) + "...";
          }
          if (fullName.length > 7) {
            // Taking only the beginning of the email and appending "...."
            fullname = fullname.substring(0, 13) + "...";
          }
          setFullName(fullname)

          // Setting the states with fetched or modified user data
          setImageProfile(userData.image)
          setEmail(userEmail)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData(); // Call the fetchData function
  }, [imageProfile, accessToken, user]);
  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();

    logout({ logoutParams: { returnTo: "https://www.typedai.com" } })
  };
  useEffect(() => {
    // Load the Tally script
    const script = document.createElement('script');
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  const handleOpenTallyPopup = () => {
    window.Tally.openPopup('nrldZv', {
      layout: 'default',
      autoClose: 5000,
      hideTitle: false,
      emoji: {
        text: "👋",        // specify the emoji text
        animation: "wave"  // choose an animation from the options
      },
      onOpen: () => console.log("Popup opened"),
      onClose: () => console.log("Popup closed")
    });
  };

  const handleCloseTallyPopup = () => {
    window.Tally.closePopup('nrldZv');
  };
  const [collapsed, setCollapsed] = useState(false); // State to manage the Drawer collapse

  const toggleDrawer = () => {
    setCollapsed(!collapsed);
  };
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Detect small screen size
  useEffect(() => {
    if (isSmallScreen) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }

  }, [isSmallScreen]);
  const handleUploadFile = () => {
    setModalOpen(true);
    setTitle("Sube tus documentos");
    setDescription("Genera material de estudio con tus documentos");
    setButton(t("upload_button"));
    setPlaceholderMessage("");
    setWidthModal("600px")
    setTypeModal("upload");
  };

  const isFreePlan = userData?.plan_name === "Free"; // Check if the plan is Free
  const isBasicPlan = userData?.plan_name?.startsWith("Basic"); // Check if the plan is Basic

  // Determine the number of free documents based on the plan
  const freeDocumentsLimit = isFreePlan ? 4 : isBasicPlan ? 20 : 0;

  return (
    <>

      {modalOpen && (
        <ModalParent
          open={modalOpen}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          type={typeModal}
          onClose={() => setModalOpen(false)}
          setShowSpinner={setShowSpinner}
          setShowAlert={setShowAlert}
          setMessage={setMessage}
          setProgressBar={setProgressBar}
          setShowUpgrade={setShowUpgrade}
          width={widthModal}




        />
      )}

      {showAlert &&
        <SmallAlert
          message={message}
          progressBar={progressBar}
          onClose={() => setShowAlert(false)}
        />
      }
      {
        showUpgrade && <NeedCredits message={message}

          onClose={() => setShowUpgrade(false)} />
      }
      {openNotReady && <ModalNotReady handleClose={handleCloseNotReady} />}

      {showSpinner && <SpinnerUpload customTransform="translate(180%, -50%)" />}



      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !collapsed && !isSmallScreen,
          [classes.drawerClose]: collapsed || isSmallScreen,
        })}
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerOpen]: !collapsed && !isSmallScreen,
            [classes.drawerClose]: collapsed || isSmallScreen,
          }),
        }}
        anchor="left"

      >
        {/* <Button onClick={toggleDrawer}>
          {collapsed ? ">" : "<"}
        </Button> */}
        <div className={classes.top}>
          {/* Header section with Image and Name */}

          {!collapsed ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 7,
                  display: 'inline-flex'
                }}>
                  <div style={{ width: 29.79, height: 30, position: 'relative', alignItems: 'center' }}>
                    <div style={{
                      width: 29.79,
                      height: 29.17,
                      left: -0,
                      top: 2,
                      position: 'absolute',
                      textAlign: 'center',
                      color: '#6BBDBD',
                      fontSize: 22,
                      fontFamily: 'Inter',
                      fontWeight: '700',
                      wordWrap: 'break-word'
                    }} className="notranslate">T </div>
                    <div style={{
                      width: 29.79,
                      height: 30,
                      left: 0,
                      top: 0,
                      position: 'absolute',
                      background: 'rgba(255, 255, 255, 0)',
                      borderRadius: 8,
                      border: '1px #6BBDBD solid'
                    }} />
                  </div>
                  <div style={{
                    textAlign: 'center',
                    color: '#6BBDBD',
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: '700',
                    wordWrap: 'break-word'
                  }} className="notranslate">Typed</div>
                </div>
              </div>
              {userData?.plan_name === "External" && (
                <div style={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center', // Added for vertical alignment
                  borderBottom: '1px solid #eee',
                  height: '50px' // Added fixed height for consistency
                }}>
                  <img
                    src={UGTLogo}
                    alt="UGT Logo"
                    style={{
                      width: '60%', // Reduced from 80% to 70%
                      height: 'auto',
                      maxHeight: '35px', // Reduced from 40px to 35px
                      objectFit: 'contain'
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className={classes.header}>
                <div style={{
                  width: '100%',
                  height: '100%',
                  alignItems: 'center', // Changed from flex-start to center
                  gap: 7,
                  display: 'inline-flex'
                }}>
                  <div style={{ width: 29.79, height: 30, position: 'relative' }}>
                    <div style={{
                      width: 29.79,
                      height: 29.17,
                      left: -0,
                      top: 2,
                      position: 'absolute',
                      textAlign: 'center',
                      color: '#6BBDBD',
                      fontSize: 22,
                      fontFamily: 'Inter',
                      fontWeight: '700',
                      wordWrap: 'break-word'
                    }}>T </div>
                    <div style={{
                      width: 29.79,
                      height: 30,
                      left: 0,
                      top: 0,
                      position: 'absolute',
                      background: 'rgba(255, 255, 255, 0)',
                      borderRadius: 8,
                      border: '1px #6BBDBD solid'
                    }} />
                  </div>
                </div>
              </div>
              {userData?.plan_name === "External" && (
                <div style={{
                  padding: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottom: '1px solid #eee',
                  height: '40px'
                }}>
                  <img
                    src={UGTMinLogo}
                    alt="UGT Min Logo"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '25px',
                      objectFit: 'contain'
                    }}
                  />
                </div>
              )}
            </>
          )}

          <List >
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>

              <ListItem button key="Inicio"
                className={`${classes.listItem}  ${location.pathname === '/' && classes.selected}`}

              >
                <ListItemIcon className={classes.listItemIcon}>
                  {" "}
                  <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3 12L5 10M5 10L12 3L19 10M5 10V20C5 20.5523 5.44772 21 6 21H9M19 10L21 12M19 10V20C19 20.5523 18.5523 21 18 21H15M9 21C9.55228 21 10 20.5523 10 20V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V20C14 20.5523 14.4477 21 15 21M9 21H15" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                  </svg>
                </ListItemIcon>
                {!collapsed && (
                  <ListItemText
                    primary={t("home")}
                    classes={{ primary: classes.text }}
                  />)}
              </ListItem>
            </Link>

            <ListItem
              button
              key="Exams"
              onClick={() => {
                navigate("/exams")
              }}
              className={`${classes.listItem} ${location.pathname === '/exams' && classes.selected}`}
              style={{ marginTop: "5px" }}

            >
              <ListItemIcon className={classes.listItemIcon}>
                <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                  <path d="M5.25 10.0835V5.68351C5.25 4.65675 5.25 4.14299 5.44074 3.75081C5.60852 3.40585 5.87604 3.12559 6.20532 2.94982C6.57967 2.75 7.07008 2.75 8.05017 2.75H11.9654C12.0726 2.75 12.1664 2.75 12.25 2.7508M17.4992 8.25C17.5 8.33764 17.5 8.43605 17.5 8.54854V16.3199C17.5 17.3447 17.5 17.8571 17.3094 18.2489C17.1417 18.5939 16.8734 18.8746 16.5441 19.0504C16.1702 19.25 15.6809 19.25 14.7027 19.25L11.375 19.25M17.4992 8.25C17.4968 7.9883 17.4875 7.82261 17.4513 7.66455C17.4084 7.47748 17.3374 7.29863 17.2415 7.1346C17.1333 6.94958 16.9828 6.79148 16.6801 6.4744L13.9453 3.60938C13.6428 3.2925 13.4912 3.13369 13.3147 3.02035C13.1581 2.91983 12.9876 2.84557 12.809 2.80066C12.658 2.76269 12.4999 2.75318 12.25 2.7508M17.4992 8.25H17.5002M17.4992 8.25H15.0473C14.0691 8.25 13.5793 8.25 13.2053 8.05037C12.876 7.87461 12.6085 7.59359 12.4407 7.24863C12.25 6.85645 12.25 6.34343 12.25 5.31667V2.7508M7.875 12.8333L9.625 14.6667M3.5 19.25V16.9583L10.0625 10.0833L12.25 12.375L5.6875 19.25H3.5Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>


              </ListItemIcon>
              {!collapsed && (
                <ListItemText
                  primary={t("your_exams")}
                  classes={{ primary: classes.text }}
                />)}
            </ListItem>

            <ListItem
              button
              key="Carpetas"
              onClick={() => {
                navigate("/folders")
              }}
              className={`${classes.listItem} ${location.pathname === '/folders' && classes.selected}`}
              style={{ marginTop: "5px" }}

            >
              <ListItemIcon className={classes.listItemIcon}>
                <svg className={classes.svgIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 11V19.4C3 19.9601 3 20.2396 3.10899 20.4535C3.20487 20.6417 3.35774 20.7952 3.5459 20.8911C3.7596 21 4.03901 21 4.59797 21H15.4014C15.9603 21 16.2408 21 16.4545 20.8911C16.6427 20.7952 16.7948 20.6421 16.8906 20.4539C16.9996 20.24 16.9996 19.96 16.9996 19.3999L17.0011 15.0006M3 11H10M3 11V10.6001C3 10.0401 3 9.75979 3.10899 9.5459C3.20486 9.35774 3.35774 9.20486 3.5459 9.10898C3.75977 9.00001 4.03975 9.00001 4.59961 9.00001L7 9M17.0011 15.0006L16.9996 12.5999C16.9996 12.0398 16.9996 11.7598 16.8906 11.5459C16.7948 11.3577 16.6441 11.2049 16.456 11.109C16.242 11 15.96 11 15.4 11H10M17.0011 15.0006L19.4023 15C19.9613 15 20.2414 15 20.4551 14.8911C20.6432 14.7952 20.7948 14.6421 20.8906 14.4539C20.9996 14.24 20.9996 13.96 20.9996 13.3999V6.59989C20.9996 6.03984 20.9996 5.75981 20.8906 5.5459C20.7948 5.35774 20.6441 5.20487 20.4559 5.10899C20.242 5 19.9601 5 19.4 5H7M10 11L8.9248 9.61768C8.74861 9.39115 8.66017 9.27743 8.5498 9.1958C8.45201 9.12347 8.34303 9.06979 8.2259 9.037C8.09373 9.00001 7.9488 9.00001 7.66191 9.00001L7 9M7 9V5M7 5V4.6001C7 4.04014 7 3.75979 7.10899 3.5459C7.20486 3.35774 7.35774 3.20487 7.5459 3.10899C7.75981 3 8.03956 3 8.59961 3H11.6615C11.9487 3 12.0913 3 12.2236 3.03701C12.3407 3.0698 12.452 3.12347 12.5498 3.1958C12.6602 3.27743 12.7486 3.39115 12.9248 3.61768L13.9999 4.99998" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>


              </ListItemIcon>
              {!collapsed && (
                <ListItemText
                  primary={"Carpetas"}
                  classes={{ primary: classes.text }}
                />)}
            </ListItem>

            <ListItem
              button
              key="Ayuda"
              component="a"
              href="https://lavish-humerus-d6a.notion.site/Centro-de-Ayuda-1835b3630d00809999c8e480ae9c2524?pvs=73"
              target="_blank"
              rel="noopener noreferrer"
              className={`${classes.listItem} ${location.pathname === '/help' && classes.selected}`}
              style={{ marginTop: "5px" }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <svg className={classes.svgIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </ListItemIcon>
              {!collapsed && (
                <ListItemText
                  primary={"Ayuda"}
                  classes={{ primary: classes.text }}
                />)}
            </ListItem>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
                marginTop: '20px',

              }}
            >
              {((userData?.plan_name !== "External") || (userData?.plan_name === "External" && userData?.email === "ugttyped@gmail.com")) && (
                collapsed ? (
                  <ListItem
                    button
                    key="Añadir"
                    onClick={handleUploadFile}
                    className={`${classes.listItem} ${location.pathname === '/Añadir' && classes.selected}`}
                    style={{ marginTop: "5px" }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#6BBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </ListItemIcon>
                  </ListItem>
                ) : (
                  <Button
                    sx={{
                      backgroundColor: 'white',
                      border: "1px solid #6BBDBD",
                      color: '#6BBDBD',
                      textTransform: 'none',
                      fontWeight: '600',
                      fontFamily: "'Fira Sans', sans-serif",
                      borderRadius: '24px',
                      padding: '8px 24px',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#white',
                      },
                      width: '100%',
                      maxWidth: 'none',
                    }}
                    onClick={handleUploadFile}
                    fullWidth
                  >
                    Añadir
                  </Button>
                )
              )}

              {collapsed ? (
                <ListItem
                  button
                  key="Estudiar"
                  onClick={(event) => setAnchorElCollapsed(event.currentTarget)}
                  className={`${classes.listItem} ${location.pathname === '/new-exam' && classes.selected}`}
                  style={{
                    marginTop: "5px",
                    backgroundColor: '#6BBDBD',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: '#5aa9a9',
                    }
                  }}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{
                      color: 'white',
                      minWidth: '40px'
                    }}
                  >
                    <svg
                      className={classes.svgIcon}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: 'white' }}
                    >
                      <path
                        d="M12 6.042C10.3516 4.56336 8.2144 3.74694 6 3.75C4.948 3.75 3.938 3.93 3 4.262V18.512C3.96362 18.172 4.97816 17.9989 6 18C8.305 18 10.408 18.867 12 20.292M12 6.042C13.6483 4.56328 15.7856 3.74685 18 3.75C19.052 3.75 20.062 3.93 21 4.262V18.512C20.0364 18.172 19.0218 17.9989 18 18C15.7856 17.9969 13.6484 18.8134 12 20.292M12 6.042V20.292"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </ListItemIcon>
                </ListItem>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                  <Button
                    sx={{
                      backgroundColor: '#6BBDBD',
                      color: 'white',
                      textTransform: 'none',
                      fontWeight: '600',
                      fontFamily: "'Fira Sans', sans-serif",
                      borderRadius: '24px',
                      padding: '8px 24px',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#6BBDBD',
                      },
                      width: '100%',
                      maxWidth: 'none',
                    }}
                    aria-controls="study-menu"
                    aria-haspopup="true"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    fullWidth
                  >
                    Estudiar
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                  <Menu
                    id="study-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      style: {
                        marginTop: '8px',
                        borderRadius: '12px',
                        minWidth: '200px',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      },
                    }}
                    style={{
                      marginTop: '8px'
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate('/new-exam');
                        setAnchorEl(null);
                      }}
                      style={{
                        padding: '12px 16px',
                        fontSize: '14px',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                        <QuizIcon style={{ color: '#666' }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Hacer test</span>
                          <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Ponte a prueba con un test</span>
                        </div>
                      </div>
                    </MenuItem>
                    <Divider style={{ margin: '4px 0' }} />
                    <Tooltip title={isBasicPlan ? "Funcionalidad solo para premium" : ""}>
                      <div>
                        <MenuItem
                          onClick={() => {
                            if (!isBasicPlan) {
                              navigate('/new-flashcards', { state: { reset: true }, replace: true });
                            }
                            setAnchorEl(null);
                          }}
                          style={{
                            padding: '12px 16px',
                            fontSize: '14px',
                            cursor: isBasicPlan ? 'default' : 'pointer', // Disable click for Basic plans
                            backgroundColor: isBasicPlan ? '#f0f0f0' : 'inherit', // Light gray background for Basic plans
                            position: 'relative', // Position relative for lock icon placement
                          }}
                          disabled={isBasicPlan} // Disable the MenuItem for Basic plans
                        >
                          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <PsychologyIcon style={{ color: '#666' }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Memorizar</span>
                              <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Responde a flashcards</span>
                            </div>
                            {isBasicPlan && (
                              <LockIcon style={{
                                color: '#666',
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                                fontSize: '16px' // Smaller size for the lock icon
                              }} />
                            )}
                          </div>
                        </MenuItem>
                      </div>
                    </Tooltip>
                  </Menu>
                </Box>
              )}
            </Box>

            {/* <ListItem
              button
              key="Memorise"
              className={`${classes.listItem} sidebar-step-file `}
              onClick={() => { navigate("/memorise", { state: { reset: true }, replace: true }) }}
              style={{ marginTop: "5px" }}

            >
              <ListItemIcon className={classes.listItemIcon}>
                <svg className={classes.svgIconFlashcards} xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                  <path d="M11.933 21.7965C12.2821 21.9996 12.6886 22.053 13.0786 21.9462C13.467 21.8393 13.792 21.5847 13.9926 21.2295L20.799 9.18832C20.9996 8.83361 21.0523 8.42067 20.947 8.02554C20.8417 7.6304 20.5907 7.3012 20.2407 7.09714L13.5501 3.20404C13.2005 3.00045 12.792 2.94655 12.404 3.05436C12.0151 3.1612 11.6901 3.4158 11.4895 3.77051L10.5954 5.35152V15.7044C10.5954 16.8147 9.70549 17.7176 8.61213 17.7176H5C5.0759 17.7855 5.15321 17.8519 5.24144 17.9034L11.933 21.7965Z" />
                  <path d="M0 16.3727C0 17.2699 0.630663 18 1.40628 18H8.59416C9.36934 18 10 17.2699 10 16.3727V1.62679C10 0.730061 9.36934 0 8.59416 0H1.40628C0.631105 0 0 0.730061 0 1.62679V16.3727Z" />
                </svg>
              </ListItemIcon>
              {!collapsed && (
                <ListItemText
                  primary={t("study_session")}
                  classes={{ primary: classes.text }}

                />)}
            </ListItem> */}
            {/* 
            <ListItem button
              className={`${classes.listItem} sidebar-step-file `}

            >
              <ListItemIcon className={classes.listItemIcon}>
                <img src={sesionesEstudio} />
              </ListItemIcon>
              <ListItemText
                primary={"Sesiones de estudio"}
                classes={{ primary: classes.text }}

              />
            </ListItem> */}


          </List>

          {/* Bottom section */}
          <div className={classes.bottom}>

            <List style={{ marginRight: 10 }}>
              {/* {hasSubscription && (


                <Link to="/admin" style={{ textDecoration: 'none', color: 'inherit' }}>

                  <ListItem button key="Mi cuenta" className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 9V12M18 12V15M18 12H21M18 12H15M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM3 20C3 16.6863 5.68629 14 9 14C12.3137 14 15 16.6863 15 20V21H3V20Z" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </ListItemIcon>
                    <ListItemText
                      primary="Admin"
                      classes={{ primary: classes.text }}
                    />
                  </ListItem>
                </Link>)} */}




              {!collapsed && (
                (isFreePlan || isBasicPlan) ? (
                  <div className={classes.boxCredits2}>
                    <Typography className={classes.creditsText}>
                      Has subido <strong>{userData.num_documents}</strong> de <strong>{freeDocumentsLimit}</strong> documentos gratuitos📚
                    </Typography>
                  </div>
                ) : null
              )}
              {!collapsed && (
                <div className={classes.boxCredits}>
                  <Typography className={classes.creditsText}>
                    {currentCredits === -100 ? (
                      <div style={{ textAlign: 'center', width: '100%' }}>⚡ Créditos ilimitados</div>
                    ) : (
                      <>
                        {t("credits_left", { currentCredits })} <a style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }} className={classes.creditsText} onClick={() => navigate("/plans")}>{t("buy")}</a>

                      </>
                    )}
                  </Typography>

                </div>)}
              {!collapsed ? (
                userData?.plan_name !== "External" && (
                  <Button sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Fira Sans', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 24px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#6BBDBD',
                    },
                    width: '100%',
                    maxWidth: 'none',
                    marginBottom: "5px"
                  }} onClick={() => { navigate("/plans") }}>¡Mejorar plan!</Button>
                )
              ) :
                userData?.plan_name !== "External" && (
                  <ListItemIcon onClick={() => { navigate("/plans") }} className={classes.listItemIconUpgrade}>
                    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="46" height="46" rx="8" fill="#E2F1F1" />
                      <path d="M31.357 21.2733L20.3022 33.0612C19.9881 33.3961 19.4494 33.0244 19.651 32.612L23.5835 24.5653C23.7133 24.2996 23.5198 23.9897 23.2241 23.9897H14.9329C14.5813 23.9897 14.4008 23.5686 14.6432 23.3139L24.8994 12.5377C25.2043 12.2174 25.7268 12.5556 25.5594 12.9649L22.6201 20.1522C22.5117 20.4174 22.709 20.7073 22.9955 20.7036L31.06 20.5997C31.4127 20.5952 31.5982 21.0161 31.357 21.2733Z" fill="#6BBDBD" />
                    </svg>
                  </ListItemIcon>
                )
              }

              <ListItem
                button
                key="Sugerencias"
                component="a"
                href="https://tally.so/r/w5l7a6"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.listItem2}
                sx={{
                  backgroundColor: '#E2F1F1',
                  borderRadius: '8px',
                  marginBottom: '8px',
                  '&:hover': {
                    backgroundColor: '#d0e7e7',
                  }
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <svg className={classes.svgIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.813 15.904L9 18.75L6.80825 16.5613C6.30225 16.0558 5.98725 15.8025 5.64624 15.6192C5.34374 15.4551 5.02724 15.3252 4.69974 15.2325C4.32874 15.1275 3.93724 15.0834 3.15374 14.995C2.99999 14.9767 2.92311 14.9675 2.86719 14.9558C2.81611 14.9452 2.79558 14.9367 2.78471 14.9303C2.77321 14.9236 2.76953 14.9199 2.76953 14.9163C2.76953 14.9124 2.77321 14.9087 2.78471 14.902C2.79558 14.8957 2.81611 14.8872 2.86719 14.8766C2.92311 14.8648 2.99999 14.8557 3.15374 14.8374C3.93724 14.749 4.32874 14.7049 4.69974 14.5999C5.02724 14.5072 5.34374 14.3773 5.64624 14.2132C5.98725 14.0299 6.30225 13.7766 6.80825 13.2711L9 11.0824L11.1918 13.2711C11.6978 13.7766 11.9505 14.0299 12.2915 14.2132C12.594 14.3773 12.9105 14.5072 13.238 14.5999C13.609 14.7049 14.0005 14.749 14.784 14.8374C14.9377 14.8557 15.0146 14.8648 15.0705 14.8766C15.1216 14.8872 15.1421 14.8957 15.153 14.902C15.1645 14.9087 15.1682 14.9124 15.1682 14.9163C15.1682 14.9199 15.1645 14.9236 15.153 14.9303C15.1421 14.9367 15.1216 14.9452 15.0705 14.9558C15.0146 14.9675 14.9377 14.9767 14.784 14.995C14.0005 15.0834 13.609 15.1275 13.238 15.2325C12.9105 15.3252 12.594 15.4551 12.2915 15.6192C11.9505 15.8025 11.6978 16.0558 11.1918 16.5613L9 18.75L8.187 15.904M7.75 7.75H10.25M13.75 7.75H16.25M7.75 11.25H16.25M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </ListItemIcon>
                {!collapsed && (
                  <ListItemText
                    primary="Sugerencias"
                    classes={{ primary: classes.text }}
                  />
                )}
              </ListItem>

              <Link to="/myaccount" style={{ textDecoration: 'none', color: 'inherit', margin: 0, padding: 0 }}>
                <ListItem button key="Mi cuenta" className={`${classes.listItem2} ${location.pathname === '/myaccount' && classes.selected}`}

                >

                  <ListItemIcon className={classes.listItemIcon}>
                    <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                      <path d="M17.5 18.375C17.5 15.9588 14.366 14 10.5 14C6.63401 14 3.5 15.9588 3.5 18.375M10.5 11.375C8.08375 11.375 6.125 9.41625 6.125 7C6.125 4.58375 8.08375 2.625 10.5 2.625C12.9162 2.625 14.875 4.58375 14.875 7C14.875 9.41625 12.9162 11.375 10.5 11.375Z" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                    </svg>
                  </ListItemIcon>
                  {!collapsed && (
                    <ListItemText
                      primary={t("my_account")}
                      classes={{ primary: classes.text }}
                    />)}
                </ListItem>
              </Link>
              {/* <ListItem button key="Help" onClick={handleOpenTallyPopup} className={classes.listItem2}
              >
                <ListItemIcon className={classes.listItemIcon}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.14648 9.07361C9.31728 8.54732 9.63015 8.07896 10.0508 7.71948C10.4714 7.36001 10.9838 7.12378 11.5303 7.03708C12.0768 6.95038 12.6362 7.0164 13.1475 7.22803C13.6587 7.43966 14.1014 7.78875 14.4268 8.23633C14.7521 8.68391 14.9469 9.21256 14.9904 9.76416C15.0339 10.3158 14.9238 10.8688 14.6727 11.3618C14.4215 11.8548 14.0394 12.2685 13.5676 12.5576C13.0958 12.8467 12.5533 12.9998 12 12.9998V14.0002M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 17V17.1L11.9502 17.1002V17H12.0498Z" stroke="black" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                </svg>

                </ListItemIcon>
                <ListItemText
                  primary={t("help_layout")}
                  classes={{ primary: classes.text }}
                />
              </ListItem> */}
              <ListItem button key="Logout" onClick={handleLogout} className={classes.listItem2}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <svg className={classes.svgIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 15L15 12M15 12L12 9M15 12H4M4 7.24802V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2839 4.21799 18.9076C4 18.4798 4 17.9201 4 16.8V16.75" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </ListItemIcon>
                {!collapsed && (
                  <ListItemText
                    primary={t("exit")}
                    classes={{ primary: classes.text }}
                  />)}
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer >
      {/* <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
      </div> 
       <Outlet /> */}

      <Menu
        id="study-menu-collapsed"
        anchorEl={anchorElCollapsed}
        keepMounted
        open={Boolean(anchorElCollapsed)}
        onClose={() => setAnchorElCollapsed(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            marginLeft: '8px',
            borderRadius: '12px',
            minWidth: '200px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/new-exam');
            setAnchorElCollapsed(null);
          }}
          style={{
            padding: '12px 16px',
            fontSize: '14px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <QuizIcon style={{ color: '#666' }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Hacer test</span>
              <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Ponte a prueba con un test</span>
            </div>
          </div>
        </MenuItem>
        <Divider style={{ margin: '4px 0' }} />
        <Tooltip title={isBasicPlan ? "Funcionalidad solo para premium" : ""}>
          <div>
            <MenuItem
              onClick={() => {
                if (!isBasicPlan) {
                  navigate('/new-flashcards', { state: { reset: true }, replace: true });
                }
                setAnchorElCollapsed(null);
              }}
              style={{
                padding: '12px 16px',
                fontSize: '14px',
                cursor: isBasicPlan ? 'default' : 'pointer', // Disable click for Basic plans
                backgroundColor: isBasicPlan ? '#f0f0f0' : 'inherit', // Light gray background for Basic plans
                position: 'relative', // Position relative for lock icon placement
              }}
              disabled={isBasicPlan} // Disable the MenuItem for Basic plans
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <PsychologyIcon style={{ color: '#666' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Memorizar</span>
                  <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Responde a flashcards</span>
                </div>
                {isBasicPlan && (
                  <LockIcon style={{
                    color: '#666',
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    fontSize: '16px' // Smaller size for the lock icon
                  }} />
                )}
              </div>
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>

    </>
  );
};

export default Sidebar;