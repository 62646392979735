import React from 'react';
import { Typography } from '@mui/material';

const Flashcards = ({ currentCard, state, studyMode }) => {
  const renderText = () => {

    const parts = currentCard.text.split(/\[(.*?)\]/);
    let clozeIndex = 0;

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const isCurrentCloze = clozeIndex === currentCard.currentClozeIndex;
        const wasAnsweredCorrectly = currentCard.answeredCorrectly?.includes(clozeIndex);
        const shouldShowAnswer =
          wasAnsweredCorrectly || // Show if answered correctly
          (isCurrentCloze && state.isCorrect) || // Show current if correct
          currentCard.revealed; // Show all answers if revealed

        clozeIndex++;

        if (shouldShowAnswer) {
          return (
            <Typography
              key={index}
              component="span"
              sx={{
                color: studyMode === 'flashcard' && currentCard.revealed ? '#00B564' :
                  (wasAnsweredCorrectly ? '#00B564' : '#FF4444'),
                fontWeight: 'bold',
                fontFamily: "'Inter', sans-serif",
                fontSize: '16px'
              }}
            >
              {part}
            </Typography>
          );
        }
        return (
          <Typography
            key={index}
            component="span"
            sx={{
              backgroundColor: isCurrentCloze ? '#E2F1F1' : '#F5F5F5',
              padding: '0 8px',
              borderRadius: '4px'
            }}
          >
            {part.split(' ').map(() => '_').join(' ')}
          </Typography>
        );
      }
      return part;
    });
  };

  return (
    <Typography
      variant="h6"
      sx={{
        textAlign: 'center',
        fontFamily: "'Inter', sans-serif",
        fontSize: '18px',
        lineHeight: 1.6
      }}
    >
      {renderText()}
    </Typography>
  );
};

export default Flashcards;
