import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';

const CustomizeTask = ({ onClose, onSave }) => {
  const [studyMode, setStudyMode] = useState('typing');

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setStudyMode(newMode);
    }
  };

  const handleStartStudy = () => {
    onSave({
      studyMode: studyMode
    });
  };

  return (
    <Box sx={{ p: 2, margin: '0 auto' }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Button
          onClick={onClose}
          startIcon={<span>←</span>}
          sx={{
            color: '#666',
            textTransform: 'none',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            fontSize: '16px',
            '&:hover': {
              backgroundColor: 'transparent',
            }
          }}
        >
          Volver
        </Button>
        <Button
          onClick={handleStartStudy}
          variant="contained"
          sx={{
            bgcolor: '#6BBDBD',
            color: 'white',
            textTransform: 'none',
            fontSize: "14px",
            borderRadius: "20px",
            padding: "6px 22px",
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            '&:hover': {
              bgcolor: '#5AACAC',
            },
            '&.Mui-disabled': {
              bgcolor: '#cccccc',
              color: '#666666'
            }
          }}
        >
          ¡Empezar!
        </Button>
      </Box>

      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          fontFamily: 'Fira Sans, sans-serif',
          color: 'black',
          textAlign: 'center',
          fontSize: '20px',
          marginBottom: '40px'
        }}
      >
        Personalizar estudio
      </Typography>

      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontFamily: 'Fira Sans, sans-serif',
            textAlign: 'left',
            marginBottom: '12px'
          }}
        >
          Elige tu modo de estudio
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <ToggleButtonGroup
            value={studyMode}
            exclusive
            onChange={handleModeChange}
            aria-label="study mode"
            sx={{
              backgroundColor: '#F5F5F5',
              padding: '4px',
              borderRadius: '12px',
              '& .MuiToggleButton-root': {
                border: 'none',
                borderRadius: '8px !important',
                mx: 0.5,
                px: 2,
                py: 1,
                textTransform: 'none',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
                '&.Mui-selected': {
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                  },
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              },
            }}
          >
            <ToggleButton
              value="typing"
              aria-label="typing mode"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <span role="img" aria-label="keyboard" style={{ fontSize: '16px' }}>⌨️</span>
              Modo tecleo
            </ToggleButton>
            <ToggleButton
              value="flashcard"
              aria-label="flashcard mode"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <span role="img" aria-label="cards" style={{ fontSize: '16px' }}>🎴</span>
              Modo Voz Alta
            </ToggleButton>
          </ToggleButtonGroup>

          <Typography sx={{
            color: '#666',
            fontSize: '14px',
            fontFamily: 'Fira Sans',
            textAlign: 'center',
            maxWidth: '400px'
          }}>
            {studyMode === 'typing'
              ? "Escribe la respuesta y mejora tu retención."
              : "Di la respuesta en voz alta."}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomizeTask; 