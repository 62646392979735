import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../Auth/Authhandle';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
        maxWidth: 1200,
        margin: '0 auto',
    },
    title: {
        marginBottom: theme.spacing(3),
        fontFamily: '"Fira Sans", sans-serif',
    },
    tableContainer: {
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    headerCell: {
        color: '#fff',
        fontWeight: 'bold',
    },
    typography: {
        fontFamily: '"Fira Sans", sans-serif',
    },
    noPermission: {
        textAlign: 'center',
        padding: theme.spacing(3),
        color: theme.palette.error.main
    },
    skeleton: {
        backgroundColor: theme.palette.action.hover
    }
}));

const TableSkeleton = () => {
    const classes = useStyles();
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeader}>
                        <TableCell className={classes.headerCell}>Correo</TableCell>
                        <TableCell className={classes.headerCell}>Idioma</TableCell>
                        <TableCell className={classes.headerCell}>Fecha de registro</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...Array(5)].map((_, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Skeleton variant="text" width="80%" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" width="60%" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" width="70%" />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const Dashboard = () => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { accessToken, user } = useAuth(); // Use the hook at the top level

    useEffect(() => {
        const fetchUsers = async () => {
            const user_id = user['sub'];

            try {
                const apiUrl = `${BACKEND_URL}/api_v1/external_users_dashboard/?user_id=${user_id}`;
                const headers = {
                    'Authorization': `Bearer ${accessToken}`, // Include the token in the Authorization header
                };
                const requestOptions = {
                    method: "GET",
                    headers: headers,
                };
                const response = await fetch(apiUrl, requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsers(data);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch users');
                setLoading(false);
            }
        };

        if (accessToken && user) {
            fetchUsers();
        }
    }, [accessToken, user]);

    if (loading) {
        return (
            <div className={classes.container}>
                <Typography variant="h4" className={`${classes.title} ${classes.typography}`}>
                    Usuarios UGT
                </Typography>
                <TableSkeleton />
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.container}>
                <Typography color="error">{error}</Typography>
            </div>
        );
    }

    if (users.length === 0) {
        return (
            <div className={classes.container}>
                <Typography variant="h4" className={`${classes.title} ${classes.typography}`}>
                    Usuarios
                </Typography>
                <Typography variant="h6" className={classes.noPermission}>
                    No tiene permisos para ver esta información
                </Typography>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <Typography variant="h4" className={`${classes.title} ${classes.typography}`}>
                Usuarios UGT
            </Typography>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.tableHeader}>
                            <TableCell className={classes.headerCell}>Correo</TableCell>
                            <TableCell className={classes.headerCell}>Idioma</TableCell>
                            <TableCell className={classes.headerCell}>Fecha de registro</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.guid || user.email}>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.language}</TableCell>
                                <TableCell>
                                    {user.date_entered ? new Date(user.date_entered).toLocaleDateString() : <span style={{ color: 'red' }}>NOT REGISTERED</span>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Dashboard; 