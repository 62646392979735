import React from 'react';
import { Typography, Divider } from '@mui/material';

const QuestionFlashcard = ({ currentCard, state, studyMode }) => {
  const getTextColor = (index) => {
    if (studyMode === 'flashcard' && currentCard.revealed) {
      return '#00B564';
    }
    
    if (currentCard.revealed) {
      return currentCard.currentClozeIndex === index ? '#FF4444' : '#2D2D2D';
    }
    return currentCard.answeredCorrectly?.includes(index) ? '#00B564' : '#2D2D2D';
  };

  const renderAnswer = () => {
    const parts = currentCard.answer.split(/\[(.*?)\]/);
    let clozeIndex = 0;
    
    return (
      <>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            fontFamily: "'Inter', sans-serif",
            fontSize: '18px',
            lineHeight: 1.6,
            marginBottom: '20px',
            color: '#2D2D2D'
          }}
        >
          {currentCard.question}
        </Typography>

        <Divider 
          sx={{
            margin: '20px auto',
            width: '100%',
            backgroundColor: '#D9D9D9',
            height: '1px'
          }}
        />

        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            fontFamily: "'Inter', sans-serif",
            fontSize: '18px',
            lineHeight: 1.6
          }}
        >
          {parts.map((part, index) => {
            if (index % 2 === 1) {
              const isCurrentCloze = clozeIndex === currentCard.currentClozeIndex;
              const wasAnsweredCorrectly = currentCard.answeredCorrectly?.includes(clozeIndex);
              const shouldShowAnswer = 
                wasAnsweredCorrectly || 
                (isCurrentCloze && state.isCorrect) || 
                currentCard.revealed;
              
              const clozeIndexCurrent = clozeIndex;
              clozeIndex++;
              
              if (shouldShowAnswer) {
                return (
                  <Typography
                    key={index}
                    component="span"
                    sx={{ 
                      color: studyMode === 'flashcard' ? '#00B564' : 
                             (wasAnsweredCorrectly ? '#00B564' : '#FF4444'),
                      fontWeight: 'bold',
                      fontFamily: "'Inter', sans-serif",
                      fontSize: '16px'
                    }}
                  >
                    {part}
                  </Typography>
                );
              }
              return (
                <Typography
                  key={index}
                  component="span"
                  sx={{ 
                    backgroundColor: isCurrentCloze ? '#E2F1F1' : '#F5F5F5',
                    padding: '0 8px',
                    borderRadius: '4px'
                  }}
                >
                  {part.split(' ').map((word, i) => 
                    '_' + (i < part.split(' ').length - 1 ? ' ' : '')
                  ).join('')}
                </Typography>
              );
            }
            return part;
          })}
        </Typography>
      </>
    );
  };

  return renderAnswer();
};

export default QuestionFlashcard; 