import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, Box, Typography, Button, CircularProgress, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import { WebSocketContext } from "../WebSocketProvider";
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '12px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: '32px',
        width: '100%',
        maxWidth: '1000px',
        margin: '20px',
        outline: 'none',
        maxHeight: '650px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    content: {
        fontFamily: 'Inter, sans-serif',
        color: 'black',
        fontSize: '14px',
        whiteSpace: 'pre-wrap',
        overflowY: 'auto',
        flex: 1,
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    button: {
        marginTop: theme.spacing(2),
        backgroundColor: '#6BBDBD',
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Inter, sans-serif',
        boxShadow: 'none',
        fontWeight: '600',
        fontSize: '14px',
        borderRadius: '20px',
        padding: '8px 24px',
        '&:hover': {
            backgroundColor: '#5AA9A9',
        },
    },
    header: {
        flexShrink: 0,
    },
    footer: {
        flexShrink: 0,
    },
    loader: {
        color: '#6BBDBD',
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: '16px',
        top: '16px',
        color: '#666',
        '&:hover': {
            color: '#333',
        },
    },
    messageBox: {
        padding: '12px 16px',
        borderRadius: '8px',
        marginBottom: '16px',
        backgroundColor: '#F5F8FF',
        border: '1px solid #E0E0E0',
        width: '100%',
        '& p': {
            marginBottom: '1em',
            '&:last-child': {
                marginBottom: 0,
            }
        },
        '& ul, & ol': {
            marginLeft: '1em',
            marginBottom: '1em',
        },
    },
}));

function JustificationPopup({ open, onClose, question, correctAnswer, justificationText, userAnswer, isLoading, user, document_id }) {
    const classes = useStyles();
    const [displayText, setDisplayText] = useState('');
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const lastChunkRef = useRef('');
    const messagesEndRef = useRef(null);
    const [isLoadingResponse, setIsLoadingResponse] = useState(false);
    const { wsChat, chatMessageCallback } = useContext(WebSocketContext);
    const ongoingStream = useRef(null);

    // Add this configuration for DOMPurify
    const sanitizeConfig = {
        ALLOWED_TAGS: ['br', 'strong'], // Allow <br> and <strong> tags
        ALLOWED_ATTR: [], // No attributes needed for these tags
    };

    // Reset when modal closes
    useEffect(() => {
        if (!open) {
            setDisplayText('');
            lastChunkRef.current = '';
            setChatHistory([]); // Clear chat history
            if (wsChat?.current) {
                wsChat.current.send(JSON.stringify({
                    event: 'disconnect',
                    user_id: user.sub,
                    document_id: document_id
                }));
            }
        }
    }, [open, user.sub, document_id]);

    // Handle new text chunks
    useEffect(() => {
        console.log("justificationText", justificationText);
        if (justificationText && justificationText !== '[DONE]') {
            // Get only the new part of the text
            const newText = justificationText.replace(lastChunkRef.current, '');
            lastChunkRef.current = justificationText;

            // Convert <br> to newline if present
            const formattedText = newText.replace(/<br>/g, '\n \n');

            setDisplayText(prev => prev + formattedText);
        }

    }, [justificationText]);

    // Handle WebSocket messages
    useEffect(() => {
        if (chatMessageCallback) {
            setIsLoadingResponse(false);
            let sender = chatMessageCallback.name;

            switch (chatMessageCallback.event) {
                case 'on_parser_start':
                    ongoingStream.current = { id: chatMessageCallback.run_id, content: '' };
                    setChatHistory(prevHistory => [
                        ...prevHistory,
                        { sender: 'Machine', message: '', id: chatMessageCallback.run_id }
                    ]);
                    break;

                case 'on_parser_stream':
                    if (ongoingStream.current && chatMessageCallback.run_id === ongoingStream.current.id) {
                        ongoingStream.current.content += chatMessageCallback.data.chunk;
                        setChatHistory(prevHistory => {
                            const newHistory = [...prevHistory];
                            const index = newHistory.findIndex(msg => msg.id === chatMessageCallback.run_id);
                            if (index !== -1) {
                                newHistory[index] = {
                                    ...newHistory[index],
                                    message: ongoingStream.current.content
                                };
                            }
                            return newHistory;
                        });
                    }
                    break;

                case 'Error':
                    setChatHistory(prevHistory => {
                        const newHistory = prevHistory.map(msg =>
                            msg.id === chatMessageCallback.run_id
                                ? { sender: "Machine", message: "Lo siento, ha ocurrido un error." }
                                : msg
                        );
                        return newHistory;
                    });
                    break;
            }
        }
    }, [chatMessageCallback]);

    // Update the createMarkup function
    const createMarkup = (html) => {
        return { __html: DOMPurify.sanitize(html, sanitizeConfig) };
    };

    // Add this style
    const renderMessage = (message, index) => {
        return (
            <div key={index}>
                <div style={{
                    marginBottom: "5px",
                    marginTop: "15px"
                }}>
                    <Typography style={{ fontWeight: "bold", fontSize: "14px", marginBottom: '8px' }}>
                        {message.sender === 'Machine' ? 'Profesor' : 'Tú'}
                    </Typography>
                </div>
                <div className={classes.messageBox}>
                    <ReactMarkdown>
                        {message.message}
                    </ReactMarkdown>
                </div>
            </div>
        );
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const handleSubmit = (e) => {
        if (e) e.preventDefault();

        // Trim the input and check if it's not empty
        if (userInput.trim()) {

            setIsLoadingResponse(true);

            const userMessage = { sender: "You", message: userInput };
            setChatHistory(prev => [...prev, userMessage]);

            // You can add posthog tracking if needed
            // posthog.capture('send_message_chat');
            console.log("user_id", user);
            // Send message through WebSocket with required parameters
            if (chatHistory.length === 0) {
                // Include question and correctAnswer in the initial message
                const message = {
                    message: userInput,
                    user_id: user.sub,
                    question: question,
                    userAnswer: userAnswer,
                    correctAnswer: correctAnswer,
                    justification: displayText,
                    document_id: document_id
                };
                wsChat.current.send(JSON.stringify(message));
            } else {
                // Regular message handling
                const message = {
                    message: userInput,
                    user_id: user.sub,
                    document_id: document_id
                };
                wsChat.current.send(JSON.stringify(message));
            }

            setUserInput(''); // Clear input field after sending
        }
    };

    // Auto-scroll to bottom when new messages arrive
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatHistory]);

    // Add typing animation component
    const TypingAnimation = () => (
        <div style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "10px",
        }}>
            <Typography style={{
                padding: "6px",
                maxWidth: "60%",
                borderRadius: "15px",
                backgroundColor: "#026277",
                color: "white",
                display: "flex",
                alignItems: "center",
            }}>
                <span className="dot dot1"></span>
                <span className="dot dot2"></span>
                <span className="dot dot3"></span>
            </Typography>
        </div>
    );

    const formatText = (text) => {
        return text
            // Replace \n\n with actual line breaks
            .replace(/\\n\\n/g, '\n\n')
            // Replace \n with single line break
            .replace(/\\n/g, '\n')
            // Fix markdown bold syntax if needed
            .replace(/\*\*/g, '**');
    };

    return (
        <Modal open={open} onClose={onClose} className={classes.modal}>
            <Box className={classes.paper}>
                <IconButton
                    onClick={onClose}
                    className={classes.closeButton}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>

                <Typography
                    variant="h6"
                    className={classes.header}
                    style={{
                        fontSize: "20px",
                        fontFamily: "Fira Sans",
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: '24px'
                    }}
                >
                    Justificación
                </Typography>

                <div style={{
                    flexGrow: 1,
                    overflowY: "auto",
                    maxHeight: "calc(100% - 180px)",
                    padding: "16px",
                }}>
                    {displayText ? (
                        <div className={classes.messageBox}>
                            <ReactMarkdown>
                                {formatText(displayText)}
                            </ReactMarkdown>
                        </div>
                    ) : (
                        <div className={classes.messageBox}>
                            <Typography>Generando justificación...</Typography>
                        </div>
                    )}

                    {chatHistory.filter(msg => msg.id !== 'initial-justification').map((message, index) => renderMessage(message, index))}
                    {isLoadingResponse && <TypingAnimation />}
                    <div ref={messagesEndRef} />
                </div>

                {displayText && (
                    <div style={{
                        display: "flex",
                        marginBottom: 10,
                        marginTop: 10,
                        borderRadius: "8px",
                        border: "1px solid #D5D4DC",
                        background: "#F5F8FF",
                        marginLeft: "1em",
                        marginRight: "1em",
                    }}>
                        <TextField
                            fullWidth
                            value={userInput}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                style: { padding: "5px", fontSize: "12px" },
                            }}
                            multiline
                            disabled={isLoadingResponse}
                            style={{ padding: "10px", fontSize: "14px" }}
                            placeholder="Escribe aquí tu pregunta"
                            onChange={(e) => setUserInput(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                        <Button
                            color="primary"
                            onClick={handleSubmit}
                            endIcon={<SendIcon />}
                            style={{ marginLeft: "10px" }}
                        />
                    </div>
                )}
            </Box>
        </Modal>
    );
}

export default JustificationPopup; 