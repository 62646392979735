import React, { useState } from 'react';
import Confetti from 'react-confetti';
import { Typography, Box, Grid, List, ListItem, ListItemIcon, ListItemText, Button, Container, Dialog, DialogContent } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useStyles from './useStylesPricing';
import '../Main/style.css';
import { loadStripe } from '@stripe/stripe-js';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getCsrfToken } from '../Api/TokenApi'
import { useAuth } from "../Auth/Authhandle";
import { useSubscription } from "../Context/SubscriptionContext";
import CancellationForm from '../AllModalsActions/CancellationForm';
const REACT_APP_STRIPE_LOADER = process.env.REACT_APP_STRIPE_LOADER;

const stripePromise = loadStripe(REACT_APP_STRIPE_LOADER);
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;




const PricingView = () => {
  const classes = useStyles();
  const { userData } = useSubscription();
  const { accessToken, user } = useAuth(); // Use the hook at the top level

  const [selectedPlan, setSelectedPlan] = useState('cuatrimestral');

  const [showConfetti, setShowConfetti] = useState(false);

  const typedFeatures = [
    "Estudia cualquier documento",
    "Sin límite de preguntas por test",
    "Sube tantos documentos como quieras",
    "Resúmenes completos en segundos",
    "3000 créditos mensuales",
    "Sin límite de uso diario",
    "Más asequible que cualquier otra opción"
  ];
  const [planId, setPlanId] = useState(process.env.REACT_APP_QUARTERLY_PLAN_ID)
  const [isLoading, setIsLoading] = useState(false);

  const [cancellationFormOpen, setCancellationFormOpen] = useState(false);

  // Map userData.plan_name to the correct plan name in your JSON
  const planNameMapping = {
    "Free": "Gratis",
    "Quarterly": "Quarterly",
    "Monthly": "Monthly",
    "Basic-Monthly": "Basic-Monthly",
    "Basic-Semestral": "Basic-Semestral",
    "Basic-Anual": "Basic-Anual",
    "Premium-Monthly": "Premium-Monthly",
    "Premium-Semestral": "Premium-Semestral",
    "Premium-Anual": "Premium-Anual"
  };

  const currentPlanName = planNameMapping[userData.plan_name];

  const handlePlanSelected = async (selectedPlan, billingPeriod) => {
    const planChosen = selectedPlan.planIds[billingPeriod];

    if (!planChosen) {
      alert("Invalid plan selection");
      return;
    }

    if (selectedPlan.title === currentPlanName) {
      alert("Ya tienes esta suscripción");
      return;
    }

    setIsLoading(true);

    try {
      const csrfToken = getCsrfToken(); // Get CSRF token for non-GET requests
      // Call your backend to create a checkout session
      const response = await fetch(`${BACKEND_URL}/create-checkout-session/?user_id=${user['sub']}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
          'Authorization': `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({ "priceId": planChosen }), // Use the selected plan ID
      });

      const result = await response.json();

      // When the backend returns the session ID, redirect to Stripe checkout
      if (response.ok && (result.subscriptionUpdated || result.subscriptionCreated)) {
        if (selectedPlan.title !== "Basic") {
          window.location.replace("/");
        }
      } else if (response.ok && result.sessionId) {
        // When the backend returns a session ID for a new checkout, redirect to Stripe checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: result.sessionId,
        });
        if (error) {
          console.error('Stripe Checkout error:', error.message);
          setIsLoading(false);
        }
      } else {
        // Handle any errors or unexpected responses
        console.error('Unexpected response:', result);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Failed to start the checkout process:', error);
      setIsLoading(false);
    }
  };
  const handleCancelSubscription = () => {
    setCancellationFormOpen(true);
  };

  const handleCloseCancellationForm = () => {
    setCancellationFormOpen(false);
  };

  const handleFormSubmit = (formData) => {
    handlePlanSelected("Free"); // Downgrade to basic plan
    setCancellationFormOpen(false);
    window.location.href = "www.typedai.com";
  };

  return (
    <Container style={{ overflowY: 'auto', paddingBottom: '20px' }}>
      <Box className={classes.container}>
        <Typography className={classes.title}>
          ¿Por qué deberías estudiar con Typed?
        </Typography>

      </Box>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Box className={classes.typedCard} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box display="flex" alignItems="center" justifyContent="center" marginBottom="16px">
              <Typography variant="h5" className={classes.typedTitle} style={{ marginRight: '16px' }}>Typed</Typography>
              <Typography variant="subtitle1" className={classes.typedSubtitle} style={{ textAlign: 'left' }}>
                La mejor forma de preparar tu oposición
              </Typography>
            </Box>
            <List className={classes.featureList}>
              {typedFeatures.map((feature, index) => (
                <ListItem key={index} className={classes.featureItem}>
                  <ListItemIcon>
                    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle' }}>
                      <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                      <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                    </svg>
                  </ListItemIcon>
                  <ListItemText
                    primary={feature}
                    primaryTypographyProps={{ style: { fontSize: '18px' } }}
                  />
                </ListItem>
              ))}
            </List>
            <Box className={classes.planContainer} sx={{ width: '100%', mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant={selectedPlan === 'cuatrimestral' ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={() => setSelectedPlan('cuatrimestral')}
                    style={{
                      position: 'relative',
                      textTransform: 'none',
                      height: '80px',
                      borderRadius: '20px',
                      backgroundColor: '#FFFFFF',
                      border: '2px solid #6BBDBD',
                      boxShadow: selectedPlan === 'cuatrimestral' ? '0px 4px 20px rgba(0, 0, 0, 0.6)' : 'none'
                    }}
                  >
                    <Box>
                      <Typography variant="h6" style={{ fontSize: '16px', color: '#333' }}>
                        Plan cuatrimestral
                      </Typography>
                      <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: '#6BBDBD' }}>
                        5,99€/mes
                      </Typography>
                    </Box>
                    {selectedPlan === 'cuatrimestral' && (
                      <CheckCircleIcon
                        style={{
                          position: 'absolute',
                          bottom: -10,
                          right: -10,
                          color: '#4caf50',
                          backgroundColor: 'white',
                          borderRadius: '50%',
                        }}
                      />
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant={selectedPlan === 'mensual' ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={() => setSelectedPlan('mensual')}
                    style={{
                      position: 'relative',
                      textTransform: 'none',
                      height: '80px',
                      borderRadius: '20px',
                      backgroundColor: '#FFFFFF',
                      border: '2px solid #6BBDBD',
                      boxShadow: selectedPlan === 'mensual' ? '0px 4px 20px rgba(0, 0, 0, 0.6)' : 'none'
                    }}
                  >
                    <Box>
                      <Typography variant="h6" style={{ fontSize: '16px', color: '#333' }}>
                        Plan mensual
                      </Typography>
                      <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: '#6BBDBD' }}>
                        7,99€/mes
                      </Typography>
                    </Box>
                    {selectedPlan === 'mensual' && (
                      <CheckCircleIcon
                        style={{
                          position: 'absolute',
                          bottom: -10,
                          right: -10,
                          color: '#4caf50',
                          backgroundColor: 'white',
                          borderRadius: '50%',
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.subscribeButton}
              onMouseEnter={() => setShowConfetti(true)}
              onMouseLeave={() => setShowConfetti(false)}
              onClick={() => handlePlanSelected(selectedPlan)}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "¡Suscribirse!🎉"
              )}
            </Button>
            {(userData.plan_name === 'Monthly' || userData.plan_name === 'Quarterly') && userData.status === "paid" && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                mt={2}
              >
                <Button
                  color="secondary"
                  style={{ textTransform: 'none', backgroundColor: 'transparent', color: 'white', marginTop: "20px" }}
                  onClick={handleCancelSubscription}
                >
                  Cancelar suscripción
                </Button>
              </Box>)}

            {showConfetti && (
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                recycle={false}
                numberOfPieces={1200}
                gravity={0.1}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{
          margin: '50px auto', justifyContent: "center",
          display: "flex"
        }}>
          <Box>


            {/* Academias */}
            <Box mb={3}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '10px', textDecoration: 'underline' }}>
                Academias
              </Typography>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  <Typography variant="body2" style={{ fontSize: '16px' }}>Muy caras, entre 1.000 y 1.500 euros</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Soporte al estudiante limitado</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>No se adapta a tus horarios</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Difícil compaginar con el trabajo</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle', marginRight: "10PX" }}>
                    <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                    <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Estudio guiado</Typography>
                </Box>
              </Box>
            </Box>

            {/* Plataformas de tests */}
            <Box mb={3}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '10px', textDecoration: 'underline' }}>
                Plataformas de tests
              </Typography>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Precio elevado, entre 15€ y 30€ al mes</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>No proporcionan el temario o tiene un coste extra</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>No resuelven tus dudas</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Temario desactualizado</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle', marginRight: "10PX" }}>
                    <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                    <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Estudia cuando quieras</Typography>
                </Box>
              </Box>
            </Box>

            {/* Estudiar sólo */}
            <Box>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '10px', textDecoration: 'underline' }}>
                Estudiar sólo
              </Typography>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Falta de estructura y guía</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Dificultad para resolver dudas</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "10px" }}>
                    <path d="M11.3626 0.833984C5.08723 0.833984 0 5.31114 0 10.834C0 16.3568 5.08723 20.834 11.3626 20.834C17.638 20.834 22.7253 16.3568 22.7253 10.834C22.718 5.3138 17.635 0.840413 11.3626 0.833984Z" fill="#AA161D" fill-opacity="0.2" />
                    <path d="M15.5 15.334L11 10.834M11 10.834L6.5 6.33398M11 10.834L15.5 6.33398M11 10.834L6.5 15.334" stroke="#AA161D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Posible desmotivación</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle', marginRight: "10PX" }}>
                    <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                    <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                  </svg>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>Flexibilidad total de horarios</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <CancellationForm
        open={cancellationFormOpen}
        onClose={handleCloseCancellationForm}
        onSubmit={handleFormSubmit}
        accessToken={accessToken}
        user={user}
      />
    </Container>
  );
};

export default PricingView;
