import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, CircularProgress, Button, Tooltip, Dialog, LinearProgress } from '@mui/material';
import GettingStarted from '../Onboard/GettingStarted';
import RectanglesHome from '../Home/RectanglesHome';
import NeedCredits from '../NoCreditsModal/NeedCredits';

import UploadButton from '../NewComponents/SubirApuntesButton';
import { useAuth } from '../Auth/Authhandle';
import * as handle from "../Main/handle";
import { useNavigate } from 'react-router-dom';
import { useSubscription } from '../Context/SubscriptionContext';
import DocumentList from '../Documents/AllListDocument';
import Tasks from '../Home/Components/Tasks';
import LockIcon from '@mui/icons-material/Lock'; // Import LockIcon from MUI

import { useLocation } from 'react-router-dom';
import { WebSocketContext } from '../WebSocketProvider';
import Onboarding from '../Onboard/Onboarding';

// import DeleteDoc from '../PopUpsAlerts/DeleteDoc/DeleteDoc';
import { usePoints } from '../Context/PointsContext';
import SchoolIcon from '@mui/icons-material/School';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import QuizIcon from '@mui/icons-material/Quiz';
import PsychologyIcon from '@mui/icons-material/Psychology';


const Progress = ({ handleUploadFile, setShowBlockedDocument, listDocuments, setListDocuments }) => {
  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const { hasSubscription, setHasSubscription, userData, fetchData } = useSubscription();
  const location = useLocation(); // Hook to get the current location
  const navigate = useNavigate();
  const itemColors = [
    { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/greenCard.svg", color: '#00B564', tooltip: 'Preguntas Memorizadas' },
    { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/yellowCard.svg", color: '#FFA726', tooltip: 'Preguntas Aprendiendo' },
    { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/redCar.svg", color: '#AA161D', tooltip: 'Preguntas Incorrectas' },
    { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/grayCard.svg", color: '#BDBDBD', tooltip: 'Preguntas Sin empezar' }
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [showImprovements, setShowImprovements] = useState(true);
  const [showErrorPreguntes, setShowErrorPreguntes] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openNewPopupOnboarding, setOpenNewPopupOnboarding] = useState(true);
  const { messageCallback } = useContext(WebSocketContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const isBasicPlan = userData?.plan_name?.startsWith("Basic"); // Check if the plan is Basic  const [anchorElCollapsed, setAnchorElCollapsed] = useState(null);
  const [anchorElCollapsed, setAnchorElCollapsed] = useState(null);
  useEffect(() => {
    if (accessToken && user) {
      async function fetchDataDocs() {
        setIsLoading(true);
        if (accessToken && user) {
          await handle.fetchAllDocuments(accessToken, user, setListDocuments);
        }
        setIsLoading(false);
      }
      fetchDataDocs();
    }
  }, [accessToken, user]);
  useEffect(() => {
    if (fetchData) {
      fetchData(); // Fetch the latest user data when the component mounts
    }
  }, [location.pathname]);

  const { allPointsData, fetchAllPointsData } = usePoints();
  useEffect(() => {
    if (userData && !userData.done_onboarding) {
      navigate("/welcome")
    }
  }, [userData]);



  useEffect(() => {
    if (fetchAllPointsData) {
      fetchAllPointsData();

    }
  }, [fetchAllPointsData]);

  async function refetchDocuments() {
    setIsLoading(true);
    if (accessToken && user) {
      await handle.fetchAllDocuments(accessToken, user, setListDocuments);
    }
    setIsLoading(false);
  }



  useEffect(() => {
    if (messageCallback && messageCallback.type === "documentsUpdate") {
      // Refetch documents when we receive a documentsUpdate message
      refetchDocuments();
    }
    if (messageCallback && messageCallback.type === "copy_ugt_done") {
      refetchDocuments();
    }

  }, [messageCallback]);

  const cancellationStats = [
    { value: userData?.total_tests || '100', label: 'test' },
    { value: userData?.total_summaries || '5', label: 'resúmenes' },
    { value: userData?.total_schemas || '80', label: 'dudas resueltas' },
  ];

  const handleCancellationSubmit = (formData) => {
    console.log('Cancellation form data:', formData);
    // Handle the form submission data here
  };

  return (
    <>
      {openModal && (
        <Onboarding
          onClose={() => setOpenModal(false)}
        />
      )}

      <NeedCredits />
      <Box>
        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
          <div>

            {/* <Typography
                            variant="h6"
                            style={{
                                color: "#026277",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                letterSpacing: "-0.225px",
                                paddingLeft: 2
                            }}
                        >
                            {t('your_folders', { Nombre: user.given_name })}👋
                        </Typography> */}
          </div>

          {/*{userData && (
            <>

              {(!userData.has_uploaded_file || !userData.has_created_summary || !userData.has_created_exam) && (
                <GettingStarted
                  has_uploaded_file={userData.has_uploaded_file}
                  has_created_summary={userData.has_created_summary}
                  has_created_exam={userData.has_created_exam}
                />
              )}
              <RectanglesHome days={userData.streak_days} allPointsData={allPointsData} />
            </>
          )}*/}
          <Tasks accessToken={accessToken} user={user} userData={userData} />

        </div>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: '20px', padding: '0 32px', marginTop: '30px' }}
          >
            <Typography
              style={{
                color: '#000000',
                fontSize: '24px',
                fontStyle: 'normal',
                fontFamily: 'Fira Sans',
                fontWeight: "bold",
                letterSpacing: '-0.225px',
              }}
            >
              Tus documentos
            </Typography>
            <Box display="flex" flexDirection="row" gap="10px">
              {userData?.plan_name !== "External" && (
                <UploadButton onClick={handleUploadFile} />
              )}

              <Box>
                <Button
                  startIcon={<SchoolIcon />}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  aria-controls="study-menu"
                  aria-haspopup="true"
                  sx={{
                    textTransform: "none",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor: "#6BBDBD",
                    color: "white",
                    fontFamily: "'Fira Sans', sans-serif",
                    fontWeight: "bold",
                    fontSize: "14px",
                    borderRadius: "20px",
                    '&:hover': {
                      backgroundColor: '#6BBDBD',
                    },
                  }}
                  endIcon={
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  Estudiar
                </Button>

                <Menu
                  id="study-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    style: {
                      marginTop: '8px',
                      borderRadius: '12px',
                      minWidth: '200px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  style={{
                    marginTop: '8px'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate('/new-exam');
                      setAnchorEl(null);
                    }}
                    style={{
                      padding: '12px 16px',
                      fontSize: '14px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <QuizIcon style={{ color: '#666' }} />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Hacer test</span>
                        <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Ponte a prueba con un test</span>
                      </div>
                    </div>
                  </MenuItem>
                  <Divider style={{ margin: '4px 0' }} />
                  <Tooltip title={isBasicPlan ? "Funcionalidad solo para premium" : ""}>
                    <div>
                      <MenuItem
                        onClick={() => {
                          if (!isBasicPlan) {
                            navigate('/new-flashcards', { state: { reset: true }, replace: true });
                          }
                          setAnchorElCollapsed(null);
                        }}
                        style={{
                          padding: '12px 16px',
                          fontSize: '14px',
                          cursor: isBasicPlan ? 'default' : 'pointer', // Disable click for Basic plans
                          backgroundColor: isBasicPlan ? '#f0f0f0' : 'inherit', // Light gray background for Basic plans
                          position: 'relative', // Position relative for lock icon placement
                        }}
                        disabled={isBasicPlan} // Disable the MenuItem for Basic plans
                      >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                          <PsychologyIcon style={{ color: '#666' }} />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Memorizar</span>
                            <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Responde a flashcards</span>
                          </div>
                          {isBasicPlan && (
                            <LockIcon style={{
                              color: '#666',
                              position: 'absolute',
                              top: '8px',
                              right: '8px',
                              fontSize: '16px' // Smaller size for the lock icon
                            }} />
                          )}
                        </div>
                      </MenuItem>
                    </div>
                  </Tooltip>
                </Menu>
              </Box>

            </Box>

          </Box>
          <Box sx={{
            marginTop: '20px',
            marginBottom: '80px',
            backgroundColor: 'white',
            border: "none",
            borderRadius: '20px',
            overflowY: 'auto',
            // height: '100vh',
            // fontFamily: "'Inter', sans-serif",
          }}>
            {isLoading ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '32px',
                height: '100%',
              }}>
                {[...Array(3)].map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '16px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: '#E0E0E0',
                        marginRight: '20px',
                      }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Box
                        sx={{
                          width: '60%',
                          height: '20px',
                          backgroundColor: '#E0E0E0',
                          marginBottom: '8px',
                        }}
                      />
                      <Box
                        sx={{
                          width: '40%',
                          height: '16px',
                          backgroundColor: '#E0E0E0',
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : listDocuments?.length > 2 ? (
              <DocumentList
                listDocuments={listDocuments}
                isLoading={isLoading}
                selectable={false}
                box={false}
                setShowBlockedDocument={setShowBlockedDocument}
                accessToken={accessToken}
              />
            ) : listDocuments?.length >= 1 ? (
              <>
                <DocumentList
                  listDocuments={listDocuments}
                  isLoading={isLoading}
                  selectable={false}
                  box={false}
                  setShowBlockedDocument={setShowBlockedDocument}
                  accessToken={accessToken}
                />
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '32px',
                  height: '100%',
                }}>
                  <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" style={{ maxWidth: '100%', height: 'auto' }} />

                  <Typography sx={{ mt: 2, color: '#666', fontFamily: 'inherit' }}>
                    Sube más documentos a estudiar
                  </Typography>
                </Box>
              </>
            ) : (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '32px',
                height: '100%',
              }}>
                <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" style={{ maxWidth: '100%', height: 'auto' }} />
                {userData?.plan_name === "External" ? (
                  <Typography sx={{ mt: 2, color: '#666', fontFamily: 'inherit' }}>
                    Aquí aparecerán tus documentos.
                  </Typography>
                ) : (
                  <Typography sx={{ mt: 2, color: '#666', fontFamily: 'inherit' }}>
                    Sube tus propios documentos para empezar a estudiar
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box >
    </>
  );
};

export default Progress;
