import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { keyframes } from '@mui/system';

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
`;

const premiumPlan = {
  features: [
    { text: 'Documentos ilimitados', included: true },
    { text: 'Resúmenes y esquemas', included: true },
    { text: 'Preguntas desbloqueadas', included: true },
    { text: 'Tarjetas didácticas', included: true },
  ],
  buttonText: '¡USAR CÓDIGO!',
};

const WelcomeBanner = ({ onClose, userData }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [copyMessage, setCopyMessage] = useState('');

  const handleCopyCode = () => {
    if (userData?.promotion_code) {
      navigator.clipboard.writeText(userData.promotion_code).then(() => {
        setCopyMessage('Código copiado');
        setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
      });
    }
  };

  useEffect(() => {
    const getDeadline = () => {
      if (userData && userData.start_date) {
        const startDate = new Date(userData.start_date);
        const deadline = new Date(startDate);
        deadline.setDate(startDate.getDate() + 3);
        return deadline;
      }
      return null;
    };

    const calculateTimeLeft = () => {
      const now = new Date();
      const deadline = getDeadline();
      if (deadline) {
        const difference = deadline - now;
        if (difference > 0) {
          setTimeLeft({
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          });
        } else {
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [userData]);

  return (
    <Box sx={{ position: 'relative', mb: 2 }}>
      {copyMessage && (
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: '-40px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#6BBDBD',
            fontSize: '0.85rem',
            backgroundColor: '#ffffff',
            padding: '4px 8px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          {copyMessage}
        </Typography>
      )}
      <Box sx={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '16px',
        p: 2,
        position: 'relative',
        border: '2px solid #E2F1F1',
        userSelect: 'text', // Allow text selection
      }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, alignItems: 'center' }}>
          {/* Left section with title and description */}
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: '"Fira Sans", sans-serif',
                fontWeight: 'bold',
                fontSize: '1.25rem',
                mb: 1,
                '& span': {
                  display: 'inline-block',
                  animation: `${bounce} 2s infinite ease-in-out`,
                }
              }}
            >
              <span>🎁</span> Descuento de bienvenida
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontFamily: '"Fira Sans", sans-serif',
                color: '#666666',
                mb: 1,
                fontSize: '0.95rem',
                lineHeight: 1.5
              }}
            >
              Usa <strong onClick={handleCopyCode} style={{ cursor: 'pointer', textDecoration: 'none' }}>{userData?.promotion_code}</strong> y obtén un <strong>15% de descuento</strong> en los planes Premium.
            </Typography>
          </Box>

          {/* Right section with timer */}
          <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1
          }}>
            <Box sx={{
              display: 'flex',
              gap: 1,
            }}>
              {[
                { value: timeLeft.days, label: 'días' },
                { value: timeLeft.hours, label: 'horas' },
                { value: timeLeft.minutes, label: 'min' },
                { value: timeLeft.seconds, label: 'seg' }
              ].map((item, index) => (
                <Box key={index} sx={{
                  backgroundColor: 'rgba(107, 189, 189, 0.08)',
                  borderRadius: '8px',
                  padding: '6px 8px',
                  minWidth: '50px',
                  textAlign: 'center'
                }}>
                  <Typography sx={{ fontWeight: 'bold', color: '#6BBDBD', fontSize: '1.25rem' }}>
                    {String(item.value).padStart(2, '0')}
                  </Typography>
                  <Typography sx={{ fontSize: '0.75rem', color: '#666666' }}>
                    {item.label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WelcomeBanner; 