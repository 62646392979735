import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  CircularProgress,
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getCsrfToken } from '../../Api/TokenApi'
import { useAuth } from "../../Auth/Authhandle";
import { useSubscription } from "../../Context/SubscriptionContext";
const REACT_APP_STRIPE_LOADER = process.env.REACT_APP_STRIPE_LOADER;
const stripePromise = loadStripe(REACT_APP_STRIPE_LOADER);

const pricingPlans = [
  {
    title: 'Free',
    prices: {
      monthly: '€0',
      semestral: '€0',
      annual: '€0',
    },
    period: '/mes',
    message: 'Perfecto para probar la plataforma',
    planIds: {
      monthly: process.env.REACT_APP_FREE_PLAN_ID,
    },
    features: [
      { text: 'Máximo 3 documentos ', included: true },
      { text: '40 páginas por documento', included: true },
      { text: '250 créditos para estudiar', included: true },
      { text: 'Resúmenes y esquemas', included: true },
      { text: 'Descarga resúmenes', included: false },
      { text: 'Todas las preguntas desbloqueadas', included: false },
      { text: 'Test de más de un documento', included: false },
      { text: 'Todas las flashcards desbloqueadas', included: false },
    ],
    buttonText: 'Empezar gratis',
    isPopular: false,
  },
  {
    title: 'Basic',
    prices: {
      monthly: '€8.99',
      semestral: '€7.2',
      annual: '€5.4',
    },
    planIds: {
      monthly: process.env.REACT_APP_BASIC_MENSUAL_PLAN_ID,
      semestral: process.env.REACT_APP_BASIC_SEMESTRAL_PLAN_ID,
      annual: process.env.REACT_APP_BASIC_ANUAL_PLAN_ID,
    },
    period: '/mes',
    message: 'Plan gratuito más: ',
    features: [
      { text: '200 páginas por documento', included: true },
      { text: 'Créditos ilimitados', included: true },
      { text: 'Todas las preguntas desbloqueadas', included: true },
      { text: 'Descarga resúmenes', included: true },
      { text: 'Generación de preguntas extras', included: false },
      { text: 'Tests de más de un documento', included: false },
      { text: 'Sube más de 20 documentos', included: false },
      { text: 'Flashcards para memorizar', included: false },
    ],
    buttonText: 'ELEGIR PLAN',
    isPopular: false,
  },
  {
    title: 'Premium',
    prices: {
      monthly: '€11.99',
      semestral: '€9.6',
      annual: '€7.2',
    },
    planIds: {
      monthly: process.env.REACT_APP_PREMIUM_MENSUAL_PLAN_ID,
      semestral: process.env.REACT_APP_PREMIUM_SEMESTRAL_PLAN_ID,
      annual: process.env.REACT_APP_PREMIUM_ANUAL_PLAN_ID,
    },
    period: '/mes',
    message: 'Todo incluido: ',
    features: [
      { text: 'Flashcards para memorizar', included: true },
      { text: 'Todas las preguntas desbloqueadas', included: true },
      { text: 'Sube más de 20 documentos', included: true },
      { text: 'Tests de más de un documento', included: true },
      { text: 'Generación de preguntas extras', included: true },
      { text: '200 páginas por documento', included: true },
      { text: 'Créditos ilimitados', included: true },
      { text: 'Descarga resúmenes', included: true },
    ],
    buttonText: 'ELEGIR PLAN',
    isPopular: true,
  },
];
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PricingPage = () => {
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const { userData } = useSubscription();
  const { accessToken, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  // Map userData.plan_name to the correct plan name in your JSON
  const planNameMapping = {
    "Free": "Free",
    "Basic-Monthly": "Basic-monthly",
    "Basic-Semestral": "Basic-semestral",
    "Basic-Anual": "Basic-annual",
    "Premium-Monthly": "Premium-monthly",
    "Premium-Semestral": "Premium-semestral",
    "Premium-Anual": "Premium-annual"
  };

  const currentPlanName = planNameMapping[userData.plan_name] || "Unknown-Unknown";
  const [currentPlanType, currentBillingPeriod] = currentPlanName.split('-');

  // Check if currentPlanType or currentBillingPeriod is undefined
  if (!currentPlanType || !currentBillingPeriod) {
    console.error('Invalid plan name:', userData.plan_name, currentPlanName, currentPlanType, currentBillingPeriod);
    // Handle the error, e.g., set a default plan or show an error message
  }
  console.log("currentPlanName", currentPlanName)
  console.log("currentPlanType", currentPlanType, "plan", userData.plan_name)
  console.log("currentBillingPeriod", currentBillingPeriod, "billingPeriod", billingPeriod)

  // Extract cancellation date from userData
  const cancellationDate = userData.cancellation_date;

  const handlePlanSelected = (plan, billingPeriod) => {
    if (currentPlanType !== "Free" && (plan.title !== currentPlanType || billingPeriod !== currentBillingPeriod)) {
      setSelectedPlan({ plan, billingPeriod });
      setIsModalOpen(true);
    } else {
      proceedWithPlanChange(plan, billingPeriod);
    }
  };

  const proceedWithPlanChange = async (plan, billingPeriod) => {
    setIsLoading(true);
    const planChosen = plan.planIds[billingPeriod];
    console.log("planChosen", planChosen)
    console.log("currentPlanName", currentPlanName)
    console.log("selectedPlan", plan)
    console.log("billingPeriod", billingPeriod)
    if (!planChosen) {
      alert("Invalid plan selection");
      return;
    }

    if (plan.title === currentPlanType && billingPeriod === currentBillingPeriod) {
      alert("Ya tienes esta suscripción");
      return;
    }

    try {
      const csrfToken = getCsrfToken(); // Get CSRF token for non-GET requests
      // Call your backend to create a checkout session
      const response = await fetch(`${BACKEND_URL}/create-checkout-session/?user_id=${user['sub']}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
          'Authorization': `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({ "priceId": planChosen }), // Use the selected plan ID
      });

      const result = await response.json();

      // When the backend returns the session ID, redirect to Stripe checkout
      if (response.ok && (result.subscriptionUpdated || result.subscriptionCreated)) {
        if (plan.title !== "Free") {
          window.location.replace("/");
        }
      } else if (response.ok && result.sessionId) {
        // When the backend returns a session ID for a new checkout, redirect to Stripe checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: result.sessionId,
        });
        if (error) {
          console.error('Stripe Checkout error:', error.message);
          setIsLoading(false);
        }
      } else {
        // Handle any errors or unexpected responses
        console.error('Unexpected response:', result);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Failed to start the checkout process:', error);
      setIsLoading(false);
    }

    // Simulate a successful change
    setTimeout(() => {
      setIsLoading(false);
      setIsSuccess(true);
    }, 2000);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsSuccess(false);
  };

  return (
    <Box sx={{ py: 4, px: 2, maxWidth: 1200, mx: 'auto', position: 'relative' }}>
      {cancellationDate && (
        <Typography
          variant="body2"
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            backgroundColor: '#f8d7da',
            color: '#721c24',
            padding: '8px 16px',
            borderRadius: '8px',
            fontFamily: '"Fira Sans", sans-serif',
          }}
        >
          Se cancela el: {new Date(cancellationDate).toLocaleDateString()}
        </Typography>
      )}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontFamily: '"Fira Sans", sans-serif',
            fontWeight: 'bold'
          }}
        >
          Planes y Precios
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{
            maxWidth: 600,
            mx: 'auto',
            fontFamily: '"Fira Sans", sans-serif',
            mb: 4
          }}
        >
          Elige el plan que mejor se adapte a tus necesidades de estudio
        </Typography>

        <ToggleButtonGroup
          value={billingPeriod}
          exclusive
          onChange={(event, newPeriod) => {
            if (newPeriod !== null) {
              setBillingPeriod(newPeriod);
            }
          }}
          aria-label="periodo de facturación"
          sx={{
            mb: 2,
            backgroundColor: '#f5f5f5',
            padding: '4px',
            borderRadius: '12px',
            '& .MuiToggleButton-root': {
              fontFamily: '"Fira Sans", sans-serif',
              px: 3,
              py: 0.5,
              fontSize: '0.875rem',
              textTransform: 'none',
              border: 'none',
              borderRadius: '8px !important',
              '&.Mui-selected': {
                backgroundColor: '#6BBDBD',
                color: 'white',
                borderRadius: '8px !important',
                '&:hover': {
                  backgroundColor: '#5aa9a9',
                }
              },
              '&:hover': {
                backgroundColor: '#e0e0e0',
              }
            }
          }}
        >
          <ToggleButton value="monthly">
            Mensual
          </ToggleButton>
          <ToggleButton value="semestral">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              Semestral
              <Typography
                sx={{
                  backgroundColor: '#E2F1F1',
                  color: '#6BBDBD',
                  fontSize: '0.6rem',
                  padding: '2px 6px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                }}
              >
                -20%
              </Typography>
            </Box>
          </ToggleButton>
          <ToggleButton value="annual">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              Anual
              <Typography
                sx={{
                  backgroundColor: '#E2F1F1',
                  color: '#6BBDBD',
                  fontSize: '0.6rem',
                  padding: '2px 6px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                }}
              >
                -40%
              </Typography>
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        {pricingPlans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={plan.title}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                backgroundColor: index === pricingPlans.length - 1 ? '#6BBDBD' : 'background.paper',
                borderRadius: '16px',
                minHeight: '520px',
                border: index === pricingPlans.length - 1 ? 'none' : '2px solid #E2F1F1',
                '& .MuiTypography-root': {
                  color: index === pricingPlans.length - 1 ? 'white' : 'inherit'
                },
                '& .MuiListItemText-root': {
                  color: index === pricingPlans.length - 1 ? 'white' : 'inherit'
                }
              }}
            >
              {plan.isPopular && (
                <Typography
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    backgroundColor: 'white',
                    color: '#6BBDBD !important',
                    px: 2,
                    py: 0.75,
                    borderRadius: 1,
                    fontFamily: '"Fira Sans", sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    zIndex: 1
                  }}
                >
                  Más Popular
                </Typography>
              )}
              <CardContent sx={{ p: 3 }}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  sx={{ fontFamily: '"Fira Sans", sans-serif' }}
                >
                  {plan.title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 3 }}>
                  <Typography
                    variant="h3"
                    component="span"
                    sx={{
                      fontFamily: '"Fira Sans", sans-serif',
                      fontWeight: 'bold',
                      fontSize: '2.5rem'
                    }}
                  >
                    {plan.prices[billingPeriod]}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: '"Fira Sans", sans-serif',
                      ml: 1
                    }}
                  >
                    {plan.period}
                  </Typography>
                  {(billingPeriod === 'semestral' || billingPeriod === 'annual') && (
                    <Typography
                      sx={{
                        ml: 2,
                        backgroundColor: '#E2F1F1',
                        color: '#6BBDBD !important',
                        fontSize: '0.75rem',
                        padding: '4px 8px',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                      }}
                    >
                      Te ahorras {billingPeriod === 'semestral'
                        ? `€${((parseFloat(plan.prices.monthly.replace('€', '').replace(',', '.')) -
                          parseFloat(plan.prices.semestral.replace('€', '').replace(',', '.'))) * 6).toFixed(2)}`
                        : `€${((parseFloat(plan.prices.monthly.replace('€', '').replace(',', '.')) -
                          parseFloat(plan.prices.annual.replace('€', '').replace(',', '.'))) * 12).toFixed(2)}`
                      }
                    </Typography>
                  )}
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: '"Fira Sans", sans-serif',
                    mb: 2,
                    fontStyle: 'italic',
                    opacity: 0.9
                  }}
                >
                  {plan.message}
                </Typography>
                <List sx={{ py: 0 }}>
                  {plan.features.map((feature, index) => (
                    <ListItem key={index} sx={{ py: 0.25 }}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        {feature.included ? (
                          <CheckIcon color="success" fontSize="small" />
                        ) : (
                          <CloseIcon color="error" fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={feature.text}
                        sx={{
                          '& .MuiTypography-root': {
                            fontFamily: '"Fira Sans", sans-serif',
                            fontSize: '0.875rem'
                          }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <CardActions sx={{
                mt: 'auto',
                p: 2,
                pt: 0,
                justifyContent: 'center'
              }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => handlePlanSelected(plan, billingPeriod)}
                  disabled={(plan.title === currentPlanType && currentPlanType == "Free") || (plan.title === currentPlanType && billingPeriod === currentBillingPeriod)}
                  sx={{
                    backgroundColor: (plan.title === currentPlanType && currentPlanType == "Free") || (plan.title === currentPlanType && billingPeriod === currentBillingPeriod) ? '#ccc' : (index === pricingPlans.length - 1 ? 'white' : '#6BBDBD'),
                    color: (plan.title === currentPlanType && currentPlanType == "Free") || (plan.title === currentPlanType && billingPeriod === currentBillingPeriod) ? '#666' : (index === pricingPlans.length - 1 ? '#6BBDBD' : 'white'),
                    '&:hover': {
                      backgroundColor: (plan.title === currentPlanType && currentPlanType == "Free") || (plan.title === currentPlanType && billingPeriod === currentBillingPeriod) ? '#ccc' : (index === pricingPlans.length - 1 ? '#f5f5f5' : '#5aa9a9'),
                    },
                    fontFamily: '"Fira Sans", sans-serif',
                    px: 4,
                    borderRadius: '20px',
                  }}
                >
                  {((plan.title === currentPlanType && currentPlanType == "Free") || (plan.title === currentPlanType && billingPeriod === currentBillingPeriod)) ? 'Plan Actual' : plan.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="confirm-plan-change"
        aria-describedby="confirm-plan-change-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : isSuccess ? (
            <Typography sx={{ mb: 2 }}>
              Se ha cambiado tu suscripción a <strong>{selectedPlan?.plan.title}</strong>.
            </Typography>
          ) : (
            <>
              <Typography
                id="confirm-plan-change"
                variant="h6"
                component="h2"
                sx={{ mb: 2, fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                Cambiar Plan
              </Typography>
              <Typography sx={{ mb: 2 }}>
                ¿Estás seguro de que quieres cambiar a plan <strong>{selectedPlan?.plan.title}</strong>?
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>

                <Button
                  variant="outlined"
                  onClick={handleModalClose}
                  sx={{
                    backgroundColor: 'transparent',
                    color: '#f44336',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Inter', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 24px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    border: '2px solid #f44336',
                    '&:hover': {
                      backgroundColor: '#f44336',
                      color: 'white',
                    },
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => proceedWithPlanChange(selectedPlan.plan, selectedPlan.billingPeriod)}
                  sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Inter', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 24px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#FCAB32',
                    },
                  }}
                >
                  Continuar
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default PricingPage; 