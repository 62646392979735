import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import DocumentListFolder from "../Documents/AllListDocumentsFolder";

function FolderContent({
    listDocuments,
    folder,
    updateListDocuments,
    accessToken,
    user,
    setShowPremium,
    isLoading
}) {
    const LoadingSkeleton = () => (
        <Box p={2}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} mb={2}>
                    <Skeleton variant="rect" height={60} animation="wave" />
                </Box>
            ))}
        </Box>
    );

    return (
        <Box style={{
            marginTop: '20px',
            marginBottom: '80px',
            backgroundColor: 'white',
            borderRadius: '20px'
        }}>
            {isLoading ? (
                <LoadingSkeleton />
            ) : (
                <DocumentListFolder
                    listDocuments={listDocuments}
                    isLoading={isLoading}
                    selectedDocument={null}
                    onDocumentSelect={() => { }}
                    selectable={false}
                    box={true}
                    token={accessToken}
                    user={user}
                    updateListDocuments={updateListDocuments}
                    setShowPremium={setShowPremium}
                />
            )}
        </Box>
    );
}

export default FolderContent;
