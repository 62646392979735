import React, { useState, useEffect, useRef } from "react";
import * as handle from "./handleLayout";
import {
  Paper, List, ListItem, ListItemText, Typography, Tooltip, CircularProgress
} from '@material-ui/core';
import { PDFDocument } from 'pdf-lib';
import { useSubscription } from "../../Context/SubscriptionContext";
import { useTranslation } from 'react-i18next';
import useUploadFileChooseFolderStyles from "./Styles/useUploadFilesFolder";
import trash_icon from './SVG/trash_icon.svg';

const PAGE_CREDIT_PRICE = 0.375
const DragAndDrop = ({ setFiles, is_multiple, limit = 5, setPages, setNumDocuments, maxWidthError = "none", handleDeleteFileLoad }) => {
  const { t } = useTranslation();
  const classes = useUploadFileChooseFolderStyles();

  const [openSpinner, setOpenSpinner] = useState(false);
  const [openUploadedSpinner, setOpenUploadSpinner] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const { userData } = useSubscription();
  const [maxPagesMessage, setMaxPagesMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFiles(selectedFiles);
    if (setNumDocuments) {
      setNumDocuments(selectedFiles.length)
    }
    if (selectedFiles.length === 0) {
      setPages(0)

    }
  }, [selectedFiles]);

  useEffect(() => {
    const handleBeforeUnloadListener = (event) => {
      handle.handleBeforeUnload(event, openSpinner, openUploadedSpinner);
    };
    window.addEventListener("beforeunload", handleBeforeUnloadListener);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnloadListener);
    };
  }, [openSpinner, openUploadedSpinner]);

  window.onbeforeunload = (event) => {
    if (openSpinner || openUploadedSpinner) {
      return t("file_still_uploading");
    }
  };

  const openFileInput = () => {
    handle.openFileInput(fileInputRef);
  };

  const handleDragOver = (event) => {
    handle.handleDragOver(event);
  };

  const handleDeleteFile = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));


  };

  const MAX_FILE_SIZE = 300 * 1024 * 1024; // 300 MB in bytes

  const validateDuration = (file, onSuccess, onError) => {
    const url = URL.createObjectURL(file);
    const media = file.type.includes("audio") ? new Audio(url) : document.createElement("video");
    media.src = url;

    media.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      const durationInHours = media.duration / 3600;
      if (durationInHours > 2) {
        onError(t("over_limit_hours"));
      } else {
        onSuccess(file);
      }
    };

    media.onerror = () => {
      URL.revokeObjectURL(url);
      onError(t("error_loading_file"));
    };
  };

  let totalPdfPages = 0;
  let validFiles = [];
  let invalidFiles = [];
  let filesProcessed = 0;
  let filePageCounts = [];

  const handleFileSelection = (files) => {
    setIsLoading(true);
    validFiles = [];
    invalidFiles = [];
    filesProcessed = 0;
    filePageCounts = [];

    Array.from(files).forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        alert("El archivo es demasiado grande");
        invalidFiles.push(file);
        checkAllFilesProcessed(files.length);
      } else if (file.type.includes("audio") || file.type.includes("video")) {
        alert("No se permiten archivos de audio o video")
        // validateDuration(
        //   file,
        //   (validFile) => {
        //     validFiles.push({ file: validFile, size: validFile.size });
        //     checkAllFilesProcessed(files.length);
        //   },
        //   (errorMsg) => {
        //     alert(`File ${file.name}: ${errorMsg}`);
        //     invalidFiles.push(file);
        //     checkAllFilesProcessed(files.length);
        //   }
        // );
      } else if (file.type === "application/pdf") {

        processPDF(file).then(() => checkAllFilesProcessed(files.length));
      } else if (file.type !== "application/pdf") {

        alert("Solo se permiten archivos PDF")
      }
      else {
        validFiles.push({ file, size: file.size });
        checkAllFilesProcessed(files.length);
      }
    });
  };

  const checkAllFilesProcessed = (totalFiles) => {
    filesProcessed++;
    if (filesProcessed === totalFiles) {
      const existingSelectedFilesPages = selectedFiles.reduce((acc, fileInfo) => acc + fileInfo.pages, 0);
      const processedTotalPages = filePageCounts.reduce((acc, pages) => acc + pages, 0);
      const totalCombinedPages = existingSelectedFilesPages + userData.pages + processedTotalPages;

      if (userData && totalCombinedPages > userData.maxPages) {
        alert("Has superado el límite de páginas");
        validFiles = [];
      } else {
        totalPdfPages += processedTotalPages;
        processFiles(validFiles);
      }
      filePageCounts = [];
      setIsLoading(false);
    }
  };

  const processPDF = async (file) => {
    try {
      const pdfDoc = await PDFDocument.load(await file.arrayBuffer(), { ignoreEncryption: true });
      const numberOfPages = pdfDoc.getPageCount();
      let filename = file.name
      if (numberOfPages > 200 && userData.plan_name != "Free") {
        // Create a new PDF with only the first 75 pages
        const newPdfDoc = await PDFDocument.create();
        const copiedPages = await newPdfDoc.copyPages(pdfDoc, Array.from(Array(200).keys()));
        copiedPages.forEach(page => newPdfDoc.addPage(page));

        // Convert the new PDF to a Blob
        const pdfBytes = await newPdfDoc.save();

        // Create a new file name
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts.pop();
        const fileName = fileNameParts.join('.');
        const newFileName = `${fileName}_1-200.${fileExtension}`;

        // Create a new File object with the modified name
        file = new File([new Blob([pdfBytes], { type: 'application/pdf' })], newFileName, { type: 'application/pdf' });
        let numberOfPages = 200;

        // Inform the user that the document was cut
        setMaxPagesMessage("Se generará el material de estudio sobre las primeras 200 páginas");
        validFiles.push({ file, size: file.size, pages: numberOfPages, original_pages: pdfDoc.getPageCount() });
        filePageCounts.push(numberOfPages);

      } else if (numberOfPages > 40 && userData.plan_name == "Free") {
        const newPdfDoc = await PDFDocument.create();
        const copiedPages = await newPdfDoc.copyPages(pdfDoc, Array.from(Array(40).keys()));
        copiedPages.forEach(page => newPdfDoc.addPage(page));

        // Convert the new PDF to a Blob
        const pdfBytes = await newPdfDoc.save();

        // Create a new file name
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts.pop();
        const fileName = fileNameParts.join('.');
        const newFileName = `${fileName}_1-40.${fileExtension}`;

        // Create a new File object with the modified name
        file = new File([new Blob([pdfBytes], { type: 'application/pdf' })], newFileName, { type: 'application/pdf' });
        let numberOfPages = 40;

        // Inform the user that the document was cut
        // setMaxPagesMessage("Se generará el material de estudio sobre las primeras 40 páginas. \n Puedes aumentar el límite mejorando tu plan.");
        validFiles.push({ file, size: file.size, pages: numberOfPages, original_pages: pdfDoc.getPageCount() });
        filePageCounts.push(numberOfPages);

      } else {
        validFiles.push({ file, size: file.size, pages: numberOfPages, original_pages: pdfDoc.getPageCount() });
        filePageCounts.push(numberOfPages);
        setErrorMessages([])
        if (setNumDocuments) {
          setNumDocuments(validFiles.length)
        }
        setPages(numberOfPages)
      }
    } catch (error) {
      console.error("Error reading PDF file:", error);
      alert("Error al leer el archivo PDF (Archivo protegido). Transforma el documento con ilovepdf.com");
      invalidFiles.push(file);
    }
  };

  const processFiles = (validFiles) => {
    const newFilesArray = validFiles.map(fileInfo => {
      const { file, size, pages, original_pages } = fileInfo;
      let formattedSize = size < 1024 * 1024 ? `${(size / 1024).toFixed(2)} KB` : `${(size / 1024 / 1024).toFixed(2)} MB`;
      return {
        file: file,
        size: formattedSize,
        pages: pages,
        original_pages: original_pages
      };
    });

    if (limit === 1) {
      setSelectedFiles(newFilesArray.slice(0, 1)); // Only keep the latest uploaded file
    } else {
      if (newFilesArray.length > 3 || selectedFiles.length === 3) {
        alert("Has subido más de 3 documentos.")
      } else {


        setSelectedFiles(prevFiles => {
          const updatedFiles = [...prevFiles, ...newFilesArray];
          // Limit the selected files to a maximum of 3
          return updatedFiles.slice(0, 3);
        });
      }
    }
  };

  return (
    <>
      <div
        style={{
          border: "2px dashed #D9D9D9",
          borderRadius: "10px",
          padding: "50px",
          cursor: "pointer",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",



        }}
        onClick={openFileInput}
        onDragOver={handleDragOver}
        onDrop={(event) => {
          event.preventDefault();
          const files = event.dataTransfer.files;
          if (files.length > 0) {
            handleFileSelection(files);
          }
        }}
      >

        <div>
          <Typography style={{ fontSize: "16px", fontWeight: 600, lineHeight: "26px", fontFamily: "Inter", color: "#505050" }}>
            Haz click para subir tus PDFs
          </Typography>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          accept=".pdf"
          style={{ display: "none" }}
          multiple={is_multiple}
          onChange={(event) => {
            const files = event.target.files;
            if (files.length > 0) {
              handleFileSelection(files);
            }
          }}
        />
      </div>
      <div><Typography style={{ fontSize: 10, color: "gray" }}>Máximo 3 ficheros </Typography></div>

      {selectedFiles.length > 0 && (
        <>
          <Typography style={{ marginTop: 10, fontFamily: "Inter", fontWeight: 600, fontSize: "14px" }}>
            Archivos seleccionados
          </Typography>
          <List component={Paper} className={classes.fileListContainerStyle} style={{
            '& .MuiPaper-root': {
              borderRadius: "0px !important",
            },
            borderRadius: "0px !important",
          }}>
            {selectedFiles.map((fileInfo, index) => (
              <ListItem key={index} className={classes.listItem}>
                <ListItemText
                  primary={
                    <Tooltip title={fileInfo.file.name} placement="top">
                      <span style={{ fontSize: "14px", fontFamily: "Inter" }}>
                        {fileInfo.file.name.length > 20 ? fileInfo.file.name.substring(0, 20) + '...' : fileInfo.file.name}
                      </span>
                    </Tooltip>
                  }
                />
                <img src={trash_icon} onClick={() => { handleDeleteFile(index); handleDeleteFileLoad() }} style={{ width: "20px" }} />
              </ListItem>
            ))}
          </List>
        </>
      )}

      <div style={{ width: maxWidthError }}>
        {maxPagesMessage &&
          <div style={{ color: 'red', fontSize: '14px', marginTop: '4px' }}>
            <Typography style={{ fontWeight: 600, color: "gray", fontSize: "12px" }}>{maxPagesMessage}</Typography>
          </div>}
      </div>

      {isLoading && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px'
        }}>
          <CircularProgress size={30} />
        </div>
      )}




    </>
  );
};

export default DragAndDrop;
