import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardContent, Typography, Box, Grid, Container,
  Accordion, AccordionSummary, AccordionDetails, Button, Skeleton, Chip, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@mui/material';


import { Tooltip } from '@mui/material';
import { useSubscription } from "../Context/SubscriptionContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStylesPreguntasDocument from './Styles/useStylePreguntasDocument';
import PreguntasDocumentPopup from './PreguntasDocumentPopup';
import Premium from '../PopUpsAlerts/Premium/Premium';
import LinearProgress from '@mui/material/LinearProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import { deleteTopic } from '../Api/ChaptersApi';
import preguntasbloquedas from './preguntasbloquedas.svg';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Slider from '@mui/material/Slider';
import RefreshIcon from '@mui/icons-material/Refresh';
import { fetchRegenerateQuestions, fetchGenerateQuestionsCount } from '../Api/FilesApi';

const useStylesDeleteChapter = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
  },
  dialog: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  content: {
    borderRadius: '20px !important',
    fontFamily: 'Inter, sans-serif !important',
  },
  actions: {
    justifyContent: 'space-between !important',
    fontFamily: 'Inter, sans-serif !important',
    marginTop: '15px !important',
    marginBottom: '10px !important',
  },
  dialogTitle: {
    '& .MuiTypography-root': {
      fontFamily: 'Inter, sans-serif !important',
      fontSize: '18px !important',
      fontWeight: 600,
      color: '#333 !important',
    },
  },
  deleteButton: {
    backgroundColor: '#D32F2F !important',
    color: 'white !important',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '16px !important',
    fontWeight: 600,
    textTransform: 'none !important',
    borderRadius: '20px !important',
    padding: '6px 16px !important',
    '&:hover': {
      backgroundColor: '#B71C1C !important',
    },
  },
  cancelButton: {
    backgroundColor: '#6BBDBD !important',
    color: 'white !important',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '16px !important',
    fontWeight: 600,
    textTransform: 'none !important',
    borderRadius: '20px !important',
    padding: '6px 16px !important',
    '&:hover': {
      backgroundColor: '#5AACAC !important',
    },
  },
}));

const SkeletonLoader = () => {
  const classes = useStylesPreguntasDocument();

  return (
    <Container className={classes.scrollableContainer}>
      {[1, 2, 3].map((_, index) => (
        <Accordion key={index} className={classes.accordionPreguntas}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            className={classes.accordionSummaryPreguntas}
          >
            <Skeleton variant="text" width="80%" height={30} />
          </AccordionSummary>
          <AccordionDetails>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}
const NewPreguntasDocument = ({ saving_questions = false, chapters_generating = false, loading, chapters = [], error, setQuestions, user, accessToken, max_pages, docInfo, regeneratingQuestions, setRegeneratingQuestions }) => {
  const classes = useStylesPreguntasDocument({ saving_questions });
  const deleteClasses = useStylesDeleteChapter();
  const { userData } = useSubscription();
  const { document_id, selectedDocumentParam } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [infoQuestion, setInfoQuestion] = useState(null);
  const [chapterTitle, setChapterTitle] = useState(null);
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [chapterToDelete, setChapterToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [expandedChapter, setExpandedChapter] = useState(null);
  const [visibleQuestions, setVisibleQuestions] = useState(4);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('New');
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(10);
  const [isGenerating, setIsGenerating] = useState(false);
  const [openRegenerateModal, setOpenRegenerateModal] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);


  useEffect(() => {
    const calculatedTotalQuestions = chapters.reduce((sum, chapter) =>
      sum + (chapter.questions ? chapter.questions.length : 0), 0
    );

    setTotalQuestions(calculatedTotalQuestions);
  }, [chapters]);
  const getPointsTextColor = (points) => {
    if (points === 0) return classes.sinEmpezarText;
    if (points >= 1 && points <= 39) return classes.wrongText;
    if (points >= 40 && points <= 79) return classes.learningText;
    return classes.memorizedText;
  };

  const getCircleColor = (points) => {
    if (points === 0) return '#A0A0A0';
    if (points >= 5) return '#00B564';
    if (points <= -3) return '#AA161D';
    if (points > -3 && points < 5) return '#FCAB32';
    return '#00B564';
  };

  const handleOpen = (question, chapterTitle) => {
    setOpen(true);
    setInfoQuestion(question);
    setChapterTitle(chapterTitle);
  };
  useEffect(() => {
    if (chapters_generating && saving_questions) {
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          const baseIncrement = 1.11; // Base increment per 500ms
          const randomVariation = 0.2; // Small random variation for natural movement
          const increment = baseIncrement + (Math.random() * randomVariation - randomVariation / 2);

          const newProgress = oldProgress + increment;
          if (newProgress >= 100) {
            return 0; // Reset to 0 when reaching 100%
          }
          return newProgress;
        });
      }, 500);

      return () => clearInterval(interval);
    }
  }, [saving_questions, chapters_generating]);

  const handleClose = () => setOpen(false);

  const handleShowPremium = (titleText) => {
    setShowPremium(true);
    setPremiumTitleText(titleText);
  };

  const handleUnlockQuestions = () => {
    handleShowPremium('questions');
  };

  const noShadowStyle = {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  };

  if (error) return <Typography color="error">{error}</Typography>;

  const cardStyle = {
    boxShadow: 'none',
    border: 'none',
    marginBottom: '8px', // Add some space between cards
  };

  const getChipClass = (points, max_points) => {
    if ((points / max_points) * 100 >= 80) return classes.memorizedChipPreguntas;
    if ((points / max_points) * 100 >= 40) return classes.learningChipPreguntas;
    if ((points / max_points) * 100 > 0) return classes.wrongChipPreguntas;
    if (points < 0) return classes.wrongChipPreguntas;
    return classes.sinEmpezarChipPreguntas;
  };

  const getChipLabel = (points, max_points) => {
    if ((points / max_points) * 100 >= 80) return " Memorizado";
    if ((points / max_points) * 100 >= 40) return "Aprendiendo";
    if ((points / max_points) * 100 > 0) return "Incorrectas";
    if (points < 0) return "Incorrectas";
    return "Sin Empezar";
  };

  // const getChapterPoints = (chapter) => {
  //   return chapter.subChapters.reduce((total, subChapter) => total + subChapter.points, 0);
  // };

  // const getChapterMaxPoints = (chapter) => {
  //   return chapter.subChapters.reduce((total, subChapter) => total + subChapter.max_points, 0);
  // };




  // Helper function to get point color
  const getPointColor = (points, maxPoints) => {
    const percentage = (points / maxPoints) * 100;
    if (percentage >= 80) return '#00B564';
    if (percentage >= 40) return '#FCAB32';
    if (percentage > 0) return '#AA161D';
    return points < 0 ? '#AA161D' : '#A0A0A0';
  };

  const LoadingMoreChapters = () => (
    <Box display="flex" justifyContent="center" alignItems="center" py={2}>
      <CircularProgress size={24} style={{ marginRight: 16 }} />
      <Typography variant="body2">Loading more chapters...</Typography>
    </Box>
  );

  const handleDeleteClick = (chapter) => {
    setChapterToDelete(chapter);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleting(true);
    deleteTopic(accessToken, document_id, chapterToDelete.guid, user)
      .then(() => {
        setQuestions(prevState => ({
          ...prevState,
          topics: prevState.topics.filter(topic => topic.guid !== chapterToDelete.guid)
        }));
        setOpenDeleteDialog(false);
        setChapterToDelete(null);
      })
      .catch(error => {
        console.error('Error deleting topic:', error);
        // Handle error (e.g., show error message to user)
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setChapterToDelete(null);
  };

  const handleAccordionChange = (chapterIndex) => (event, isExpanded) => {
    setExpandedChapter(isExpanded ? chapterIndex : null);
    setVisibleQuestions(6)
    if (!isExpanded) {
      setVisibleQuestions(6);
    }
  };

  const handleShowMoreQuestions = () => {
    setVisibleQuestions(prev => prev + 5);
  };

  // Instead, sort the chapters directly in the render method
  const sortedChapters = [...chapters].sort((a, b) => a.order - b.order);

  const getFilteredQuestions = () => {
    if (!chapters || chapters.length === 0) return [];

    // Flatten all questions from all chapters into a single array
    const allQuestions = chapters.reduce((acc, chapter) => {
      return [...acc, ...(chapter.questions || []).map(q => ({
        ...q,
        chapterTitle: chapter.title
      }))];
    }, []);
    switch (selectedFilter) {
      case 'New':
        return allQuestions.filter(q => q.points === 0 && q.question_answered === false);
      case 'Learning':
        return allQuestions.filter(q => q.points > 0 && q.points < 5 && q.question_answered === true);
      case 'Incorrect':
        return allQuestions.filter(q => q.points <= 0 && q.question_answered === true);
      case 'Correct':
        return allQuestions.filter(q => q.points >= 5 && q.question_answered === true);
      default:
        return allQuestions;
    }
  };

  const filteredQuestions = getFilteredQuestions();

  const getQuestionsCount = () => {
    if (!chapters || chapters.length === 0) return {
      new: 0,
      learning: 0,
      correct: 0,
      incorrect: 0
    };

    const allQuestions = chapters.reduce((acc, chapter) =>
      [...acc, ...(chapter.questions || [])], []
    );

    return {
      new: allQuestions.filter(q => q.points === 0 && q.question_answered === false).length,
      learning: allQuestions.filter(q => q.points > 0 && q.points < 5).length,
      correct: allQuestions.filter(q => q.points >= 5 && q.question_answered === true).length,
      incorrect: allQuestions.filter(q => q.points <= 0 && q.question_answered === true).length
    };
  };

  const questionCounts = getQuestionsCount();

  const filters = [
    { value: 'New', label: `Sin empezar (${questionCounts.new})`, color: '#A0A0A0' },
    { value: 'Learning', label: `Aprendiendo (${questionCounts.learning})`, color: '#FCAB32' },
    { value: 'Correct', label: `Memorizadas (${questionCounts.correct})`, color: '#00B564' },
    { value: 'Incorrect', label: `Incorrectas (${questionCounts.incorrect})`, color: '#AA161D' }
  ];

  const getBlockedQuestionsCount = () => {
    const totalQuestions = chapters.reduce((sum, chapter) =>
      sum + (chapter.questions ? chapter.questions.length : 0), 0
    );

    if (!totalQuestions) return 0;

    if (max_pages * 12 > totalQuestions && userData.plan_name === "Free")
      return max_pages * 12;

    return 0;
  };

  const blockedQuestionsCount = getBlockedQuestionsCount();

  const handleOpenGenerateModal = () => {
    setOpenGenerateModal(true);
  };

  const handleCloseGenerateModal = () => {
    setOpenGenerateModal(false);
    setNumberOfQuestions(10);
  };

  const handleGenerateMore = async () => {
    setIsGenerating(true);
    setRegeneratingQuestions(true);
    docInfo.added_more_questions_done = true;

    try {
      await fetchGenerateQuestionsCount(accessToken, document_id, numberOfQuestions, user);
    } catch (error) {
      console.error('Error generating questions:', error);
      // Optionally handle error (show error message, etc.)
    } finally {
      setIsGenerating(false);
      handleCloseGenerateModal();
      // Don't set regeneratingQuestions to false here - it should be controlled by the parent component
      // or when the actual regeneration process is complete
    }
  };

  const handleOpenRegenerateModal = () => {
    setOpenRegenerateModal(true);
  };



  const handleCloseRegenerateModal = () => {
    setOpenRegenerateModal(false);
  };



  const handleRegenerate = () => {
    setIsRegenerating(true);
    fetchRegenerateQuestions(accessToken, document_id, user)
    // Here you would add the API call to regenerate questions
    // For now, just closing the modal
    handleCloseRegenerateModal();
    setIsRegenerating(false);
    docInfo.regenerate_questions_done = true;
  };

  // const handleRegenerateFlashcards = () => {
  //   setIsRegenerating(true);
  //   fetchRegenerateFlashcards(accessToken, document_id, user)
  //   // Here you would add the API call to regenerate flashcards
  //   // For now, just closing the modal
  //   handleCloseRegenerateFlashcardsModal();
  //   setIsRegenerating(false);
  //   docInfo.regenerate_flashcards_done = true;
  // };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      {showPremium && (
        <Premium
          onClose={() => setShowPremium(false)}
          titleText={premiumTitleText}
        />
      )}

      {open && <PreguntasDocumentPopup
        open={open}
        onClose={() => setOpen(false)}
        infoQuestion={infoQuestion}
        chapterTitle={chapterTitle}
      />}
      {saving_questions && (
        <Box
          sx={{
            backgroundColor: '#F5F5F5',
            border: '1px dashed #E0E0E0',
            borderRadius: '10px',
            // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '24px',
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: "0px 30px",
            // maxWidth: '500px',
            width: '90%',
            flexShrink: 0
          }}
        >

          {!chapters_generating ? (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: '#333',
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  fontFamily: "Inter"
                }}
              >
                Generando preguntas
              </Typography>
              <Typography
                sx={{
                  color: '#666',
                  marginBottom: '16px',
                  textAlign: 'center',
                  fontFamily: "Inter"
                }}
              >
                Las preguntas irán apareciendo a medida que estén listas
              </Typography>
              <Box sx={{ width: '50%', marginTop: '8px' }}>
                <LinearProgress
                  variant="determinate"
                  value={totalQuestions / (max_pages * (user.plan_name === "Free" ? 2 : 10)) * 100}



                  sx={{
                    height: '8px',
                    borderRadius: '4px',
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#4A90E2'
                    }
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                <Typography
                  variant="h6"
                  sx={{
                    color: '#333',
                    marginBottom: '8px',
                    fontWeight: 'bold',
                    fontFamily: "Inter"
                  }}
                >
                  Estamos detectando el texto
                </Typography>

                <Box sx={{
                  width: '50%',
                  height: '10px',
                  bgcolor: '#8db8c2',
                  borderRadius: '6px',
                  overflow: 'hidden'
                }}>
                  <Box sx={{
                    width: `${progress}%`,
                    height: '100%',
                    bgcolor: '#026277',
                    transition: 'width 0.5s ease'
                  }} />
                </Box>

              </Box>
            </>
          )}
        </Box>
      )}

      {regeneratingQuestions && (
        <Box sx={{
          backgroundColor: '#FFF9F0',
          border: '1px dashed #FFBF60',
          borderRadius: '10px',
          padding: '24px',
          marginBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: "0px 30px",
          width: '90%',
          flexShrink: 0
        }}>
          <Typography
            variant="h6"
            sx={{
              color: '#333',
              marginBottom: '8px',
              fontWeight: 'bold',
              fontFamily: "Inter"
            }}
          >
            Añadiendo preguntas
          </Typography>
          <Typography
            sx={{
              color: '#666',
              marginBottom: '16px',
              textAlign: 'center',
              fontFamily: "Inter"
            }}
          >
            Estamos añadiendo tus preguntas, aparecerán en breve
          </Typography>

        </Box>
      )}

      <Container className={`${classes.scrollableContainer} custom-scrollbar`}>
        {blockedQuestionsCount > 0 && (
          <Typography
            sx={{
              textDecoration: 'none',
              color: '#AA161D',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 600,
              paddingLeft: '25px',
              marginBottom: '16px',
              fontSize: '14px'
            }}
          >
            Suscríbete para desbloquear {blockedQuestionsCount} preguntas
          </Typography>
        )}

        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          paddingRight: '25px',
          paddingLeft: '25px',
          marginBottom: '16px'
        }}>
          {!docInfo.regenerate_questions_done && !saving_questions && !chapters_generating && !regeneratingQuestions && (
            <Button
              onClick={handleOpenRegenerateModal}
              startIcon={<RefreshIcon />}
              sx={{
                backgroundColor: '#6BBDBD',
                color: 'white',
                borderRadius: '20px',
                padding: '6px 16px',
                textTransform: 'none',
                fontFamily: 'Fira Sans',
                fontWeight: 600,
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: '#5aa9a9',
                },
              }}
            >
              Regenerar Preguntas
            </Button>
          )}

          {!userData?.plan_name?.startsWith("Basic") && userData?.credits_plan == -100 && !docInfo?.added_more_questions_done && !saving_questions && !chapters_generating && !regeneratingQuestions && (
            <Tooltip title="Generar más preguntas" arrow>
              <Button
                onClick={handleOpenGenerateModal}
                sx={{
                  minWidth: '40px',
                  width: '40px',
                  height: '40px',
                  backgroundColor: 'white',
                  border: '1px solid #6BBDBD',
                  color: '#6BBDBD',
                  borderRadius: '50%',
                  padding: 0,
                }}
              >
                <AddIcon fontSize="medium" />
              </Button>
            </Tooltip>
          )}
        </Box>

        <Box sx={{
          display: 'flex',
          gap: 1,
          mb: 2,
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          paddingLeft: '25px',
          paddingRight: '16px'
        }}>
          {filters.map((filter) => (
            <Chip
              key={filter.value}
              label={filter.label}
              onClick={() => setSelectedFilter(filter.value)}
              sx={{
                backgroundColor: selectedFilter === filter.value
                  ? (filter.value === 'New' ? '#A0A0A0' : filter.color) // Changed to gray for 'New'
                  : '#f5f5f5',
                color: selectedFilter === filter.value
                  ? (filter.value === 'New' ? '#FFFFFF' : 'white')
                  : (filter.value === 'New' ? '#4A4A4A' : 'black'), // Darker gray for non-selected 'New'
                '&:hover': {
                  backgroundColor: selectedFilter === filter.value
                    ? (filter.value === 'New' ? '#808080' : filter.color)
                    : '#e0e0e0',
                },
                fontFamily: 'Inter, sans-serif',
                fontWeight: 600,
                borderRadius: '20px',
                padding: '8px 12px',
              }}
            />
          ))}
        </Box>

        {filteredQuestions.slice(0, visibleQuestions).map((question) => (
          <Card
            variant="outlined"
            className={classes.cardPreguntas}
            key={question.id}
            style={{
              marginBottom: '16px',
              position: 'relative',
              backgroundColor: 'white',
              borderRadius: '8px',
              padding: '32px',
              marginLeft: '16px',
              marginRight: '32px',
              width: 'calc(100% - 48px)'
            }}
          >
            <CardContent style={{
              filter: !question.questionactive && userData.plan_name === "Free" ? 'blur(3px)' : 'none',
              transition: 'filter 0.3s ease-in-out',
              padding: '0',
              '&:last-child': {
                paddingBottom: 0
              }
            }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 3,
                    minHeight: '60px'
                  }}>
                    <Typography sx={{
                      fontFamily: "'Fira Sans', sans-serif",
                      fontWeight: 500,
                      fontSize: '14px',
                      color: '#2D2D2D',
                      flex: 1,
                      pr: 3,
                      lineHeight: '1.6'
                    }}>
                      {question.text}
                    </Typography>
                    <Button
                      onClick={() => handleOpen(question, question.chapterTitle)}
                      disabled={!question.questionactive && userData.plan_name === "Free"}
                      style={{
                        textTransform: 'none',
                        fontSize: '14px',
                        fontWeight: '600',
                        fontFamily: "'Inter', sans-serif",
                        color: 'white',
                        backgroundColor: '#6BBDBD',
                        padding: '6px 16px',
                        borderRadius: '24px',
                        boxShadow: 'none',
                        border: '1px solid #6BBDBD',
                        minWidth: 'fit-content',
                        alignSelf: 'center'
                      }}
                    >
                      Ver
                    </Button>
                  </Box>
                </Box>
              </Box>
            </CardContent>

            {userData.plan_name === "Free" && !question.questionactive && (
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                zIndex="1"
              >
                <Button
                  onClick={() => handleShowPremium('questions')}
                  style={{
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: '600',
                    fontFamily: "'Inter', sans-serif",
                    color: 'white',
                    backgroundColor: '#AA161D',
                    padding: '4px 16px',
                    borderRadius: '24px',
                    boxShadow: 'none',
                    width: 'auto'
                  }}
                >
                  <img
                    src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/LockWhite.svg"
                    alt="Lock"
                    style={{ width: 20, height: 20, marginRight: '8px' }}
                  />
                  Desbloquear
                </Button>
              </Box>
            )}
          </Card>
        ))}

        {visibleQuestions < filteredQuestions.length && (
          <Button
            onClick={handleShowMoreQuestions}
            style={{
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: '600',
              fontFamily: "'Inter', sans-serif",
              color: 'white',
              backgroundColor: '#6BBDBD',
              padding: '8px 16px',
              borderRadius: '24px',
              boxShadow: 'none',
              marginTop: '16px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            Mostrar más
          </Button>
        )}

        {!loading && filteredQuestions.length === 0 && (
          <Typography
            variant="body1"
            align="center"
            sx={{ mt: 4, color: '#666' }}
          >
            No hay preguntas que coincidan con el filtro seleccionado
          </Typography>
        )}
      </Container>

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        classes={{
          root: deleteClasses.dialog,
          paper: deleteClasses.content,
        }}
        BackdropProps={{
          classes: {
            root: deleteClasses.backdrop,
          },
        }}
      >
        <DialogTitle className={deleteClasses.dialogTitle}>
          🗑 Eliminar capítulo
        </DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: '10px' }}>
            ¿Estás seguro que quieres eliminar "<b>{chapterToDelete?.title}</b>"?
          </Typography>
          <Typography>
            Esta acción no se puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions className={deleteClasses.actions}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '40px', width: '100%' }}>
            <Button
              onClick={handleDeleteCancel}
              className={deleteClasses.cancelButton}
              disabled={isDeleting}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              className={deleteClasses.deleteButton}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <CircularProgress size={24} style={{ color: 'white' }} />
              ) : (
                'Eliminar'
              )}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openGenerateModal}
        onClose={handleCloseGenerateModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Generar más preguntas
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            Selecciona el número de preguntas adicionales que quieres generar
          </Typography>
        </DialogTitle>

        <DialogContent sx={{
          padding: "0",
          '&.MuiDialogContent-root': {
            paddingTop: '10px !important'
          }
        }}>
          <Box sx={{
            width: '100%',
            paddingRight: '8px'
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1
            }}>
              <Typography sx={{
                fontSize: '14px',
                fontFamily: 'Fira Sans',
                color: '#A0A0A0'
              }}>
                Número de preguntas: {numberOfQuestions}
              </Typography>
            </Box>
            <Slider
              value={numberOfQuestions}
              onChange={(_, newValue) => setNumberOfQuestions(newValue)}
              min={1}
              max={100}
              sx={{
                color: '#6BBDBD',
                '& .MuiSlider-thumb': {
                  height: 24,
                  width: 24,
                  backgroundColor: '#fff',
                  border: '2px solid #6BBDBD',
                  '&:hover, &.Mui-focusVisible': {
                    boxShadow: '0 0 0 8px rgba(107, 189, 189, 0.16)',
                  },
                },
                '& .MuiSlider-rail': {
                  backgroundColor: '#F0F4F4',
                  opacity: 1,
                },
                '& .MuiSlider-track': {
                  height: 4,
                },
                '& .MuiSlider-mark': {
                  backgroundColor: '#F0F4F4',
                  height: 8,
                  width: 1,
                  '&.MuiSlider-markActive': {
                    backgroundColor: '#6BBDBD',
                  },
                },
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleCloseGenerateModal}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleGenerateMore}
            disabled={isGenerating}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#5aa9a9'
              }
            }}
          >
            {isGenerating ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Generar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRegenerateModal}
        onClose={handleCloseRegenerateModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Regenerar preguntas
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#666666',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro que quieres regenerar las preguntas? Las preguntas actuales se perderán.
          </Typography>
        </DialogTitle>

        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleCloseRegenerateModal}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleRegenerate}
            disabled={isRegenerating}
            sx={{
              backgroundColor: '#FFBF60',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#FFBF60'
              }
            }}
          >
            {isRegenerating ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Regenerar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NewPreguntasDocument;
