import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell, LabelList } from 'recharts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import QuizIcon from '@mui/icons-material/Quiz';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import JustificationPopup from './JustificationPopup';
import { streamJustification } from './QdrantJustification';
import { useAuth } from "../Auth/Authhandle";
import { useCredits } from "../Context/CreditsContext";
import { useNavigate } from 'react-router-dom';
import { useSubscription } from "../Context/SubscriptionContext";
import WelcomePopup from '../AllModalsActions/WelcomePopup';

const SummaryContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: "800px",
  margin: '0 auto',
  padding: theme.spacing(2),
  fontFamily: "'Fira Sans', sans-serif",
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 22px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: '#6BBDBD',
  color: 'white',
  '&:hover': {
    backgroundColor: '#5AACAC',
  },
}));

const ScoreBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#6BBDBD',
  borderRadius: '12px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  fontFamily: "'Fira Sans', sans-serif",
}));

const ResultCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '8px',
  border: '1px solid #E2F1F1',
  boxShadow: 'none',
}));

const AnswerStatus = styled(Box)(({ correct }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 12px',
  borderRadius: '16px',
  backgroundColor: correct ? '#E8F5E9' : '#FFEBEE',
  color: correct ? '#4CAF50' : '#F44336',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: "'Fira Sans', sans-serif",
}));

const OptionBox = styled(Box)(({ isSelected, isCorrect }) => ({
  padding: '12px 16px',
  borderRadius: '8px',
  marginBottom: '8px',
  border: '1px solid #E2F1F1',
  backgroundColor: isCorrect ? '#E8F5E9' :
    isSelected && !isCorrect ? '#FFEBEE' :
      'transparent',
  color: isCorrect ? '#4CAF50' :
    isSelected && !isCorrect ? '#F44336' :
      '#666666',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontFamily: "'Inter', sans-serif",
  fontSize: '14px',
}));

const ExplanationButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '13px',
  fontWeight: 500,
  backgroundColor: '#F8F9FA',
  color: '#666666',
  border: '1px solid #E0E0E0',
  '&:hover': {
    backgroundColor: '#EAECEF',
  },
}));

const NumberChip = styled(Box)({
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: "'Fira Sans', sans-serif",
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const ScrollTopButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: '50%',
  minWidth: '48px',
  width: '48px',
  height: '48px',
  backgroundColor: '#6BBDBD',
  color: 'white',
  '&:hover': {
    backgroundColor: '#5AACAC',
  },
}));

const ExamCorrection = ({ examData, listScore }) => {
  const navigate = useNavigate();
  const { userData } = useSubscription();
  const { accessToken, user } = useAuth();
  const { updateCredits } = useCredits();
  const [isJustificationOpen, setIsJustificationOpen] = useState(false);
  const [justificationText, setJustificationText] = useState("");
  const [isLoadingJustification, setIsLoadingJustification] = useState(false);
  const [currentQuestionForJustification, setCurrentQuestionForJustification] = useState(null);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const totalQuestions = examData.questions.length;
  const correctAnswers = listScore.filter(score => score > 0).length;
  const incorrectAnswers = listScore.filter(score => score <= 0).length;

  const chartData = [
    { name: 'Correctas', value: correctAnswers, color: '#4CAF50' },
    { name: 'Incorrectas', value: incorrectAnswers, color: '#F44336' }
  ];
  const score = (examData.final_score / 10).toFixed(1);

  useEffect(() => {
    if (userData && userData.start_date && userData.plan_name === "Free") {
      const startDate = new Date(userData.start_date);
      const currentDate = new Date();
      const timeDifference = currentDate - startDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      if (daysDifference > 3) {
        setShowWelcomePopup(false);
      } else {
        setShowWelcomePopup(true);
      }
    }
  }, [userData]);

  const handleJustificationOpen = async (question) => {
    setCurrentQuestionForJustification(question);
    setIsJustificationOpen(true);

    const existingJustification = question.question_answers[0].justification;

    if (existingJustification) {
      setJustificationText(existingJustification);
      setIsLoadingJustification(false);
      return;
    }

    setIsLoadingJustification(true);
    setJustificationText("");

    try {
      await streamJustification(
        {
          question: question.question_statement,
          correctAnswer: question.question_correct_answer,
          userAnswer: question.question_answers[0].question_answer,
          isCorrect: question.question_answers[0].punctuation > 0,
          documentId: examData.document_guid,
          userId: user['sub'],
          questionAnswerId: question.question_answers[0].guid,
          typeJustification: "exam",
        },
        (word) => {
          setJustificationText(prev => prev + word);
        },
        user,
        accessToken
      );
      updateCredits();
    } catch (error) {
      console.error("Error fetching justification:", error);
      setJustificationText("Lo sentimos, no pudimos generar la justificación en este momento.");
    } finally {
      setIsLoadingJustification(false);
    }
  };

  const handleJustificationClose = () => {
    setIsJustificationOpen(false);
    setCurrentQuestionForJustification(null);
  };

  const handleScrollToTop = () => {
    document.querySelector('.custom-scrollbar-wide').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <SummaryContainer>

      {showWelcomePopup && <WelcomePopup
        open={true}
        userData={userData}
        onClose={() => setShowWelcomePopup(false)}
      />}
      <Box sx={{
        mb: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: '24px',
          fontFamily: "'Fira Sans', sans-serif",
        }}>
          Resultado del test
        </Typography>

        <Box sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap'
        }}>
          <ActionButton
            startIcon={<QuizIcon />}
            onClick={() => navigate('/new-exam')}
            sx={{
              backgroundColor: '#6BBDBD',
              '&:hover': {
                backgroundColor: '#5AACAC',
              },
            }}
          >
            Estudiar
          </ActionButton>
        </Box>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <ScoreBox>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
              <Box component="span" sx={{
                mr: 1,
                fontSize: '1.5rem'
              }}>
                📝
              </Box>
              <Typography sx={{
                fontSize: '20px',
                fontWeight: 500,
                fontFamily: "'Fira Sans', sans-serif",
              }}>
                Puntuación Final
              </Typography>
            </Box>
            <Typography sx={{
              fontSize: '48px',
              fontWeight: 'bold',
              mb: 1,
              fontFamily: "'Fira Sans', sans-serif",
            }}>
              {score}
            </Typography>
            <Typography sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '16px',
              mb: 2,
            }}>
              {correctAnswers} de {totalQuestions} respuestas correctas
            </Typography>
          </ScoreBox>
        </Grid>

        <Grid item xs={12} md={7}>
          <Box sx={{ height: 250 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                margin={{
                  top: 30,
                  right: 10,
                  left: 10,
                }}
                barGap={0}
              >
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666',
                    fontSize: 14,
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666',
                    fontSize: 14,
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                />
                <Bar
                  dataKey="value"
                  radius={[4, 4, 4, 4]}
                  barSize={40}
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                    />
                  ))}
                  <LabelList
                    dataKey="value"
                    position="top"
                    content={({ x, y, width, value }) => (
                      <text
                        x={x + width / 2}
                        y={y - 10}
                        fill="#666"
                        textAnchor="middle"
                        fontSize={16}
                        fontWeight={500}
                        fontFamily="'Fira Sans', sans-serif"
                      >
                        {value}
                      </text>
                    )}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>

      {userData?.plan_name === "Free" && (
        <Box sx={{
          textAlign: 'center',
          my: 3,
          p: 2,
          backgroundColor: '#F8F9FA',
          borderRadius: '12px',
          border: '2px dashed #CCCCCC'
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 600,
            mb: 1,
            fontFamily: "'Fira Sans', sans-serif",
            color: '#2D2D2D'
          }}>
            ¡Accede a todas las preguntas!
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#555',
            fontFamily: "'Fira Sans', sans-serif",
            mb: 1
          }}>
            Desbloquea acceso a todas las preguntas de tus documentos
          </Typography>
          <Button
            onClick={() => navigate('/plans')}
            sx={{
              mt: 1,
              fontFamily: "'Fira Sans', sans-serif",
              color: '#6BBDBD',
              textTransform: 'none',
              padding: "6px 22px",
              backgroundColor: 'white',
              border: '1px solid #6BBDBD',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            ¡Desbloquear!
          </Button>
        </Box>
      )}

      <Box sx={{ mt: 4 }}>
        <Typography sx={{
          mb: 2,
          fontSize: '20px',
          fontWeight: 500,
          fontFamily: "'Fira Sans', sans-serif",
        }}>
          Revisión de Preguntas
        </Typography>

        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          mb: 2,
          px: 1,
          py: 1,
          borderRadius: '12px',
        }}>
          {examData.questions.map((question, index) => {
            const isCorrect = question.question_answers[0].punctuation > 0;
            const colors = isCorrect
              ? { bg: '#E8F5E9', text: '#4CAF50' }
              : { bg: '#FFEBEE', text: '#F44336' };

            return (
              <NumberChip
                key={index}
                sx={{
                  backgroundColor: colors.bg,
                  color: colors.text,
                  border: `1px solid ${colors.text}20`,
                }}
                onClick={() => {
                  document.getElementById(`question-${index}`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  });
                }}
              >
                {index + 1}
              </NumberChip>
            );
          })}
        </Box>

        {examData.questions.map((question, index) => {
          const answer = question.question_answers[0];
          const isCorrect = answer.punctuation > 0;

          return (
            <ResultCard
              key={index}
              id={`question-${index}`}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Typography sx={{
                    fontFamily: "'Fira Sans', sans-serif",
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#2D2D2D',
                    flex: 1,
                  }}>
                    {index + 1}. {question.question_statement}
                  </Typography>
                  <AnswerStatus correct={isCorrect}>
                    {isCorrect ? 'Correcto' : 'Incorrecto'}
                  </AnswerStatus>
                </Box>

                <Box sx={{ pl: 2 }}>
                  {question.question_type === 'MC' ? (
                    // Multiple choice questions
                    question.question_options.map((option, optIndex) => (
                      <OptionBox
                        key={optIndex}
                        isSelected={option.text === answer.question_answer}
                        isCorrect={option.text === question.question_correct_answer}
                      >
                        {option.text === answer.question_answer && option.text !== question.question_correct_answer && (
                          <Box component="span" sx={{ color: '#F44336', mr: 1 }}>✗</Box>
                        )}
                        {option.text === question.question_correct_answer && (
                          <Box component="span" sx={{ color: '#4CAF50', mr: 1 }}>✓</Box>
                        )}
                        {option.text}
                      </OptionBox>
                    ))
                  ) : (
                    // Open text questions
                    <>
                      <OptionBox
                        isSelected={true}
                        isCorrect={isCorrect}
                      >
                        Tu respuesta: {answer.question_answer}
                      </OptionBox>
                      <OptionBox
                        isSelected={false}
                        isCorrect={true}
                      >
                        Respuesta correcta: {question.question_correct_answer}
                      </OptionBox>
                    </>
                  )}
                </Box>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                  <ExplanationButton
                    onClick={() => handleJustificationOpen(question)}
                    startIcon={<DescriptionOutlinedIcon />}
                  >
                    Explicación
                  </ExplanationButton>
                </Box>
              </Box>
            </ResultCard>
          );
        })}
      </Box>

      <Box sx={{ mt: 2, mb: 4, textAlign: 'center' }}>
        <Typography sx={{
          fontSize: '14px',
          color: '#666',
          fontFamily: "'Inter', sans-serif",
        }}>
          Puntuación final: {score}/10
        </Typography>
      </Box>

      {currentQuestionForJustification && (
        <JustificationPopup
          open={isJustificationOpen}
          onClose={handleJustificationClose}
          question={currentQuestionForJustification.question_statement}
          correctAnswer={currentQuestionForJustification.question_correct_answer}
          justificationText={justificationText}
          isLoading={isLoadingJustification}
          document_id={examData.document_guid}
          user={user}
          userAnswer={currentQuestionForJustification.question_answer}
        />
      )}

      <ScrollTopButton
        onClick={handleScrollToTop}
        variant="contained"
        aria-label="scroll to top"
      >
        <KeyboardArrowUpIcon />
      </ScrollTopButton>
    </SummaryContainer>
  );
};

export default ExamCorrection;